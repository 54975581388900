import { IsoCountryCode2Char } from '@rbilabs/intl-common';

import { Channel } from 'src/graphql';

import { roundTo } from './pricing';

type IUpdateMenuFromCSVInput = {
  serviceMode: string;
  plu: string;
  availability?: boolean;
  price?: number;
  lateNight?: number;
};

type IStore = {
  [key: string]: {
    channel: Channel;
    items: IUpdateMenuFromCSVInput[];
  };
};

// CSV parser function for uploading prices
export const parseCsv = (csv: string, channel: Channel) => {
  const lines: string[] = csv.split('\n');
  const stores: IStore[] = [];

  lines.forEach((line: string, index: number) => {
    const lineCells = line.split(',');

    if (!index) {
      return;
    }

    const [storeId, plu, price, availability, serviceMode, , , lateNightPrice] = lineCells;

    if (!storeId) {
      return;
    }

    const storeData: IUpdateMenuFromCSVInput = {
      serviceMode,
      plu,
      availability: Boolean(Number(availability)),
    };

    const parsedPrice = parseFloat(price);
    const parsedLateNightPrice = parseFloat(lateNightPrice);

    if (!Number.isNaN(parsedPrice) || parsedPrice >= 0) {
      storeData.price = roundTo(parsedPrice, 0);
    }

    if (!Number.isNaN(parsedLateNightPrice) || parsedLateNightPrice >= 0) {
      storeData.lateNight = roundTo(parsedLateNightPrice, 0);
    }

    const storeIndex = stores.findIndex(store => !!store[storeId]);

    if (storeIndex !== -1) {
      stores[storeIndex][storeId].items.push(storeData);
      return;
    }

    stores.push({ [storeId]: { channel, items: [storeData] } });
  });

  return stores;
};

/**
 * Download CSV helper
 * Creates a blob from a csv text and makes initiates the browser download
 * by creating a anchor element and triggering a click event
 */
export const downloadCSV = function (filename: string, data: string): void {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([data], { type: 'text/csv' });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');

  // Passing the blob downloading url
  a.setAttribute('href', url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', filename);

  // Performing a download with click
  a.click();
};

interface FetchCsvParams {
  url: string;
  body: string;
  token: string;
  region: IsoCountryCode2Char;
  headers?: any;
}

/**
 * CSV Download fetcher function
 */
export const fetchCsv = async ({
  url,
  body,
  token,
  region,
  headers,
}: FetchCsvParams): Promise<void> => {
  const res = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'text/csv',
      'okta-id-token': token ?? '',
      'x-ui-region': region,
      ...headers,
    },
    body,
  });

  if (!res.ok || (res.status >= 400 && res.status < 600)) {
    return Promise.reject();
  }

  const filename =
    res.headers.get('content-disposition')?.split('=')[1] ?? `Report_${Date.now()}.csv`;
  const csv = await res.text();

  downloadCSV(filename, csv);

  return Promise.resolve();
};
