import { useEffect, useRef } from 'react';

/**
 * useOutsideClick hook params
 */
export interface UseOutsideClickParams {
  /** React ref with an HTML element */
  ref: React.RefObject<HTMLElement | null>;

  /** Callback function to exectute when click is detected outside the ref element  */
  callback: (event: Event) => void;

  /** enables (default) or disabled the execution of the callback function */
  autoclose?: boolean;
}

/**
 * Execute a callback fn when user clicks outside the element
 *
 * @params params {UseOutsideClickParams}
 */
export const useOutsideClick = ({ ref, callback, autoclose = true }: UseOutsideClickParams) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler: EventListener = event => {
      if (!autoclose) {
        return;
      }

      const { current: target } = ref;

      if (target && !target.contains(event.target as HTMLElement)) {
        callbackRef.current(event);
      }
    };

    document.addEventListener('click', handler);
    return () => document.removeEventListener('click', handler);
  }, [autoclose, ref]);
};
