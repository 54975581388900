
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Product": [
      "Combo",
      "ConfigOffer",
      "Item",
      "ModifierMultiplier",
      "Offer",
      "Reward",
      "SystemwideOffer"
    ],
    "ValidateProductsResponse": [
      "ValidateProductsResponseNewSchema",
      "ValidateProductsResponseOldSchema"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Product": [
      "Combo",
      "ConfigOffer",
      "Item",
      "ModifierMultiplier",
      "Offer",
      "Reward",
      "SystemwideOffer"
    ],
    "ValidateProductsResponse": [
      "ValidateProductsResponseNewSchema",
      "ValidateProductsResponseOldSchema"
    ]
  }
};
      export default result;
    