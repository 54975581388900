import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Warning: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="26" height="23" viewBox="0 0 26 23" {...props}>
    <path
      d="M13.0015 2.98664L23.0415 20.3333H2.96146L13.0015 2.98664ZM0.654797 19C-0.37187 20.7733 0.90813 23 2.96146 23H23.0415C25.0948 23 26.3748 20.7733 25.3481 19L15.3081 1.6533C14.2815 -0.12003 11.7215 -0.12003 10.6948 1.6533L0.654797 19ZM11.6681 9.66664V12.3333C11.6681 13.0666 12.2681 13.6666 13.0015 13.6666C13.7348 13.6666 14.3348 13.0666 14.3348 12.3333V9.66664C14.3348 8.9333 13.7348 8.3333 13.0015 8.3333C12.2681 8.3333 11.6681 8.9333 11.6681 9.66664ZM11.6681 16.3333H14.3348V19H11.6681V16.3333Z"
      fill="#97B0BD"
    />
  </SvgIcon>
);
