import {
  IconAnalytics,
  IconDelivery,
  IconErrors,
  IconGuides,
  IconProducts,
  IconStores,
  IconUser,
} from 'src/components/V2/Icons';

export const navItems = [
  {
    id: 'restaurants',
    localeString: 'shell.restaurantsLinkText',
    path: '/restaurants',
    Icon: IconStores,
  },
  {
    id: 'products',
    localeString: 'shell.productsLinkText',
    path: '/products',
    Icon: IconProducts,
  },
  {
    id: 'analytics',
    localeString: 'shell.analyticsLinkText',
    path: '/analytics',
    Icon: IconAnalytics,
  },
  {
    id: 'errors',
    localeString: 'shell.errorsLinkText',
    path: '/errors',
    Icon: IconErrors,
  },
  {
    id: 'guides',
    localeString: 'shell.guidesLinkText',
    path: '/guides',
    Icon: IconGuides,
  },
  {
    id: 'delivery',
    localeString: 'shell.deliveryLinkText',
    path: '/delivery',
    Icon: IconDelivery,
  },
  {
    id: 'users',
    localeString: 'shell.usersLinkText',
    path: '/users',
    Icon: IconUser,
  },
  {
    id: 'history',
    localeString: 'shell.historyLinkText',
    path: '/history',
    Icon: IconGuides,
  },
] as const;
