import { Plugin } from '@rematch/core';
import ldRedux from 'ld-redux';
import { ReducersMapObject, combineReducers } from 'redux';

import { RootModel } from 'src/rematch/models';

function combineReducersWithLaunchDarkly(reducers: ReducersMapObject) {
  return combineReducers({ ...reducers, launchDarkly: ldRedux.reducer() });
}

// rematch plugin
export const ldPlugin = (clientSideId: string): Plugin<RootModel> => ({
  config: {
    redux: {
      combineReducers: combineReducersWithLaunchDarkly,
    },
  },
  onStoreCreated(store) {
    ldRedux.init({
      clientSideId,
      dispatch: store.dispatch,
    });
  },
});

export default ldPlugin;
