import { createModel } from '@rematch/core';
import { createSelector } from '@rematch/select';

import { GridAreaType, RestaurantPolygon } from 'src/graphql';
import { MapCoordinates, geoJsonToLatLng, omitDeepTypename } from 'src/utils/';

import type { RootModel } from '.';

export enum DrawingArea {
  MAIN_AREA = 'mainArea',
  REDUCED_AREA = 'reducedArea',
}

export type AreaProps = { coordinates: MapCoordinates[]; color: string };
export interface SelectedPolygonState {
  id?: string;
  mainArea: AreaProps;
  reducedArea: AreaProps;
  name: string;
  default?: boolean;
  pendingApproval?: boolean;
  gridArea: GridAreaType[];
  restaurantId?: string;
}

interface PolygonsState {
  /* List of all polygons by a given country and brand */
  allPolygons: ReadonlyArray<RestaurantPolygon>;

  /* List of polygons by a given restaurant */
  restaurantPolygons: ReadonlyArray<RestaurantPolygon>;

  selectedPolygon: SelectedPolygonState;
}

export interface SetPolygonCoordProps {
  area: DrawingArea;
  coordinates: MapCoordinates[];
}

interface SetPolygonColorProps {
  area: DrawingArea;
  color: string;
}

const mockedGridArea = [
  {
    id: 'B3',
    polygon: {
      coordinates: [
        [
          [-3.701310635699855, 40.44980163404452],
          [-3.701310635699855, 40.44980163404452],
        ],
      ],
      color: '#000000',
    },
  },
];

const formatSelectedPolygon = (polygon?: RestaurantPolygon) => {
  if (polygon) {
    const mainAreaPolygon = geoJsonToLatLng(polygon.mainArea.coordinates);
    const reducedAreaPolygon = polygon.reducedArea
      ? geoJsonToLatLng(polygon.reducedArea.coordinates)
      : undefined;

    return {
      ...omitDeepTypename(polygon),
      mainArea: {
        coordinates: mainAreaPolygon,
        color: polygon.mainArea.color,
      },
      reducedArea: {
        coordinates: reducedAreaPolygon,
        color: polygon.reducedArea?.color,
      },
    };
  }
  return {
    mainArea: {
      coordinates: [],
      color: '#3B5A6B',
    },
    reducedArea: {
      coordinates: [],
      color: '#3B5A6B',
    },
    name: '',
    gridArea: mockedGridArea,
  };
};

const sortPolygonsList = (isMaster: boolean, restaurantPolygons: RestaurantPolygon[]) => {
  const sortedList = [];
  const approved = restaurantPolygons.find(polygon => polygon.default);
  const pendingApproval = restaurantPolygons.find(polygon => polygon.pendingApproval);
  const draft = restaurantPolygons.filter(polygon => !polygon.default && !polygon.pendingApproval);

  if (isMaster) {
    pendingApproval && sortedList.push(pendingApproval);
    approved && sortedList.push(approved);
    draft && sortedList.push(...draft);
  }

  if (!isMaster) {
    approved && sortedList.push(approved);
    pendingApproval && sortedList.push(pendingApproval);
    draft && sortedList.push(...draft);
  }
  return sortedList;
};

export const polygons = createModel<RootModel>()({
  state: {
    allPolygons: [],
    restaurantPolygons: [],
    selectedPolygon: {} as SelectedPolygonState,
  } as PolygonsState,
  reducers: {
    setAllPolygons(state, allPolygons: ReadonlyArray<RestaurantPolygon>) {
      return {
        ...state,
        allPolygons,
      };
    },
    setSelectedPolygon(state, polygon?: RestaurantPolygon) {
      const selectedPolygon = formatSelectedPolygon(polygon);
      return {
        ...state,
        selectedPolygon,
      };
    },
    setPolygons(state, restaurantPolygons: ReadonlyArray<RestaurantPolygon>) {
      return {
        ...state,
        restaurantPolygons,
      };
    },
    setCurrentPolygonName(state, name: string) {
      return {
        ...state,
        selectedPolygon: {
          ...state.selectedPolygon,
          name,
        },
      };
    },
    setCurrentPolygonAreaCoords(state, payload: SetPolygonCoordProps) {
      const { area, coordinates } = payload;
      const { selectedPolygon } = state;
      const color = selectedPolygon[area].color || '#3B5A6B';
      return {
        ...state,
        selectedPolygon: {
          ...selectedPolygon,
          [area]: {
            ...selectedPolygon[area],
            coordinates,
            color,
          },
        },
      };
    },
    setCurrentPolygonAreaColor(state, payload: SetPolygonColorProps) {
      const { area, color } = payload;
      const { selectedPolygon } = state;
      return {
        ...state,
        selectedPolygon: {
          ...selectedPolygon,
          [area]: {
            ...selectedPolygon[area],
            color,
          },
        },
      };
    },
  },
  selectors: slice => ({
    getRestaurantPolygonsList() {
      return slice(state => state.restaurantPolygons);
    },
    sortPolygonsByManager() {
      return createSelector(this.getRestaurantPolygonsList, pl => {
        const restaurantPolygons = pl as unknown as RestaurantPolygon[];
        if (restaurantPolygons.length) {
          return sortPolygonsList(false, restaurantPolygons);
        }
        return [];
      });
    },
    sortPolygonsByMaster() {
      return createSelector(this.getRestaurantPolygonsList, pl => {
        const restaurantPolygons = pl as unknown as RestaurantPolygon[];
        if (restaurantPolygons.length) {
          return sortPolygonsList(true, restaurantPolygons);
        }
        return [];
      });
    },
    getCurrentPolygon() {
      return slice(state => state.selectedPolygon);
    },
    getMainArea() {
      return slice(state => state.selectedPolygon.mainArea);
    },
    getReducedArea() {
      return slice(state => state.selectedPolygon.reducedArea);
    },
    getAllMainPolygons() {
      return slice(state =>
        state.allPolygons.filter(pol => pol.default).map(({ mainArea }) => mainArea)
      );
    },
    hasPolygonPendingApproval() {
      return slice(state => state.restaurantPolygons.some(polygon => polygon.pendingApproval));
    },
  }),
});
