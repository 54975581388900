import styled from 'styled-components';

export const Container = styled.div``;

export const Form = styled.form`
  width: 100%;
`;

export const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
`;

export const FormLabel = styled.label`
  display: inline-block;
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacing.xxxsmall};
  color: ${({ theme }) => theme.palette.base.darker};
`;

export const FormSelect = styled.select`
  display: block;
  width: 100%;
  border: 1px solid;
  padding: ${({ theme }) => `${theme.spacing.xxxsmall} ${theme.spacing.xxsmall}`};
  border-color: ${({ theme }) => theme.palette.base.darker};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

export const FormSelectOption = styled.option``;
