import { createModel } from '@rematch/core';

import { ComplaintsRowFragment } from 'src/graphql';
import { ComplaintsFilter } from 'src/pages/delivery/complaints-compensations/filters/useComplaintsFilter';
import { getPaymentTranslationKey } from 'src/utils/orders';

import type { RootModel } from '.';

interface ComplaintsState {
  data: ReadonlyArray<ComplaintsRowFragment>;
  searchTerm: string;
  restaurants: ComplaintsFilter[];
  status: ComplaintsFilter[];
  channels: ComplaintsFilter[];
  dateRange: Date[];
  searchDateRange: Date[];
}

export const complaints = createModel<RootModel>()({
  state: {
    data: [],
    searchTerm: '',
    restaurants: [],
    status: [],
    channels: [],
    dateRange: [],
    searchDateRange: [],
  } as ComplaintsState,
  reducers: {
    setComplaints(state, data: ReadonlyArray<ComplaintsRowFragment>) {
      const complaints = data;

      if (!complaints) {
        return state;
      }
      return {
        ...state,
        data: complaints,
      };
    },
    setSearchTerm(state, newSearchTerm: string) {
      return {
        ...state,
        searchTerm: newSearchTerm,
      };
    },
    setRestaurants(state, restaurants: ComplaintsFilter[]) {
      return {
        ...state,
        restaurants: restaurants === state?.restaurants ? [] : restaurants,
      };
    },
    setStatus(state, status: ComplaintsFilter[]) {
      return {
        ...state,
        status: status === state.status ? [] : status,
      };
    },

    setChannel(state, channels: ComplaintsFilter[]) {
      return {
        ...state,
        channels: channels === state.channels ? [] : channels,
      };
    },

    setDateRange(state, date: Date[]) {
      return {
        ...state,
        dateRange: date === state.dateRange ? [] : date,
      };
    },

    setSearchDateRange(state, date: Date[]) {
      return {
        ...state,
        searchDateRange: date === state.searchDateRange ? [] : date,
      };
    },
  },
  selectors: (slice, createSelector) => ({
    getAllComplaints() {
      return slice(state => state.data);
    },
    getSearchTerm() {
      return slice(state => state.searchTerm);
    },
    getSelectedStatus() {
      return slice(state => state.status);
    },
    getSelectedChannels() {
      return slice(state => state.channels);
    },
    getSelectedDateRange() {
      return slice(state => state.dateRange);
    },
    getSearchDateRange() {
      return slice(state => state.searchDateRange);
    },
    getSelectedRestaurant() {
      return slice(state => state.restaurants) ?? [];
    },
    filteredComplaints() {
      return createSelector(
        this.getSearchTerm,
        this.getSelectedStatus,
        this.getSelectedChannels,
        this.getAllComplaints,
        (st, sst, ch, ad) => {
          const searchTerm = st as unknown as string;
          const status = sst as unknown as ComplaintsFilter[];
          const channel = ch as unknown as ComplaintsFilter[];
          const allComplaints = ad as unknown as ComplaintsRowFragment[];

          const filterBySearchTerm = (complaint: ComplaintsRowFragment) =>
            [
              complaint?.id?.slice(-7).toLowerCase(),
              complaint.order?.displayOrderId?.toLowerCase(),
              complaint?.order?.channel?.toLowerCase(),
              complaint?.payment?.total?.amount.toFixed(2),
              complaint?.customer?.name?.toLowerCase(),
              getPaymentTranslationKey(complaint?.payment?.paymentMethod ?? '').toLocaleLowerCase(),
              complaint?.dmpCategory?.toLowerCase(),
              complaint?.order?.restaurantId?.toLowerCase(),
            ].some(term => term?.includes(searchTerm.toLowerCase()));

          if (!allComplaints.length) {
            return [];
          }
          if (!searchTerm && !status && !channel) {
            return allComplaints;
          }

          let results = allComplaints;

          if (status.length) {
            results = results.filter(complaint => {
              return status.find(item => item.name === complaint.status);
            });
          }

          if (channel.length) {
            results = results.filter(complaint => {
              return channel.find(item => item.name === complaint.order?.channel);
            });
          }

          if (searchTerm !== '') {
            results = results.filter(filterBySearchTerm);
          }

          return results;
        }
      );
    },
    hasActiveFilters() {
      return createSelector(this.filteredComplaints, this.getAllComplaints, (fr, ad) => {
        const filteredResult = fr as unknown as ComplaintsRowFragment[];
        const allComplaints = ad as unknown as ComplaintsRowFragment[];

        return filteredResult.length !== allComplaints.length;
      });
    },
  }),
});
