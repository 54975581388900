import { NavLink as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import type { Brand } from 'src/brand';
import { Typography } from 'src/components/V2/Typography';

export const PageWrapper = styled.div`
  overflow-x: hidden;
  padding-top: ${({ theme }) => theme.navbar.mobile.height};

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    padding-top: 0;
  }
`;

export const NavBar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ theme }) => theme.navbar.mobile.height};
  z-index: 1000;
  background-color: ${({ theme }) => theme.palette.base.darker};
  box-shadow: 0 0 12px -8px rgba(0, 0, 0, 0.4);

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    height: ${({ theme }) => theme.navbar.desktop.height};
    position: relative;
    display: flex;
  }
`;

export const NavBarBrand = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1001;
  background-color: ${({ theme }) => theme.palette.base.darker};

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    height: ${({ theme }) => theme.navbar.desktop.height};
    width: auto;
  }
`;

export const NavBarTitle = styled.span`
  display: block;
  padding-left: ${({ theme }) => theme.spacing.large};
  padding-right: ${({ theme }) => theme.spacing.large};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.03em;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.common.white};

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    padding-right: ${({ theme }) => theme.spacing.xlarge};
  }
`;

export const NavBarLogo = styled(RouterLink)<{ brand: Brand }>`
  width: ${({ theme }) => theme.navbar.mobile.height};
  height: ${({ theme }) => theme.navbar.mobile.height};
  padding: 12px;
  background-color: ${({ theme }) => theme.palette.brand.bk};

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    width: ${({ theme }) => theme.navbar.desktop.height};
    height: ${({ theme }) => theme.navbar.desktop.height};
    padding: 20px;
  }
`;

export const NavBarToggle = styled.div`
  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    display: none;
  }
`;

export const NavBarNav = styled.nav<{ open: boolean }>`
  position: absolute;
  top: ${({ theme }) => theme.navbar.mobile.height};
  left: 0;
  width: 100%;
  max-height: calc(100vh - ${({ theme }) => theme.navbar.mobile.height});
  background-color: ${({ theme }) => theme.palette.base.darker};
  overflow: auto;
  z-index: 999;

  /** Restore values for desktop */
  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    position: static;
    max-height: none;
    overflow: visible;
  }

  /** Open ~ Close Animation */
  @media (max-width: ${({ theme }) => `calc(${theme.navbar.breakpoint} - 1px)`}) {
    ${({ open, theme }) => {
      // values for [translation, opacity]
      const navOpen = [0, 1];
      const navClose = [`calc(-100% - ${theme.navbar.mobile.height})`, 0];

      const [translation, opacity] = open ? navOpen : navClose;
      return `
        --translation: ${translation};
        --opacity: ${opacity};
        `;
    }}

    transition: ${({ open }) => (open ? 'all 250ms 100ms ease-out' : 'all 100ms 0ms ease')};
    opacity: var(--opacity, 0);
    transform: translateY(
      var(--translation, calc(-100% - ${({ theme }) => theme.navbar.mobile.height}))
    );
  }
`;

export const NavGroup = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: ${({ theme }) => theme.spacing.small};
  padding-right: ${({ theme }) => theme.spacing.small};
  list-style: none;

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    display: flex;
  }
`;

export const NavItem = styled.li`
  padding-top: ${({ theme }) => theme.spacing.xxsmall};
  padding-bottom: ${({ theme }) => theme.spacing.xxsmall};

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    display: flex;
    padding: 0;
  }
`;

export const NavLink = styled(RouterLink)<{ $dropdown?: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-top: ${({ theme }) => theme.spacing.xxsmall};
  padding-bottom: ${({ theme }) => theme.spacing.xxsmall};
  background-color: ${({ theme }) => theme.palette.base.darker};
  transition: background-color 100ms ease-in;
  gap: ${({ $dropdown, theme }) => ($dropdown ? theme.spacing.xxsmall : 0)};

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    min-width: 120px;
    justify-content: center;
    flex-direction: ${({ $dropdown }) => ($dropdown ? 'row' : 'column')};
    padding: ${({ theme }) => theme.spacing.xxsmall};
    height: ${({ theme }) => theme.navbar.desktop.height};

    &.active {
      background: linear-gradient(180deg, rgba(38, 60, 71, 0.8) 0%, rgba(38, 60, 71, 0) 68.75%),
        linear-gradient(0deg, #3b5a6b, #3b5a6b);
      cursor: default;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.palette.base.dark};
    }
  }
`;

export const NavLinkLabel = styled(Typography.Label)`
  color: ${({ theme }) => theme.palette.base.lighter};
  margin-left: 10px;

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    margin-left: 0;
    margin-top: 4px;
  }
`;

export const NavFlag = styled(NavItem)`
  margin-left: auto;
`;

export const Action = styled.button`
  display: block;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

export const ToggleButton = styled(Action)`
  padding: ${({ theme }) => theme.spacing.small};
  width: ${({ theme }) => theme.navbar.mobile.height};
  height: ${({ theme }) => theme.navbar.mobile.height};
`;

export const NavBarDropdown = styled.div<{ open: boolean }>`
  --dropdown-display: ${({ open }) => (open ? 'block' : 'none')};
  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const NavBarDropdownMenu = styled.div`
  display: block;
  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    display: var(--dropdown-display);
    position: absolute;
    right: 0;
    top: ${({ theme }) => `calc(100% - ${theme.spacing.small} )`};
    background: ${({ theme }) => theme.palette.common.white};
    border: 1px solid ${({ theme }) => theme.palette.base.light};
    border-radius: ${({ theme }) => theme.borderRadius.default};

    &::after {
      content: ' ';
      position: absolute;
      top: -${({ theme }) => theme.spacing.xxxsmall};
      right: ${({ theme }) => theme.spacing.large};
      width: ${({ theme }) => theme.spacing.xxsmall};
      height: ${({ theme }) => theme.spacing.xxsmall};
      border-left: 1px solid ${({ theme }) => theme.palette.base.light};
      border-top: 1px solid ${({ theme }) => theme.palette.base.light};
      background: ${({ theme }) => theme.palette.common.white};
      transform: rotate(45deg);
    }
  }
`;

export const DropdownMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    width: 240px;
    padding: ${({ theme }) => `${theme.spacing.xsmall} ${theme.spacing.normal}`};
  }
`;

export const DropdownMenuItem = styled.li`
  margin: ${({ theme }) => `${theme.spacing.xxxsmall} 0`};
`;

export const DropdownMenuLink = styled(NavLinkLabel)`
  width: 100%;
  display: block;
  padding: 12px 0;
  border: 0;
  background: transparent;
  text-decoration: none;
  text-align: left;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    padding: ${({ theme }) => `${theme.spacing.xxxsmall} 0`};
    font-weight: 500;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    color: ${({ theme }) => theme.palette.base.darker};
  }
`;
