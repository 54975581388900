import { useState } from 'react';

import { Button } from 'src/components/V2/Button';
import { Modal } from 'src/components/V2/Modal';
import { LocaleCode } from 'src/generated/markets';
import { useLocale } from 'src/locale';
import { supportedLanguages } from 'src/locale/constants';

import { Form, FormGroup, FormLabel, FormSelect, FormSelectOption } from './LaguageSelector.styled';

interface LanguageSelectorProps {
  closeModal: () => void;
  isModalOpen?: boolean;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ closeModal, isModalOpen }) => {
  const { changeLanguage, formatMessage, language } = useLocale();

  const [option, selectOption] = useState(language);

  const onSubmit = () => {
    changeLanguage(option);
    closeModal();
  };

  return (
    <Modal
      open={isModalOpen ?? true}
      onDissmisModal={closeModal}
      title={formatMessage({ id: 'config-locale.title' })}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <FormGroup>
          <FormLabel htmlFor="select-language">Language</FormLabel>
          <FormSelect
            id="select-language"
            name="language"
            value={option}
            onChange={e => selectOption(e.target.value as LocaleCode)}
          >
            {supportedLanguages.map(({ value, label }) => (
              <FormSelectOption key={value} value={value}>
                {label}
              </FormSelectOption>
            ))}
          </FormSelect>
        </FormGroup>

        <Button disabled={!language} type="submit">
          {formatMessage({ id: 'config-locale.continue' })}
        </Button>
      </Form>
    </Modal>
  );
};
