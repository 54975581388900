import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Notification: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="16" height="25" viewBox="0 0 20 25" {...props}>
    <path
      d="M11 26.3333C12.4666 26.3333 13.6666 25.1333 13.6666 23.6667H8.33331C8.33331 25.1333 9.51998 26.3333 11 26.3333ZM19 18.3333V11.6667C19 7.57333 16.8133 4.14667 13 3.24V2.33333C13 1.22667 12.1066 0.333332 11 0.333332C9.89331 0.333332 8.99998 1.22667 8.99998 2.33333V3.24C5.17331 4.14667 2.99998 7.56 2.99998 11.6667V18.3333L0.333313 21V22.3333H21.6666V21L19 18.3333Z"
      fill="#97B0BD"
    />
  </SvgIcon>
);
