import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Close: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2071 13.7929C15.5976 14.1834 15.5976 14.8166 15.2071 15.2071C14.8166 15.5976 14.1834 15.5976 13.7929 15.2071L8.00005 9.41426L2.20717 15.2071C1.81664 15.5977 1.18348 15.5977 0.792952 15.2071C0.402427 14.8166 0.402427 14.1835 0.792952 13.7929L6.58584 8.00005L0.792893 2.20711C0.402369 1.81658 0.402368 1.18342 0.792893 0.792893C1.18342 0.402368 1.81658 0.402369 2.20711 0.792893L8.00005 6.58584L13.7929 0.792939C14.1835 0.402415 14.8166 0.402414 15.2072 0.792939C15.5977 1.18346 15.5977 1.81663 15.2072 2.20715L9.41426 8.00005L15.2071 13.7929Z"
    />
  </SvgIcon>
);
