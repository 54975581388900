import { Override } from '@rbilabs/intl-menu';

import { defaultChannels, serviceModes } from '../../../pages/editor/constants';
import { EditorState, IQuickFillActionType, Update } from '../../types';

import { addUpdate } from './add-update';
import { updateData } from './update-data';

export interface IUpdateAllQuickFillOptions {
  action: IQuickFillActionType;
  displayLateNightPrice: boolean;
}

/**
 * Updates all prices/availabilities
 */
export const updateAllQuickFill = (
  state: EditorState,
  payload: Update,
  { action, displayLateNightPrice }: IUpdateAllQuickFillOptions
) => {
  const updatesToRemove: Array<string> = [];

  if (displayLateNightPrice && payload.price) {
    payload.overrides = [
      {
        id: Override.lateNight,
        price: payload.price,
      },
    ];
  } else {
    payload.overrides = undefined;
  }

  const allChannels = [
    ...defaultChannels,
    ...state.additionalChannels.map(({ channel }) => channel),
  ];

  const quickFillUpdatesMap = new Map(state.quickFill.updates.map(u => [`${u.id}`, u]));
  const payloadAlreadyInMap = quickFillUpdatesMap.get(payload.id);

  if (payloadAlreadyInMap) {
    const updatedPayload = {
      ...payloadAlreadyInMap, //* Depends on the updated fields, payload doesn't have all the values, which might be already changed (i.e while updating price, payload doesn't have availability, and vice versa)
      ...payload,
    };
    quickFillUpdatesMap.set(payload.id, updatedPayload);
  } else {
    quickFillUpdatesMap.set(payload.id, payload);
  }

  state.products.forEach(product => {
    const serviceQuickFillId = `${product.id}::${payload.serviceMode}`;
    const serviceQuickFillPrev = quickFillUpdatesMap.get(serviceQuickFillId);

    if (!serviceQuickFillPrev) {
      quickFillUpdatesMap.set(serviceQuickFillId, {
        ...payload,
        id: serviceQuickFillId,
        product: product.id,
      });
    } else {
      const updatedServiceQuickFill = {
        ...serviceQuickFillPrev,
        ...payload,
        id: serviceQuickFillId,
        product: product.id,
      };
      quickFillUpdatesMap.set(serviceQuickFillId, updatedServiceQuickFill);
    }

    serviceModes.forEach(serviceMode => {
      allChannels.forEach(channel => {
        const updateId = `${product.id}::${serviceMode.id}::${channel}`;
        const prev = state.updates.find(u => u.id === updateId);
        const channelQuickFillId = `${payload.product}::${serviceMode.id}::${channel}`;
        const channelQuickFillPrev = quickFillUpdatesMap.get(channelQuickFillId);

        if (!channelQuickFillPrev) {
          quickFillUpdatesMap.set(channelQuickFillId, {
            ...payload,
            id: channelQuickFillId,
            product: payload.product,
            serviceMode: serviceMode.id,
            channel,
          });
        } else {
          const updatedChannelQuickFill = {
            ...channelQuickFillPrev,
            ...payload,
            id: channelQuickFillId,
            product: payload.product,
            serviceMode: serviceMode.id,
            channel,
          };
          quickFillUpdatesMap.set(channelQuickFillId, updatedChannelQuickFill);
        }

        if (!prev) {
          addUpdate(state, payload, updateId, product.id, channel, serviceMode.id);
        } else {
          const dataUpdated = updateData(prev, payload, action, product.id);
          if (!dataUpdated) {
            updatesToRemove.push(updateId);
          }
        }
      });
    });
  });

  const quickFillUpdates = Array.from(quickFillUpdatesMap.values());

  return {
    ...state,
    updates: state.updates.filter(update => !updatesToRemove.includes(update.id)),
    quickFill: { ...state.quickFill, updates: quickFillUpdates },
  };
};
