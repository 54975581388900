import { useMemo } from 'react';

import { createIntl } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { useBrand } from 'src/brand';
import { Loading, LoadingWrapper } from 'src/components/V2/Loading';
import { ERROR_ICONS, Page, PageContent } from 'src/components/V2/Page';
import enDictionary from 'src/locale/dictionaries/en.json';
import { theme } from 'src/pages/shell/Theme';

import { ContentRow, ErrorIcon, ErrorText, Header, HeaderTitle } from './NoAccess.styled';

export interface NoAccessProps {
  isLoading: boolean;
}

export const NoAccess: React.FC<NoAccessProps> = ({ isLoading }) => {
  const brand = useBrand();
  const icon = useMemo(() => ERROR_ICONS[brand], [brand]);

  // We need to instantiate intl here because this page is used at the AuthProvider level, which is
  // above the LocaleProvider. So our useLocale hook won't work here. Also, since this page shows
  // when there's no Okta user data, we can't know the language, so defaulting to English
  const intl = createIntl({ locale: 'EN', messages: enDictionary });
  const { formatMessage } = intl;

  return (
    <ThemeProvider theme={theme}>
      <Page>
        <PageContent>
          {isLoading ? (
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          ) : (
            <>
              <Header>
                <ErrorIcon src={icon} />
                <HeaderTitle>{formatMessage({ id: 'auth.title' })}</HeaderTitle>
              </Header>

              <ContentRow>
                <ErrorText>
                  {formatMessage({ id: 'auth.noAccessGrantedError' })}
                  <a
                    href="https://rbi.service-now.com/rbi?id=sc_cat_item&sys_id=853a8a051bef6010ac31edf3604bcb64"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {formatMessage({ id: 'auth.noAccessGrantedLink' })}
                  </a>
                </ErrorText>
              </ContentRow>
            </>
          )}
        </PageContent>
      </Page>
    </ThemeProvider>
  );
};
