import { LocaleCode } from 'src/generated/markets';

import { LOCALE_SEPARATOR } from '../constants';

import { default as de } from './de.json';
import { default as en } from './en.json';
import { default as en_GB } from './en_GB.json';
import { default as en_US } from './en_US.json';
import { default as es } from './es.json';
import { default as pt_PT } from './pt_PT.json';

const dictionaries: { [key: string]: Record<string, string> } = {
  en,
  en_GB,
  en_US,
  de,
  pt_PT,
  es,
};

export const getLocaleDictionaries = (locale: LocaleCode) => {
  const baseLanguage = locale?.split(LOCALE_SEPARATOR)[0] ?? 'en';
  const language = locale?.replace('-', '_');

  return [dictionaries[baseLanguage], dictionaries[language]];
};
