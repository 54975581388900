import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const ContactInfo: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      d="M9.96989 10C11.806 10 13.2932 8.50833 13.2932 6.66667C13.2932 4.825 11.806 3.33333 9.96989 3.33333C8.13375 3.33333 6.64657 4.825 6.64657 6.66667C6.64657 8.50833 8.13375 10 9.96989 10ZM9.96989 11.6667C7.75157 11.6667 3.32324 12.7833 3.32324 15V15.8333C3.32324 16.2917 3.69712 16.6667 4.15407 16.6667H15.7857C16.2427 16.6667 16.6165 16.2917 16.6165 15.8333V15C16.6165 12.7833 12.1882 11.6667 9.96989 11.6667Z"
      fill="#97B0BD"
    />
  </SvgIcon>
);
