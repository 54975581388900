import { IsoCountryCode2Char } from '@rbilabs/intl-common';
import { createModel } from '@rematch/core';
import LogRocket from 'logrocket';

import type { RbiUserClaims } from 'src/Auth';
import { UserRole } from 'src/graphql';
import { shouldForceRegion } from 'src/utils';

import { getLatestSelectedCountry } from '../utils';

import type { RootModel } from '.';

export type UserState = {
  isLoading: boolean;
  email?: string;
  name?: string;
  locale?: string;
  sub?: string;
  givenName?: string;
  familyName?: string;
  updatedAt?: number;
  userId?: string;
  userRole?: UserRole;
  FranCountry?: IsoCountryCode2Char;
};

export const user = createModel<RootModel>()({
  state: {
    isLoading: true,
  } as UserState,
  reducers: {
    setUser(state, payload: RbiUserClaims) {
      return {
        ...state,
        isLoading: false,
        email: payload.email,
        name: payload.name,
        sub: payload.sub,
        userId: payload.UserID,
        userRole: payload.UserType,
      };
    },
  },
  effects: dispatch => ({
    async setUserAsync(payload: RbiUserClaims) {
      if (!payload) {
        return;
      }

      LogRocket.identify(payload.UserID);
      dispatch.user.setUser(payload);

      //TODO: After rolling out the multimarket feature and removing the enableMultiMarket FL,
      // we must remove the shouldForceRegion function and keep only "?? payload.FranCountry"
      const country =
        getLatestSelectedCountry() ?? shouldForceRegion(payload.UserID, payload.FranCountry);

      dispatch.userPermissions.fetchPermissions({
        country,
        oktaId: payload.UserID,
      });
    },
  }),
  selectors: slice => ({
    getState() {
      return slice;
    },
  }),
});
