import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Products: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon viewBox="0 0 32 32" with="32" height="32" fill="#DAE7EE" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4H5C4.44772 4 4 4.44772 4 5V7C4 7.55228 4.44772 8 5 8H7C7.55228 8 8 7.55228 8 7V5C8 4.44772 7.55228 4 7 4ZM5 2C3.34315 2 2 3.34315 2 5V7C2 8.65685 3.34315 10 5 10H7C8.65685 10 10 8.65685 10 7V5C10 3.34315 8.65685 2 7 2H5ZM7 14H5C4.44772 14 4 14.4477 4 15V17C4 17.5523 4.44772 18 5 18H7C7.55228 18 8 17.5523 8 17V15C8 14.4477 7.55228 14 7 14ZM5 12C3.34315 12 2 13.3431 2 15V17C2 18.6569 3.34315 20 5 20H7C8.65685 20 10 18.6569 10 17V15C10 13.3431 8.65685 12 7 12H5ZM5 24H7C7.55228 24 8 24.4477 8 25V27C8 27.5523 7.55228 28 7 28H5C4.44772 28 4 27.5523 4 27V25C4 24.4477 4.44772 24 5 24ZM2 25C2 23.3431 3.34315 22 5 22H7C8.65685 22 10 23.3431 10 25V27C10 28.6569 8.65685 30 7 30H5C3.34315 30 2 28.6569 2 27V25ZM12 3C12 2.44772 12.4477 2 13 2H29C29.5523 2 30 2.44772 30 3C30 3.55228 29.5523 4 29 4H13C12.4477 4 12 3.55228 12 3ZM13 12C12.4477 12 12 12.4477 12 13C12 13.5523 12.4477 14 13 14H29C29.5523 14 30 13.5523 30 13C30 12.4477 29.5523 12 29 12H13ZM12 23C12 22.4477 12.4477 22 13 22H29C29.5523 22 30 22.4477 30 23C30 23.5523 29.5523 24 29 24H13C12.4477 24 12 23.5523 12 23ZM13 6C12.4477 6 12 6.44772 12 7C12 7.55228 12.4477 8 13 8H24C24.5523 8 25 7.55228 25 7C25 6.44772 24.5523 6 24 6H13ZM12 17C12 16.4477 12.4477 16 13 16H24C24.5523 16 25 16.4477 25 17C25 17.5523 24.5523 18 24 18H13C12.4477 18 12 17.5523 12 17ZM13 26C12.4477 26 12 26.4477 12 27C12 27.5523 12.4477 28 13 28H24C24.5523 28 25 27.5523 25 27C25 26.4477 24.5523 26 24 26H13Z"
    />
  </SvgIcon>
);
