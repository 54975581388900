import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Issue: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M9.1665 12.5001H10.8332V14.1667H9.1665V12.5001ZM9.1665 5.83342H10.8332V10.8334H9.1665V5.83342ZM9.9915 1.66675C5.3915 1.66675 1.6665 5.40008 1.6665 10.0001C1.6665 14.6001 5.3915 18.3334 9.9915 18.3334C14.5998 18.3334 18.3332 14.6001 18.3332 10.0001C18.3332 5.40008 14.5998 1.66675 9.9915 1.66675ZM9.99984 16.6667C6.3165 16.6667 3.33317 13.6834 3.33317 10.0001C3.33317 6.31675 6.3165 3.33341 9.99984 3.33341C13.6832 3.33341 16.6665 6.31675 16.6665 10.0001C16.6665 13.6834 13.6832 16.6667 9.99984 16.6667Z"
      fill="#D14545"
    />
  </SvgIcon>
);
