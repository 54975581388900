import { Update } from '../../types';

export const updateData = (
  prevUpdate: Update,
  payload: Update,
  action: 'price' | 'availability',
  productId: string
): boolean => {
  if (action === 'price') {
    if (!payload.price && payload.overrides) {
      prevUpdate.product = productId;
      prevUpdate.overrides = payload.overrides;
      return true;
    }

    if (typeof payload.price === 'number' || typeof prevUpdate.availability === 'boolean') {
      if (payload.overrides) {
        prevUpdate.overrides = payload.overrides;
      }
      if (payload.price) {
        prevUpdate.price = payload.price;
      }
      // Change plu to sanityId. Required for processing by `prepareBatches`.
      prevUpdate.product = productId;
      return true;
    }
  }

  if (action === 'availability') {
    if (typeof payload.availability === 'boolean' || typeof prevUpdate.price === 'number') {
      prevUpdate.availability = payload.availability;
      // Change plu to sanityId. Required for processing by `prepareBatches`.
      prevUpdate.product = productId;
      return true;
    }
  }

  return false;
};
