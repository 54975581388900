import styled from 'styled-components';

import type { ModalProps } from './Modal';

export const ModalBackground = styled.div<Pick<ModalProps, 'showBackground'>>`
  display: ${({ showBackground }) => (showBackground ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(90, 90, 90, 0.5);
  z-index: 1001;
`;

export const ModalContainer = styled.div<Pick<ModalProps, 'open'>>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing.normal} ${theme.spacing.xsmall}`};
  overflow: auto;
  z-index: 1001;
`;

export const ModalDialog = styled.div<Pick<ModalProps, 'open' | 'large'>>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: relative;
  width: 100%;
  max-width: ${({ large }) => (large ? '960px' : '600px')};
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  z-index: 1002;

  &:focus {
    outline: 0;
  }

  @media (min-width: 432px) {
    margin: ${({ theme }) => `${theme.spacing.normal} auto`};
  }
`;

export const ModalBody = styled.section`
  padding: ${({ theme }) => theme.spacing.large};
`;

export const ModalClose = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacing.small};
  right: ${({ theme }) => theme.spacing.small};
  width: 32px;
  height: 32px;
  border: 0;
  background: none;
  cursor: pointer;
`;

export const ModalHeader = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.large};
  padding-bottom: 0;
`;

export const ModalTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  margin: 0;
  letter-spacing: -0.96px;

  @media (min-width: 768px) {
    font-size: 2rem;
    line-height: 2.375rem;
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xsmall};
  padding: ${({ theme }) => theme.spacing.large};
  padding-top: 0;

  @media (min-width: 580px) {
    gap: ${({ theme }) => theme.spacing.small};
  }
`;
