import { EditorState, Update } from '../../types';

export const getQuickFillUpdates = (state: EditorState, payload: Update): Array<Update> => {
  const quickFillPrev = state.quickFill.updates.find(u => u.id === payload.id);

  if (!quickFillPrev) {
    return [...state.quickFill.updates, payload];
  }

  return state.quickFill.updates.map(update =>
    update === quickFillPrev ? { ...quickFillPrev, ...payload } : update
  );
};
