import { useEffect, useRef, useState } from 'react';

import { IconClose } from 'src/components/V2/Icons';
import { Bell } from 'src/components/V2/Icons/Bell';
import { BellNotification } from 'src/components/V2/Icons/BellNotification';
import { PopoverRef } from 'src/components/V2/Popover/Popover';
import { GetNotificationsQuery, useGetNotificationsQuery } from 'src/graphql';
import { useLocale } from 'src/locale';

import {
  CloseButton,
  Container,
  PopoverItemContainer,
  StyledPopover,
  Title,
  TitleContainer,
} from './Notification.styled';
import { NotificationContainer, NotificationType } from './NotificationContainer';

const INTERVAL_FIVE_MINUTES = 60000 * 5;
const NOTIFICATIONS_IDS_DISMISS_KEY_STORAGE = 'notifications-ids-dismiss-storage';

export const Notification = () => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const popoverRef = useRef<PopoverRef>(null);
  const { formatMessage } = useLocale();
  const captureClick: React.MouseEventHandler<HTMLElement> = e => {
    // as the popover closes when a click bubble, we must stop the propagation
    e.stopPropagation();
  };

  const handleCloseNotification = () => popoverRef.current?.closePopover();

  const {
    data: getNotifications,
    error,
    refetch: retrieveNotifications,
  } = useGetNotificationsQuery({
    pollInterval: INTERVAL_FIVE_MINUTES,
  });

  const mapperNotificationsDismiss = (data?: GetNotificationsQuery): void => {
    if (data?.notifications) {
      /* Set only notifications on storage with valid TTL */
      const notificationIdsDismiss = (
        JSON.parse(localStorage.getItem(NOTIFICATIONS_IDS_DISMISS_KEY_STORAGE) ?? '[]') as string[]
      )
        .map(item => {
          if (data?.notifications?.length) {
            return (
              data?.notifications?.find(notification => notification.id === item)?.id ?? undefined
            );
          }
          return item;
        })
        .filter(item => !!item);

      setNotifications(
        data.notifications.map(notification => {
          return {
            ...notification,
            isDismissed: notificationIdsDismiss.includes(notification.id),
          };
        })
      );

      /** Update storage with valid notifications (TTL) */
      localStorage.setItem(
        NOTIFICATIONS_IDS_DISMISS_KEY_STORAGE,
        JSON.stringify(notificationIdsDismiss)
      );
    }
  };

  const onDismissed = (id: string): void => {
    /** First, save notification id in storage */
    const notificationDismiss = JSON.parse(
      localStorage.getItem(NOTIFICATIONS_IDS_DISMISS_KEY_STORAGE) ?? '[]'
    ) as string[];
    !notificationDismiss.includes(id) && notificationDismiss.push(id);
    localStorage.setItem(
      NOTIFICATIONS_IDS_DISMISS_KEY_STORAGE,
      JSON.stringify(notificationDismiss)
    );

    /** Update notifications */
    setNotifications(prevState =>
      prevState.map(state => (state.id === id ? { ...state, isDismissed: true } : state))
    );
  };

  useEffect(() => {
    mapperNotificationsDismiss(getNotifications);
  }, [getNotifications]);

  const hasNewNotifications =
    notifications.filter(notification => !notification.isDismissed).length > 0;
  return (
    <Container>
      <StyledPopover
        ref={popoverRef}
        testId="show_notifications_list"
        content={
          <PopoverItemContainer data-testid="notification_container" onClick={captureClick}>
            <TitleContainer>
              <Title large>{formatMessage({ id: 'shell.notification.title' })}</Title>
              <CloseButton data-testid="close_notifications" onClick={handleCloseNotification}>
                <IconClose fill="currentColor" />
              </CloseButton>
            </TitleContainer>
            <NotificationContainer
              notifications={notifications}
              hasError={error}
              onRetry={() => retrieveNotifications()}
              onDismissed={onDismissed}
            />
          </PopoverItemContainer>
        }
      >
        {hasNewNotifications ? (
          <BellNotification data-testid="new_notifications" />
        ) : (
          <Bell data-testid="notifications_dissmissed" />
        )}
      </StyledPopover>
    </Container>
  );
};
