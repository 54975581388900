import { EditorState, IQuickFillActionType, Update } from '../../types';

import { getQuickFillUpdates } from './get-quick-fill-updates';
import { updateData } from './update-data';

export const updateChannelQuickFill = (
  state: EditorState,
  payload: Update,
  action: IQuickFillActionType
) => {
  const updatesToRemove: Array<string> = [];
  const quickFillUpdates = getQuickFillUpdates(state, payload);

  state.products.forEach(product => {
    const updateId = `${product.id}::${payload.serviceMode}::${payload.channel}`;
    const prev = state.updates.find(u => u.id === updateId);

    if (!prev) {
      const update: Update = {
        id: updateId,
        product: product.id,
        serviceMode: payload.serviceMode,
        channel: payload.channel,
        price: payload.price,
        availability: payload.availability,
        overrides: payload.overrides,
      };

      state.updates.push(update);
      return;
    }

    const dataUpdated = updateData(prev, payload, action, product.id);

    if (!dataUpdated) {
      updatesToRemove.push(updateId);
    }
  });

  return {
    ...state,
    updates: state.updates.filter(update => !updatesToRemove.includes(update.id)),
    quickFill: { ...state.quickFill, updates: quickFillUpdates },
  };
};
