import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const BellNotification: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="16" height="16" viewBox="0 0 18 21" {...props}>
    <path
      d="M16 15V9.8C15.5 9.9 15 10 14.5 10H14V16H4V9C4 6.2 6.2 4 9 4C9.1 2.7 9.7 1.6 10.5 0.7C10.2 0.3 9.6 0 9 0C7.9 0 7 0.9 7 2V2.3C4 3.2 2 5.9 2 9V15L0 17V18H18V17L16 15ZM7 19C7 20.1 7.9 21 9 21C10.1 21 11 20.1 11 19H7ZM18 4.5C18 6.4 16.4 8 14.5 8C12.6 8 11 6.4 11 4.5C11 2.6 12.6 1 14.5 1C16.4 1 18 2.6 18 4.5Z"
      fill="white"
    />
    <circle cx="14.5" cy="4.5" r="3.5" fill="#DD3712" />
  </SvgIcon>
);
