import styled from 'styled-components';

import { Typography } from 'src/components/V2/Typography';
import { Scrollbar } from 'src/pages/delivery/Delivery.styled';

export const Container = styled(Scrollbar)`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const CardContainer = styled.li<{ isDismissed: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ isDismissed, theme }) =>
    isDismissed ? theme.palette.common.white : theme.palette.gray.lighter};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.normal};
  border: 1px solid ${({ theme }) => theme.palette.gray.medium};
  border-right-width: 0;
  border-left-width: 0;
  border-bottom-width: 0;

  &:last-child {
    margin-bottom: ${({ theme }) => theme.navbar.desktop.height};
  }
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: min-content;
`;

export const CardFooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing.small};
`;

export const CardBody = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.small};
`;

export const NotificationText = styled(Typography.Body)`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.palette.common.black};
`;

export const NotificationMark = styled.div`
  width: ${({ theme }) => theme.spacing.xxsmall};
  height: ${({ theme }) => theme.spacing.xxsmall};
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.accent.error};
`;

export const NotificationTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.normal};
`;

export const NotificationTitle = styled(Typography.Body).attrs(() => ({
  large: true,
  bold: true,
}))`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.palette.base.dark};
`;
