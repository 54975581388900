import { useEffect, useRef } from 'react';

/**
 * useEscapeKey hook params
 */
export interface UseOutsideClickParams {
  /** React ref with an HTML element */
  ref: React.RefObject<HTMLElement | null>;

  /** Callback function to exectute when click is detected outside the ref element  */
  callback: (event: KeyboardEvent) => void;
}

export const useEscapeKey = ({ ref, callback }: UseOutsideClickParams) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const { current: target } = ref;

      if (target && target.contains(event.target as HTMLElement) && event.key === 'Escape') {
        callbackRef.current(event);
      }
    };

    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, [ref]);
};
