import { useMemo } from 'react';

import { useDispatch, useRematch } from 'src/rematch';
import {
  ComplaintsCompensationProperties,
  DmpSettings,
  LastMileSettings,
  NotificationSettings,
} from 'src/rematch/models/deliveryRestaurantSettings';
import { select } from 'src/rematch/store';
import { parseNullableToUndefined, parseNullablesArr } from 'src/utils/typing';

export const MAX_ORDER_LIMIT = 4;
export const MIN_ORDER_LIMIT = 1;

export const useRestaurantSettings = () => {
  const settings = useRematch(select.deliveryRestaurantSettings.getSettings);
  const restaurantData = useRematch(select.deliveryRestaurantSettings.getRestaurantData);
  const etaValues = useRematch(select.deliveryRestaurantSettings.getEtaValues);

  const dispatch = useDispatch();

  const api = useMemo(() => {
    /** Sets the new settings on DMP settings */
    const setDmpSettings = (settings: DmpSettings) =>
      dispatch.deliveryRestaurantSettings.setDmpSettings(settings);

    /** Return an object with the DMP settings */
    const getDmpSettings = () => {
      const {
        allowPauseService,
        allowReducedArea,
        allowAutoFire,
        autoFireRadius,
        allowAutoPrepare,
      } = settings;

      return {
        allowPauseService,
        allowReducedArea,
        allowAutoFire,
        autoFireRadius,
        allowAutoPrepare,
      };
    };

    const getOrderLimit = () => {
      const { driverOrderLimit } = settings;

      return { driverOrderLimit };
    };

    const setOrderLimit = (orderLimit: number) => {
      if (orderLimit > MAX_ORDER_LIMIT || orderLimit < MIN_ORDER_LIMIT) {
        return;
      }
      dispatch.deliveryRestaurantSettings.setOrderLimit(orderLimit);
    };

    const getCurrentEtaValue = () => {
      const { deliverySettings } = settings;

      return { currentEta: deliverySettings.eta.toString() };
    };

    const getComplaintsCompensation = () => ({
      allowRefund: settings.allowRefund ?? false,
      allowVoucher: settings.allowVoucher ?? false,
    });

    const setComplaintsCompensation = (props: ComplaintsCompensationProperties) => {
      dispatch.deliveryRestaurantSettings.setComplaintsCompensation(props);
    };

    const setDeliveryEta = (eta: string) =>
      dispatch.deliveryRestaurantSettings.setDeliveryEta(+eta);

    const setAutoFireRadius = (autoFireRadius: number) =>
      dispatch.deliveryRestaurantSettings.setAutoFireRadius(autoFireRadius);

    const setNotificationSettings = (settings: NotificationSettings) =>
      dispatch.deliveryRestaurantSettings.setNotificationSettings(settings);

    const setLastMileSettings = (settings: LastMileSettings) =>
      dispatch.deliveryRestaurantSettings.setLastMileSettings({
        settings: settings.settings,
        catcherLocationId: parseNullableToUndefined(settings.catcherLocationId),
        providers: parseNullablesArr([...settings.providers]),
      });

    const getNotificationSettings = () => {
      const { deliveryArea, eta, pauseService, posOffline } = settings.notifications;

      return { deliveryArea, eta, pauseService, posOffline };
    };

    const getLastMileSettings = () => {
      return settings.lastMile;
    };

    const onIncrementValue = () => setOrderLimit(getOrderLimit().driverOrderLimit + 1);
    const onDecrementValue = () => setOrderLimit(getOrderLimit().driverOrderLimit - 1);

    return {
      setDmpSettings,
      getDmpSettings,
      onIncrementValue,
      onDecrementValue,
      getOrderLimit,
      getCurrentEtaValue,
      setDeliveryEta,
      setAutoFireRadius,
      restaurantData,
      etaValues,
      setNotificationSettings,
      getNotificationSettings,
      getComplaintsCompensation,
      setComplaintsCompensation,
      setLastMileSettings,
      getLastMileSettings,
    };
  }, [dispatch, settings, restaurantData, etaValues]);

  return api;
};
