import styled from 'styled-components';

export const BodyType = styled.p<{ large?: boolean; bold?: boolean }>`
  font-size: ${({ large }) => (large ? '1.125rem' : '1rem')};
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
  line-height: ${({ large }) => (large ? '1.444444' : '1.5')};
  letter-spacing: -0.01em;
`;

export const LabelType = styled.label<{ large?: boolean }>`
  font-size: ${({ large }) => (large ? '1rem' : '0.75rem')};
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.06em;
`;

export const LinkType = styled.a`
  font-weight: 500;
  text-decoration: undelined;
  color: ${({ theme }) => theme.palette.base.medium};
`;

export const HeadingOneType = styled.h1`
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.3333333;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.palette.base.darker};
`;

export const HeadingTwoType = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.1875;
  letter-spacing: -0.03em;
  color: ${({ theme }) => theme.palette.base.darker};
`;

export const HeadingThreeType = styled.h3`
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.2727272;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.palette.base.darker};
`;
