import { FC, MouseEventHandler, useCallback, useMemo } from 'react';

import { ApolloError } from '@apollo/client';

import BKError from 'src/assets/ErrorIcon.bk.svg';
import PLKError from 'src/assets/ErrorIcon.plk.svg';
import { useBrand } from 'src/brand';
import { useLocale } from 'src/locale';
import { parseError } from 'src/utils';

import {
  ErrorButton,
  ErrorContentWrapper,
  ErrorHeadingText,
  ErrorIcon,
  ErrorSubHeading,
} from './PageLoadError.styled';

export const ERROR_ICONS = {
  bk: BKError,
  plk: PLKError,
  th: BKError, // default to BK until we have TH svg
} as const;

export const PageLoadError: FC<{
  error?: ApolloError;
  showDefaultError?: boolean;
  onRefreshClick?: MouseEventHandler<HTMLButtonElement>;
}> = ({ error, showDefaultError, onRefreshClick }) => {
  const { formatMessage } = useLocale();
  const brand = useBrand();
  const icon = useMemo(() => ERROR_ICONS[brand], [brand]);

  const handleErrorMessage = useCallback(
    (error?: ApolloError) => {
      const defaultError = formatMessage({ id: 'error.somethingWentWrongLoading' });
      const parsedError = parseError(error) ?? defaultError;

      return showDefaultError ? defaultError : parsedError;
    },
    [showDefaultError, formatMessage]
  );

  return (
    <ErrorContentWrapper>
      <ErrorIcon src={icon} />
      <ErrorHeadingText>{formatMessage({ id: 'error.somethingWentWrong' })}</ErrorHeadingText>
      <ErrorSubHeading>{handleErrorMessage(error)}</ErrorSubHeading>
      <ErrorButton onClick={onRefreshClick}>
        {formatMessage({ id: 'error.refreshButtonText' })}
      </ErrorButton>
    </ErrorContentWrapper>
  );
};
