import { SvgIcon } from './SvgIcon';

export const Block: React.FC<React.ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0.487305C5.24218 0.487305 4.52717 0.631697 3.85685 0.921446C3.19015 1.20963 2.6077 1.60194 2.11117 2.09847C1.61463 2.59501 1.22233 3.17746 0.934141 3.84416C0.644509 4.5142 0.5 5.22501 0.5 5.97481C0.5 6.74026 0.642044 7.45915 0.927488 8.12951C1.21163 8.79682 1.60415 9.38162 2.10492 9.88239C2.60547 10.3829 3.18996 10.7774 3.85685 11.0657C4.52717 11.3554 5.24218 11.4998 6 11.4998C6.75782 11.4998 7.47283 11.3554 8.14315 11.0657C8.81005 10.7774 9.39453 10.3829 9.89508 9.88239C10.3956 9.38183 10.7901 8.79735 11.0784 8.13045C11.3682 7.45987 11.5125 6.74065 11.5125 5.97481C11.5125 5.22462 11.3657 4.51348 11.0717 3.84323C10.7794 3.17693 10.3852 2.59479 9.88883 2.09847C9.3923 1.60194 8.80985 1.20963 8.14315 0.921446C7.47283 0.631696 6.75782 0.487305 6 0.487305ZM3.20064 3.18933C3.96906 2.42887 4.89848 2.0498 6 2.0498C6.43927 2.0498 6.85687 2.11564 7.25374 2.24661C7.58374 2.35551 7.89484 2.50983 8.18733 2.71015L2.73788 8.17142C2.54828 7.88463 2.39228 7.57293 2.27001 7.23587C2.12338 6.83165 2.05 6.41164 2.05 5.97481C2.05 4.87395 2.43271 3.9493 3.20064 3.18933ZM4.71921 9.75234C4.37536 9.64323 4.06587 9.48026 3.78964 9.26314L9.27869 3.78595C9.47875 4.07301 9.63483 4.38472 9.74712 4.7216C9.88239 5.12739 9.95 5.54487 9.95 5.97481C9.95 7.08446 9.56692 8.01999 8.79833 8.79257C8.02969 9.56519 7.10059 9.94981 6 9.94981C5.56075 9.94981 5.13405 9.88397 4.71921 9.75234Z"
    />
  </SvgIcon>
);
