import { Override } from '@rbilabs/intl-menu';

import { defaultChannels, serviceModes } from '../../../pages/editor/constants';
import { EditorState, IQuickFillActionType, Update } from '../../types';

import { addUpdate } from './add-update';
import { getQuickFillUpdates } from './get-quick-fill-updates';
import { updateData } from './update-data';

export interface IUpdateProductQuickFillOptions {
  action: IQuickFillActionType;
  displayLateNightPrice: boolean;
}

export const updateProductQuickFill = (
  state: EditorState,
  payload: Update,
  { action, displayLateNightPrice }: IUpdateProductQuickFillOptions
) => {
  const updatesToRemove: Array<string> = [];
  const quickFillUpdates = getQuickFillUpdates(state, payload);

  if (displayLateNightPrice && payload.price) {
    payload.overrides = [
      {
        id: Override.lateNight,
        price: payload.price,
      },
    ];
  } else {
    payload.overrides = undefined;
  }

  serviceModes.forEach(serviceMode => {
    [...defaultChannels, ...state.additionalChannels.map(({ channel }) => channel)].forEach(
      channel => {
        const updateId = `${payload.product}::${serviceMode.id}::${channel}`;
        const prev = state.updates.find(u => u.id === updateId);

        if (!prev) {
          addUpdate(state, payload, updateId, payload.product, channel, serviceMode.id);
          return;
        }

        const dataUpdated = updateData(prev, payload, action, payload.product);

        if (!dataUpdated) {
          updatesToRemove.push(updateId);
        }
      }
    );
  });

  return {
    ...state,
    updates: state.updates.filter(update => !updatesToRemove.includes(update.id)),
    quickFill: { ...state.quickFill, updates: quickFillUpdates },
  };
};
