import {
  FC,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { IsoCountryCode2Char } from '@rbilabs/intl-common';
import {
  IntlFormatters,
  IntlShape,
  MessageDescriptor,
  createIntl,
  createIntlCache,
} from 'react-intl';

import { useAuthContext } from 'src/Auth';
import enDictionary from 'src/locale/dictionaries/en.json';
import { useRematch } from 'src/rematch';
import { select } from 'src/rematch/store';
import { IS_DEV } from 'src/utils/constants';

import { Currency, LocaleCode } from '../generated/markets';

import { CURRENCY_MAPPING, supportedLanguages, supportedLocalesByRegion } from './constants';
import { getLocaleDictionaries } from './dictionaries';
import { LocalizedDictionary } from './types';

export * from './types';

interface ILocaleContext extends Omit<IntlShape, 'formatMessage'> {
  /** Language and grammar (user configurable). */
  language: LocaleCode;
  /** Change application language */
  changeLanguage: (language: LocaleCode) => void;
  /** Change language/grammar. */
  /** Physical region where user is located. */
  region: IsoCountryCode2Char;
  /** Derived state based on region. */
  currency?: Currency;
  /** Type safe message retrieval */
  formatMessage: (
    descriptor: MessageDescriptor & { id: keyof LocalizedDictionary },
    values?: Parameters<IntlFormatters['formatMessage']>[1],
    options?: Parameters<IntlFormatters['formatMessage']>[2]
  ) => ReturnType<IntlFormatters<string>['formatMessage']>;
  /** Transform a cents price into a price string. Ex: 645 => 6,45 € */
  formatPrice: (number?: number | null) => string;
  /** Transform date based on language */
  formatDate: (
    value: Parameters<IntlFormatters['formatDate']>[0],
    options: Parameters<IntlFormatters['formatDate']>[1]
  ) => ReturnType<IntlFormatters<string>['formatDate']>;
}

interface LanguageConfiguration {
  /** Dictionary with application translation */
  messages: Record<string, string>;
  /** User preferred language */
  language: LocaleCode;
}

type LocaleProviderProps = {
  /** Force region (for storybook/testing only!). */
  forceRegion?: IsoCountryCode2Char;
  children: React.ReactNode;
};

export const LocaleContext = createContext<ILocaleContext>({} as ILocaleContext);

export const useLocale = () => useContext(LocaleContext);

const getDefaultLocaleForRegion = (region: IsoCountryCode2Char) =>
  supportedLocalesByRegion[region][0].value;

const getDefaultConfiguration = (region: IsoCountryCode2Char): LanguageConfiguration => {
  const regionLocale = supportedLocalesByRegion[region][0].value;

  const defaultLanguage = supportedLanguages.map(({ value }) => value).includes(regionLocale)
    ? regionLocale
    : 'en-US';

  const language = (localStorage.getItem('selectedLocale') as LocaleCode) ?? defaultLanguage;

  const [baseDictionary, localeDictionary] = getLocaleDictionaries(language);
  return {
    messages: {
      ...enDictionary,
      ...baseDictionary,
      ...localeDictionary,
    },
    language,
  };
};

export const LocaleProvider: FC<LocaleProviderProps> = ({ children, forceRegion }) => {
  const cache = useRef(createIntlCache());
  const { authClient } = useAuthContext();
  const franCountry = authClient.authStateManager.getAuthState()?.idToken?.claims
    .FranCountry as IsoCountryCode2Char;
  const userRegion = useRematch(select.userPermissions.region);
  const currentUser = useRematch(select.user.getState);

  const region = useMemo(() => {
    const regionOverride = new URLSearchParams(window.location.search).get('regionOverride');

    // * Allow a custom region override ONLY on dev
    if (IS_DEV && regionOverride) {
      const isValidRegion =
        regionOverride && (Object.values(IsoCountryCode2Char) as string[]).includes(regionOverride);

      if (isValidRegion) {
        return regionOverride as IsoCountryCode2Char;
      }
    }

    return forceRegion ?? userRegion ?? franCountry ?? IsoCountryCode2Char.US;
  }, [forceRegion, userRegion, franCountry]);

  const currency = useMemo(
    () => (region ? CURRENCY_MAPPING[region] : undefined) as ILocaleContext['currency'],
    [region]
  );

  const locale = useMemo(() => getDefaultLocaleForRegion(region), [region]);

  const [{ messages, language }, setMessagesAndLanguage] = useState<LanguageConfiguration>(
    getDefaultConfiguration(region)
  );

  // Set locale and messages at the same time
  const changeLanguage = useCallback(
    (newLanguage: LocaleCode) => {
      const [baseDictionary, localeDictionary] = getLocaleDictionaries(newLanguage);

      window.localStorage.setItem('selectedLocale', newLanguage);
      setMessagesAndLanguage({
        messages: {
          ...enDictionary,
          ...baseDictionary,
          ...localeDictionary,
        },
        language: newLanguage,
      });
    },
    [setMessagesAndLanguage]
  );

  useLayoutEffect(() => {
    if (!region || currentUser.isLoading) {
      return;
    }

    datadogLogs.addLoggerGlobalContext('region', region);

    // @ts-ignore
    window.ldClient?.identify({
      email: currentUser.email,
      key: currentUser.sub,
      country: region,
    });
  }, [region, currentUser]);

  const intl = useMemo(
    () =>
      createIntl(
        {
          locale,
          messages,
        },
        cache.current
      ),
    [locale, messages]
  );

  const intlDate = useMemo(() => createIntl({ locale: language }), [language]);

  const formatPrice = useCallback(
    (value?: number | null) => {
      if (typeof value === 'number' && !isNaN(value)) {
        return intl.formatNumber(value / 100, { style: 'currency', currency });
      }
      return '--';
    },
    [currency, intl]
  );

  const formatMessage = useCallback(
    (descriptor, values, options): string => {
      return intl.formatMessage(descriptor, values, options);
    },
    [intl]
  );

  const formatDate = useCallback(
    (value, options): string => {
      return intlDate.formatDate(value, options);
    },
    [intlDate]
  );

  const state = useMemo(
    () => ({
      ...intl,
      formatDate,
      formatMessage,
      formatPrice,
      changeLanguage,
      language,
      region,
      currency,
    }),
    [intl, formatDate, formatMessage, formatPrice, language, region, currency, changeLanguage]
  );

  return <LocaleContext.Provider value={state}>{children}</LocaleContext.Provider>;
};
