import { PaymentType } from 'src/graphql';
import { LocalizedDictionary } from 'src/locale';

const paycometApplePay = 'APPLEPAYLINK';

const pathPaymentTranslations = 'delivery-mgmt.restaurantManagement.changeDriver.paidWith';

export const paymentMethodTranslation: Record<string, keyof LocalizedDictionary> = {
  APPLE_PAY: `${pathPaymentTranslations}.apple_pay`,
  BIZUM: `${pathPaymentTranslations}.bizum`,
  CASH: `${pathPaymentTranslations}.cash`,
  CHEQUE_GOURMET: `${pathPaymentTranslations}.chequeGourmet`,
  CHEQUE_GOURMET_VOUCHER: `${pathPaymentTranslations}.chequeGourmetVoucher`,
  CREDIT_CARD: `${pathPaymentTranslations}.credit_card`,
  GIFTCARD: `${pathPaymentTranslations}.giftcard`,
  GOOGLE_PAY: `${pathPaymentTranslations}.google_pay`,
  PAYMENT_ON_DELIVERY_CARD: `${pathPaymentTranslations}.paymentOnDeliveryCard`,
  PAYPAL: `${pathPaymentTranslations}.paypal`,
  SODEXO: `${pathPaymentTranslations}.sodexo`,
  SODEXO_VOUCHER: `${pathPaymentTranslations}.sodexoVoucher`,
  TICKET_RESTAURANT_CARD: `${pathPaymentTranslations}.ticketRestaurantCard`,
  TICKET_RESTAURANT_VOUCHER: `${pathPaymentTranslations}.ticketRestaurantVoucher`,
  UNPAID: `${pathPaymentTranslations}.unpaid`,
  WAYLET: `${pathPaymentTranslations}.waylet`,
  VAULTED_ACCOUNT: `${pathPaymentTranslations}.vaulted_account`,
};

/**
 * Receives the payment type (either paymentMethodBrand or paymentMethod) and return Lokalise key
 */
export const getPaymentTranslationKey = (paymentType: string): keyof LocalizedDictionary => {
  const paymentMethod = paymentType === paycometApplePay ? PaymentType.APPLE_PAY : paymentType;
  return paymentMethodTranslation[paymentMethod];
};

const pathCloseIssueTranslation = 'delivery-mgmt.dmpInspector.orderDetails.issueOptions';

export const issueOptionsTranslation: Record<string, keyof LocalizedDictionary> = {
  '0001': `${pathCloseIssueTranslation}.wrongAddress`,
  '0002': `${pathCloseIssueTranslation}.customerAbsent`,
  '0003': `${pathCloseIssueTranslation}.lateDelivery`,
  '0004': `${pathCloseIssueTranslation}.productQuality`,
  '0005': `${pathCloseIssueTranslation}.fakeOrder`,
  '0006': `${pathCloseIssueTranslation}.wrongProduct`,
  '0007': `${pathCloseIssueTranslation}.paymentIssues`,
};

const pathCancelReasonTranslation = 'delivery-mgmt.dmpInspector.orderDetails.cancelReasonOptions';

export const cancelReasonsTranslation: Record<string, keyof LocalizedDictionary> = {
  '0001': `${pathCancelReasonTranslation}.customerRequest`,
  '0002': `${pathCancelReasonTranslation}.fakeOrder`,
  '0003': `${pathCancelReasonTranslation}.technicalIssues`,
  '0004': `${pathCancelReasonTranslation}.lastMileReason`,
};

export const formatIssueOrReasonOptionId = (id: string): string =>
  id.split('-')[id.split('-').length - 1];

export const getOrderChannel = (value: string): string =>
  value?.replace(/([A-Z])/g, ' $1')?.replace(/^./, str => str.toUpperCase());
