import { useEffect, useState } from 'react';

import { IsoCountryCode2Char } from '@rbilabs/intl-common';

import { Button } from 'src/components/V2/Button';
import { Modal } from 'src/components/V2/Modal';
import { IMarket } from 'src/generated/markets';
import { useLocale } from 'src/locale';

import { Flag } from '../Flag';
import { Check } from '../Icons/Check';

import { Container, Icon, Item, ScrollContainer } from './RegionSwitcher.styled';

interface RegionModalProps {
  onDissmisModal: () => void;
  onConfirm: (region: IsoCountryCode2Char) => void;
  isOpen: boolean;
  selected: IsoCountryCode2Char;
  items: IMarket[];
}

export const RegionModal: React.FC<RegionModalProps> = props => {
  const { formatMessage } = useLocale();
  const [regionSelected, setRegionSelected] = useState(props.selected);

  useEffect(() => {
    setRegionSelected(props.selected);
  }, [props.isOpen, props.selected]);

  return (
    <Modal
      open={props.isOpen}
      onDissmisModal={props.onDissmisModal}
      title={formatMessage({ id: 'region.swhitcher.title' })}
      actions={
        <>
          <Button type="button" large secondary onClick={props.onDissmisModal}>
            {formatMessage({ id: 'region.swhitcher.cancelButton' })}
          </Button>
          <Button
            type="submit"
            name="confirm"
            large
            onClick={() => props.onConfirm(regionSelected)}
          >
            {formatMessage({ id: 'region.swhitcher.confirmButton' })}
          </Button>
        </>
      }
    >
      <ScrollContainer>
        <Container data-testid={'region-switcher-modal-container'}>
          {props.items?.map(item => (
            <Item
              data-testid={item.regionCode}
              key={item.regionCode}
              onClick={() => setRegionSelected(item.regionCode as IsoCountryCode2Char)}
            >
              <Flag country={item.regionCode as IsoCountryCode2Char} />
              <span>{item.regionName}</span>
              {item.regionCode === regionSelected && (
                <Icon>
                  <Check />
                </Icon>
              )}
            </Item>
          ))}
        </Container>
      </ScrollContainer>
    </Modal>
  );
};
