import styled from 'styled-components';

/**
 * Base svg component
 * @param size?: {number} of pixels
 * @param color?: {string} css valid string color such as hex or rgba
 */
export const SvgIcon = styled.svg<{ size?: number; color?: string }>`
  fill: ${({ color }) => color};
  width: ${({ size }) => size && `${size}px`};
  height: ${({ size }) => size && `${size}px`};
  user-select: none;
  pointer-events: none;
  line-height: 0;
`;
