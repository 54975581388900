import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const DecrementButton: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7359 20.9908H27.9359C28.433 20.9908 28.8359 20.5431 28.8359 19.9908C28.8359 19.4385 28.433 18.9908 27.9359 18.9908H11.7359C11.2389 18.9908 10.8359 19.4385 10.8359 19.9908C10.8359 20.5431 11.2389 20.9908 11.7359 20.9908Z"
      fill="white"
    />
  </SvgIcon>
);
