import { PosVendor } from '@rbilabs/intl-common';
import { Override } from '@rbilabs/intl-menu';

import {
  LightRestaurantFragmentFragment,
  ProductFragmentFragment,
  ProductPriceFragmentFragment,
} from 'src/graphql';
import { Channel, RestaurantFragmentFragment, ServiceMode } from 'src/graphql/__generated__/main';

import { Plus } from '../utils';

import { Batch } from './utils';

export type RestaurantWithMenu = LightRestaurantFragmentFragment & {
  menuPrices?: readonly ProductPriceFragmentFragment[] | null;
};

export enum EditMode {
  Price = 'price',
  Availability = 'availability',
}

export type MenuQueryDecompressed = {
  readonly __typename: 'Query';
  readonly menu: ReadonlyArray<
    | {
        readonly __typename: 'Combo';
        readonly id: string;
        readonly name: string;
        readonly plusComposite?: string | null;
        readonly sanityUrl?: string | null;
        readonly sections?: ReadonlyArray<string | null> | null;
        readonly vendorConfigs?: {
          readonly __typename: 'VendorConfigs';
          readonly carrols?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly carrolsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncr?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncrDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheics?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheicsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partner?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partnerDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumber?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicom?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicomDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdi?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdiDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qst?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qstDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rpos?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rposDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tablet?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tabletDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
        } | null;
        readonly productHierarchy?: ReadonlyArray<{
          readonly __typename: 'ProductHierarchy';
          readonly id: string;
          readonly name: string;
          readonly type: string;
          readonly productHierarchy?: ReadonlyArray<{
            readonly __typename: 'ProductHierarchy';
            readonly id: string;
            readonly name: string;
            readonly type: string;
            readonly productHierarchy?: ReadonlyArray<{
              readonly __typename: 'ProductHierarchy';
              readonly id: string;
              readonly name: string;
              readonly type: string;
              readonly productHierarchy?: ReadonlyArray<{
                readonly __typename: 'ProductHierarchy';
                readonly id: string;
                readonly name: string;
                readonly type: string;
              } | null> | null;
            } | null> | null;
          } | null> | null;
        } | null> | null;
        readonly parentProducts?: ReadonlyArray<{
          readonly __typename: 'ProductHierarchy';
          readonly id: string;
          readonly name: string;
          readonly type: string;
        } | null> | null;
      }
    | {
        readonly __typename: 'Item';
        readonly id: string;
        readonly name: string;
        readonly plusComposite?: string | null;
        readonly sanityUrl?: string | null;
        readonly sections?: ReadonlyArray<string | null> | null;
        readonly vendorConfigs?: {
          readonly __typename: 'VendorConfigs';
          readonly carrols?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly carrolsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncr?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncrDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheics?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheicsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partner?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partnerDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumber?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicom?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicomDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdi?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdiDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qst?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qstDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rpos?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rposDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tablet?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tabletDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
        } | null;
        readonly productHierarchy?: ReadonlyArray<{
          readonly __typename: 'ProductHierarchy';
          readonly id: string;
          readonly name: string;
          readonly type: string;
          readonly productHierarchy?: ReadonlyArray<{
            readonly __typename: 'ProductHierarchy';
            readonly id: string;
            readonly name: string;
            readonly type: string;
            readonly productHierarchy?: ReadonlyArray<{
              readonly __typename: 'ProductHierarchy';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null> | null;
          } | null> | null;
        } | null> | null;
        readonly parentProducts?: ReadonlyArray<{
          readonly __typename: 'ProductHierarchy';
          readonly id: string;
          readonly name: string;
          readonly type: string;
        } | null> | null;
      }
    | {
        readonly __typename: 'ModifierMultiplier';
        readonly id: string;
        readonly name: string;
        readonly prefix: string;
        readonly quantity: number;
        readonly plusComposite?: string | null;
        readonly sanityUrl?: string | null;
        readonly sections?: ReadonlyArray<string | null> | null;
        readonly modifier: {
          readonly __typename: 'Modifier';
          readonly id: string;
          readonly name: string;
        };
        readonly vendorConfigs?: {
          readonly __typename: 'VendorConfigs';
          readonly carrols?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly carrolsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncr?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncrDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheics?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheicsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partner?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partnerDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumber?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicom?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicomDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdi?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdiDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qst?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qstDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rpos?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rposDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tablet?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tabletDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
        } | null;
        readonly productHierarchy?: ReadonlyArray<{
          readonly __typename: 'ProductHierarchy';
          readonly id: string;
          readonly name: string;
          readonly type: string;
          readonly productHierarchy?: ReadonlyArray<{
            readonly __typename: 'ProductHierarchy';
            readonly id: string;
            readonly name: string;
            readonly type: string;
          } | null> | null;
        } | null> | null;
      }
    | {
        readonly __typename: 'Offer';
        readonly id: string;
        readonly name: string;
        readonly plusComposite?: string | null;
        readonly sanityUrl?: string | null;
        readonly vendorConfigs?: {
          readonly __typename: 'VendorConfigs';
          readonly carrols?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly carrolsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncr?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncrDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheics?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheicsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partner?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partnerDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumber?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicom?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicomDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdi?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdiDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qst?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qstDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rpos?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rposDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tablet?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tabletDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
        } | null;
        readonly productHierarchy?: ReadonlyArray<{
          readonly __typename: 'ProductHierarchy';
          readonly id: string;
          readonly name: string;
          readonly type: string;
          readonly productHierarchy?: ReadonlyArray<{
            readonly __typename: 'ProductHierarchy';
            readonly id: string;
            readonly name: string;
            readonly type: string;
            readonly productHierarchy?: ReadonlyArray<{
              readonly __typename: 'ProductHierarchy';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      }
    | {
        readonly __typename: 'Reward';
        readonly id: string;
        readonly name: string;
        readonly plusComposite?: string | null;
        readonly sanityUrl?: string | null;
        readonly vendorConfigs?: {
          readonly __typename: 'VendorConfigs';
          readonly carrols?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly carrolsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncr?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncrDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheics?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheicsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partner?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partnerDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumber?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicom?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicomDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdi?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdiDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qst?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qstDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rpos?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rposDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tablet?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tabletDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
        } | null;
        readonly productHierarchy?: ReadonlyArray<{
          readonly __typename: 'ProductHierarchy';
          readonly id: string;
          readonly name: string;
          readonly type: string;
          readonly productHierarchy?: ReadonlyArray<{
            readonly __typename: 'ProductHierarchy';
            readonly id: string;
            readonly name: string;
            readonly type: string;
            readonly productHierarchy?: ReadonlyArray<{
              readonly __typename: 'ProductHierarchy';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      }
    | {
        readonly __typename: 'SystemwideOffer';
        readonly id: string;
        readonly name: string;
        readonly plusComposite?: string | null;
        readonly sanityUrl?: string | null;
        readonly vendorConfigs?: {
          readonly __typename: 'VendorConfigs';
          readonly carrols?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly carrolsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncr?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly ncrDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheics?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly oheicsDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partner?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly partnerDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumber?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicom?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly sicomDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdi?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qdiDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qst?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly qstDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rpos?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly rposDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tablet?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
          readonly tabletDelivery?: {
            readonly __typename: 'VendorConfig';
            readonly pluType?: string | null;
            readonly parentSanityId?: string | null;
            readonly pullUpLevels?: number | null;
            readonly constantPlu?: string | null;
            readonly quantityBasedPlu?: ReadonlyArray<{
              readonly __typename: 'QuantityBasedPlu';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly multiConstantPlus?: ReadonlyArray<{
              readonly __typename: 'MultiConstantPlus';
              readonly quantity?: number | null;
              readonly plu?: string | null;
              readonly qualifier?: string | null;
            } | null> | null;
            readonly parentChildPlu?: {
              readonly __typename: 'ParentChildPlu';
              readonly plu?: string | null;
              readonly childPlu?: string | null;
            } | null;
            readonly sizeBasedPlu?: {
              readonly __typename: 'SizeBasedPlu';
              readonly comboPlu?: string | null;
              readonly comboSize?: string | null;
            } | null;
          } | null;
        } | null;
        readonly productHierarchy?: ReadonlyArray<{
          readonly __typename: 'ProductHierarchy';
          readonly id: string;
          readonly name: string;
          readonly type: string;
          readonly productHierarchy?: ReadonlyArray<{
            readonly __typename: 'ProductHierarchy';
            readonly id: string;
            readonly name: string;
            readonly type: string;
            readonly productHierarchy?: ReadonlyArray<{
              readonly __typename: 'ProductHierarchy';
              readonly id: string;
              readonly name: string;
              readonly type: string;
            } | null> | null;
          } | null> | null;
        } | null> | null;
      }
  >;
};

export type IQuickFillActionType = 'price' | 'availability';

export type Update = {
  id: string;
  product: string;
  channel: string;
  serviceMode: string;
  price?: number | null;
  availability?: boolean | null;
  overrides?: {
    id: Override;
    price: number;
  }[];
};

export type EditorProduct = {
  id: ProductFragmentFragment['id'];
  name: ProductFragmentFragment['name'];
  __typename: ProductFragmentFragment['__typename'];
  plus: Plus | null;
};

export type QuickFill = {
  enabled: boolean;
  updates: Array<Update>;
};

export type EditorAdditionalChannel = {
  channel: Channel;
  serviceModes: {
    id: ServiceMode;
    tr: 'editor.serviceModeEatIn' | 'editor.serviceModeDelivery';
  }[];
};

export interface EditorState {
  additionalChannels: EditorAdditionalChannel[];
  MAX_PRODUCTS_PER_BATCH: number;
  MAX_STORES_PER_BATCH: number;
  region: string;
  restaurants: Partial<Record<PosVendor, Array<RestaurantFragmentFragment['id']>>> | null;
  products: Array<EditorProduct>;
  initialUpdates: Array<Update>;
  updates: Array<Update>;
  validatedBatches: Batch[];
  quickFill: QuickFill;
  isLoading?: boolean;
  isCopyMode?: boolean;
  isModalVisible?: boolean;
}
