import { SvgIcon } from './SvgIcon';

export const Check: React.FC<React.ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50836 4.40836C9.83055 4.08618 9.83055 3.56382 9.50836 3.24164C9.18618 2.91945 8.66382 2.91945 8.34164 3.24164L4.40239 7.18088L2.90247 5.71081C2.57707 5.39188 2.05474 5.39712 1.73581 5.72253C1.41688 6.04793 1.42212 6.57026 1.74753 6.88919L3.83075 8.93097C4.15384 9.24764 4.67169 9.24504 4.99158 8.92514L9.50836 4.40836Z"
    />
  </SvgIcon>
);
