/* istanbul ignore file */
import { Datacenter, datadogLogs } from '@datadog/browser-logs';

export function init(): void {
  if (process.env.NODE_ENV === 'test') {
    return;
  }

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN || '',
    datacenter: Datacenter.EU,
    forwardErrorsToLogs: true,
    env: process.env.REACT_APP_STAGE,
    service: `${process.env.REACT_APP_BRAND}_fzportal`,
    sampleRate: 100,
  });

  datadogLogs.setLoggerGlobalContext({
    brand: process.env.REACT_APP_BRAND,
    stage: process.env.REACT_APP_STAGE,
    platform: 'fzportal',
  });
}
