import { createModel } from '@rematch/core';

import {
  DriverDeliveryAvailabilityStatus,
  Driver as DriverGQL,
  DriverRealtimeStatus,
  GetAllDriversQuery,
} from 'src/graphql';

import type { RootModel } from '.';

export enum DriverStatus {
  AVAILABLE = 'Available',
  IN_DELIVERY = 'In Delivery',
  INACTIVE = 'Inactive',
}

export type Driver = DriverGQL & {
  status: DriverStatus;
};

interface DriversState {
  data: Driver[];
  searchTerm: string;
  status: DriverStatus | null;
  selectedDriverId: null | string;
  showModalDeleteDriver: boolean;
}

export const drivers = createModel<RootModel>()({
  state: {
    data: [],
    searchTerm: '',
    status: null,
    selectedDriverId: null,
    showModalDeleteDriver: false,
  } as DriversState,
  reducers: {
    setDrivers(state, data: GetAllDriversQuery) {
      const allDrivers = data.allDrivers?.allDrivers;
      const drivers: Driver[] = (allDrivers as DriverGQL[]).map((driver: DriverGQL) => {
        let status = DriverStatus.INACTIVE;
        if (
          driver.deliveryAvailabilityStatus === DriverDeliveryAvailabilityStatus.DRIVER_AVAILABLE
        ) {
          status = DriverStatus.AVAILABLE;
        }

        if (driver.realtimeStatus === DriverRealtimeStatus.DRIVER_DELIVERING) {
          status = DriverStatus.IN_DELIVERY;
        }

        return { ...driver, status };
      });

      return {
        ...state,
        data: drivers,
      };
    },
    setSearchTerm(state, newSearchTerm: string) {
      return {
        ...state,
        searchTerm: newSearchTerm,
      };
    },
    setStatus(state, status: DriverStatus | null) {
      return {
        ...state,
        status: status === state.status ? null : status,
      };
    },
    setSelectedDriverId(state, driverId: string) {
      return {
        ...state,
        selectedDriverId: driverId,
      };
    },
  },
  selectors: (slice, createSelector) => ({
    getAllDrivers() {
      return slice(state => state.data);
    },
    getSearchTerm() {
      return slice(state => state.searchTerm);
    },
    getSelectedStatus() {
      return slice(state => state.status);
    },
    getSelectedDriverId() {
      return slice(state => state.selectedDriverId);
    },
    getAvailableStatus() {
      return slice(state => {
        const statuses: DriverStatus[] = [];
        state.data.forEach(driver => {
          const status = driver.status;
          if (status) {
            statuses.push(status);
          }
        });
        return [...new Set(statuses)];
      });
    },
    getShowModalDeleteDriver() {
      return slice(state => state.showModalDeleteDriver);
    },
    filteredDrivers() {
      return createSelector(
        this.getSearchTerm,
        this.getSelectedStatus,
        this.getAllDrivers,
        (st, sst, ad) => {
          const searchTerm = st as unknown as string;
          const status = sst as unknown as DriverStatus;
          const allDrivers = ad as unknown as Driver[];

          const filterBySearchTerm = (driver: Driver) =>
            [driver.firstName?.toLowerCase(), driver.lastName?.toLowerCase()].some(term =>
              term?.includes(searchTerm.toLowerCase())
            );
          if (!allDrivers.length) {
            return [];
          }
          if (!searchTerm && !status) {
            return allDrivers;
          }

          let results = allDrivers;

          if (status) {
            results = results.filter(drivers => drivers.status?.includes(status));
          }

          if (searchTerm) {
            results = results.filter(filterBySearchTerm);
          }

          return results;
        }
      );
    },
    hasActiveFilters() {
      return createSelector(this.filteredDrivers, this.getAllDrivers, (fr, ad) => {
        const filteredResult = fr as unknown as Driver[];
        const allDrivers = ad as unknown as Driver[];

        return filteredResult.length !== allDrivers.length;
      });
    },
    getSelectedDriverStatus() {
      return createSelector(this.getSelectedDriverId, this.getAllDrivers, (di, ad) => {
        const selectedDriverId = di as unknown as string;
        const allDrivers = ad as unknown as Driver[];

        return allDrivers.find(driver => driver.identifier === selectedDriverId)?.status;
      });
    },
  }),
});
