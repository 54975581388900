import { forwardRef, useRef, useState } from 'react';

import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { useOutsideClick } from 'src/hooks/useOutsideClick';

import { PopoverReference, PopperContainer } from './Popover.styled';

export interface PopoverProps {
  children: React.ReactNode;
  content: React.ReactNode;
  onClickPopover?: () => void;
  toggleOnClick?: boolean;
  options?: Parameters<typeof usePopper>[2];
  className?: string;
  testId?: string;
  ariaLabel?: string;
}

export type PopoverRef = {
  /** Reference to function responsible for toggling the select */
  closePopover: () => void;
};

export const Popover = forwardRef<PopoverRef, PopoverProps>(
  (
    {
      children,
      onClickPopover,
      toggleOnClick = true,
      content,
      options = {},
      className,
      testId,
      ariaLabel,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const internalRef = useRef<HTMLButtonElement>(null);
    const [referenceElement, setReferenceElement] = useState<Element | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'bottom',
      strategy: 'fixed',
      ...options,
    });

    const toggleIsOpen = () => {
      setIsOpen(prev => !prev);
    };

    const handleClick = (e: React.MouseEvent) => {
      toggleOnClick ? toggleIsOpen() : !isOpen && setIsOpen(true);
      onClickPopover?.();
      !toggleOnClick && e.stopPropagation();
    };

    useOutsideClick({ ref: internalRef, callback: () => isOpen && toggleIsOpen() });

    if (ref !== null && typeof ref !== 'function') {
      ref.current = { closePopover: toggleIsOpen };
    }

    return (
      <PopperContainer
        aria-label={ariaLabel}
        onClick={(e: React.MouseEvent) => {
          handleClick(e);
        }}
        ref={internalRef}
        className={className}
        data-testid={testId}
      >
        <PopoverReference ref={setReferenceElement}>{children}</PopoverReference>
        {isOpen &&
          createPortal(
            <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
              {content}
            </div>,
            document.getElementById('popper_root')!
          )}
      </PopperContainer>
    );
  }
);
