import PacmanLoader from 'react-spinners/PacmanLoader';
import { useTheme } from 'styled-components';

export { LoadingWrapper } from './Loading.styled';

/**
 * Renders the pacman Loading component with the common theming.
 *
 * @param {typeof PacmanLoader} props
 *
 * @returns {FC<ComponentProps<typeof PacmanLoader>>}
 */
export const Loading: React.FC<React.ComponentProps<typeof PacmanLoader>> = props => {
  const theme = useTheme();
  const color = theme?.palette.base.medium ?? '#507083';
  return (
    <span data-testid="loading-indicator">
      <PacmanLoader size={20} color={color} {...props} />
    </span>
  );
};
