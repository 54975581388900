import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const DeliveryArea: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 18 18" {...props}>
    <path
      d="M6.09898 0.244141L0.611955 2.09261C0.40765 2.16071 0.261719 2.33583 0.261719 2.55959V17.2695C0.261719 17.3985 0.312968 17.5222 0.404193 17.6135C0.495418 17.7047 0.619146 17.7559 0.748157 17.7559L0.903818 17.7267L6.09898 15.7129L11.9362 17.7559L17.4233 15.9075C17.6276 15.8394 17.7735 15.6642 17.7735 15.4405V0.730579C17.7735 0.601568 17.7223 0.47784 17.631 0.386615C17.5398 0.29539 17.4161 0.244141 17.2871 0.244141L17.1314 0.273327L11.9362 2.28718L6.09898 0.244141ZM5.12611 2.62769V14.0104L2.20747 15.1389V3.6103L5.12611 2.62769ZM7.07186 2.64715L10.9634 4.00918V15.3529L7.07186 13.9909V2.64715ZM15.8278 2.87091V14.3898L12.9091 15.3724V3.99945L15.8278 2.87091ZM4.60075 3.45464L2.76201 4.10646V6.19815L4.60075 5.54632V3.45464ZM4.60075 6.13005L2.76201 6.78188V8.87356L4.60075 8.22174V6.13005ZM4.60075 8.80546L2.76201 9.45729V11.549L4.60075 10.8971V8.80546ZM4.60075 11.4809L2.76201 12.1327V14.2244L4.60075 13.5726V11.4809Z"
      fill="#507083"
    />
  </SvgIcon>
);
