// TODO: remove when fixing okta user for PT BK PROD
// we are using the X-Ui-Region from the request headers to check
// what region should be used for some parts of the code

import { Brand, IsoCountryCode2Char } from '@rbilabs/intl-common';

// the value of that header is filled with FranCountry field from Okta-Id-Token
export const shouldForceRegion = (
  oktaId: string,
  defaultRegion: IsoCountryCode2Char | string
): IsoCountryCode2Char => {
  const accountsMap: Record<string, { [key: string]: string[] }> = {
    [Brand.BK]: {
      [IsoCountryCode2Char.PT]: [
        'ADRCAL024',
        'BORGON010',
        'MARSAS001',
        'THJANDOE02',
        'JOSWAN005',
        'RITAND003',
        'CARKLI002',
        'RAUMOR008',
        'SARLOZ003',
        'BKDIACARV00',
        'CBAPTISTA001',
        'DRIBEIRO000',
        'BKLUIPERE00',
        'BKHELPINT00',
        'SARMEN024',
        'ELIJES003',
        'CGAIOLA009',
        'ADACOS001',
        'ANDALB003',
        'APEREIRA00',
        'JOSSIL033',
        'PATROD060',
        'FFERREIRA001',
        'DFILIPE00',
        'BKNELRICO00',
        'JOAROD005',
        'VERTOR011',
        'HALROD003',
      ],
    },
    [Brand.TH]: {
      [IsoCountryCode2Char.AQ]: ['GBTES0001'],
    },
  };

  // Find a user country by oktaId and brand
  const results = Object.entries(
    accountsMap[process.env.REACT_APP_BRAND.toUpperCase()!] || {}
  ).find(([, users]: [string, string[]]) => users.includes(oktaId));

  return (results ?? [defaultRegion]).shift() as unknown as IsoCountryCode2Char;
};
