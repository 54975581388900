import { PageHeaderContent, PageHeaderWrapper } from './Page.styled';

export * from './Page.styled';

export interface PageHeaderProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ children, style = {} }) => (
  <PageHeaderWrapper>
    <PageHeaderContent as="div" style={style}>
      {children}
    </PageHeaderContent>
  </PageHeaderWrapper>
);
