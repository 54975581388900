import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const InfoBox: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M9 22C8.4 22 8 21.6 8 21V18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H13.9L10.2 21.7C10 21.9 9.8 22 9.5 22H9ZM13 11V5H11V11M13 15V13H11V15H13Z"
      fill="#97B0BD"
    />
  </SvgIcon>
);
