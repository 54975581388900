type LineSegments = {
  x: number;
  y: number;
};

export function doLineSegmentsIntersect(
  p: LineSegments,
  p2: LineSegments,
  q: LineSegments,
  q2: LineSegments
): boolean {
  const r = subtractPoints(p2, p);
  const s = subtractPoints(q2, q);

  const uNumerator = crossProduct(subtractPoints(q, p), r);
  const denominator = crossProduct(r, s);

  if (denominator === 0) {
    // lines are parallel
    return false;
  }
  const u = uNumerator / denominator;
  const t = crossProduct(subtractPoints(q, p), s) / denominator;

  return t >= 0 && t <= 1 && (u >= 0 || u === 0) && u <= 1;
}

/**
 * Calculate the cross product of the two points.
 *
 * @param {LineSegments} point1 point object with x and y coordinates
 * @param {LineSegments} point2 point object with x and y coordinates
 *
 * @return the cross product result as a float
 */
function crossProduct(point1: LineSegments, point2: LineSegments) {
  return point1.x * point2.y - point1.y * point2.x;
}

/**
 * Subtract the second point from the first.
 *
 * @param {LineSegments} point1 point object with x and y coordinates
 * @param {LineSegments} point2 point object with x and y coordinates
 *
 * @return the subtraction result as a point object
 */
function subtractPoints(point1: LineSegments, point2: LineSegments) {
  const result = {} as LineSegments;
  result.x = point1.x - point2.x;
  result.y = point1.y - point2.y;

  return result;
}
