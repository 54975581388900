import { Currency, LocaleCode, MARKETS } from '../generated/markets';

export const LOCALE_SEPARATOR = '-';

export const CURRENCY_MAPPING: Record<string, Currency> = Object.fromEntries(
  MARKETS.map(market => [market.regionCode, market.regionCurrency])
);

export const supportedLanguages: { value: LocaleCode; label: string }[] = [
  { value: 'en-US', label: 'English (US)' },
  { value: 'en-GB', label: 'English (UK)' },
  { value: 'de-DE', label: 'German (Germany)' },
  { value: 'es-ES', label: 'Spanish (Spain)' },
  { value: 'pt-PT', label: 'Portuguese (Portugal)' },
];

// * The first language in the supported set will be treated as the default language for that region
export const supportedLocalesByRegion: {
  [key: string]: {
    value: LocaleCode;
  }[];
} = Object.fromEntries(
  MARKETS.map(market => [
    market.regionCode,
    market.regionLanguages.map(language => ({
      value: language.languageLocale,
    })),
  ])
);
