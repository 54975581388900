import { createModel } from '@rematch/core';

import { LastMileProviders } from 'src/graphql';

import type { RootModel } from '.';

export const regionLastMileProviders = createModel<RootModel>()({
  state: {
    lastMileProviders: new Array<LastMileProviders>(),
  },
  reducers: {
    setRegionLastMileProviders(state, payload: Array<LastMileProviders>) {
      return {
        ...state,
        lastMileProviders: [...payload],
      };
    },
  },
  selectors: slice => ({
    getRegionLastMileProviders() {
      return slice(state => state.lastMileProviders);
    },
  }),
});
