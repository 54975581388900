import { EditorState, Update } from '../../types';

export const addUpdate = (
  state: EditorState,
  payload: Update,
  updateId: string,
  productId: string,
  channelId: string,
  serviceModeId: string
) => {
  const update: Update = {
    ...payload,
    id: updateId,
    product: productId,
    serviceMode: serviceModeId,
    channel: channelId,
  };

  state.updates.push(update);
};
