import { ProductFragmentFragment, ProductPrice } from 'src/graphql';

import { getPlus } from './plus';

/**
 * Returns the sections field of a product or an empty array for products without them
 */
export const getProductSections = (product: ProductFragmentFragment): string[] => {
  if ('sections' in product && product.sections) {
    return product.sections.filter(p => typeof p === 'string') as string[];
  }

  return [];
};

/**
 * Return the product with price and availability if it matches some of the menu prices.
 * It searches the menuPrices array using the IDs and PLUs.
 */
export const matchProductAndPrice = (
  menuPrices: ProductPrice[],
  product: ProductFragmentFragment
): ProductFragmentFragment & { price?: number | null; availability?: boolean | null } => {
  let match: ProductPrice | undefined;

  match = menuPrices.find(mp => mp.productId === product.id);

  if (!match) {
    const plus = getPlus(product.vendorConfigs);

    if (plus) {
      Object.entries(plus).forEach(([_, plu]) => {
        match = menuPrices?.find(mp => mp.plu === plu.value);
      });
    }
  }

  return {
    ...product,
    price: match?.price,
    availability: match?.availability,
  };
};
