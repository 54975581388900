import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { AuthState, OktaAuth, OktaAuthOptions, UserClaims } from '@okta/okta-auth-js';
import { IsoCountryCode2Char } from '@rbilabs/intl-common';

import { UserRole } from 'src/graphql';

import { NoAccess } from './pages/no-access/NoAccess';
import { dispatch } from './rematch/store';

type CustomUserClaims = {
  UserID: string;
  UserType: UserRole;
  FranCountry: IsoCountryCode2Char;
};

export type RbiUserClaims = UserClaims<CustomUserClaims>;

export const OKTA_CONFIG: OktaAuthOptions = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  issuer: process.env.REACT_APP_ISSUER!,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: false,
  tokenManager: {
    autoRenew: true,
  },
};

export interface AuthContextType {
  authClient: OktaAuth;
}

export const AuthContext = createContext<AuthContextType>({ authClient: {} as OktaAuth });

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    isPending: true,
  });

  const authClient = useMemo(() => new OktaAuth(OKTA_CONFIG), []);

  useEffect(() => {
    // initialize auth service
    authClient.start();

    // * Subscribe with internal state is needed to trigger updates/redirect
    authClient.authStateManager.subscribe(setAuthState);

    // Update for initial render
    authClient.authStateManager.updateAuthState();

    // Check for changes to local storage every second
    const interval = window.setInterval(() => authClient.authStateManager.updateAuthState(), 1000);
    return () => clearInterval(interval);
  }, [authClient]);

  // Handle token retrieval
  useEffect(() => {
    const { isPending, isAuthenticated, error } = authState;
    // Ignore if pending or authenticated
    if (isPending || isAuthenticated || error) {
      return;
    }

    // Store tokens if sourced from redirect
    if (authClient.isLoginRedirect()) {
      authClient.handleRedirect().catch(e => {
        setAuthState(e);
      });
      return;
    }

    authClient.token.getWithRedirect();
  }, [authClient, authState]);

  // Handle state logging
  useEffect(() => {
    const { isPending, isAuthenticated, error } = authState;

    if (!isPending) {
      if (error) {
        datadogLogs.logger.error('error while authenticating with okta', {
          message: error.message,
        });
      } else if (!isAuthenticated) {
        datadogLogs.logger.warn('user not authenticated via okta');
      } else {
        datadogLogs.logger.info('user successfully authenticated via okta');
      }
    }
  }, [authState.isPending, authState.isAuthenticated, authState.error]);

  // Handle user object
  useEffect(() => {
    const claims = authState?.idToken?.claims as RbiUserClaims;

    if (!claims) {
      return;
    }

    // TODO? Retrieve user info from Auth context as opposed to Redux
    dispatch.user.setUserAsync(claims);
  }, [authState]);

  // User has no Okta access
  if (authState.error) {
    return <NoAccess isLoading={false} />;
  }

  // Pending auth check / redirecting to login / fetching user
  if (!authState.isAuthenticated) {
    return <NoAccess isLoading={true} />;
  }

  if (!authState.idToken?.claims) {
    // TODO! Consider i18n now that LocaleProvider is a descendent of AuthProvider
    return (
      <>
        Error fetching user
        <a href="/">Reload</a>
      </>
    );
  }

  return <AuthContext.Provider value={{ authClient }}>{children}</AuthContext.Provider>;
};
