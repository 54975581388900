import {
  MultiConstantPlus,
  ParentChildPlu,
  QuantityBasedPlu,
  SizeBasedPlu,
  VendorConfig,
  VendorConfigs,
} from 'src/graphql';

export type PluType = Exclude<keyof VendorConfig, '__typename'>;
export type PluVendor = Exclude<keyof VendorConfigs, '__typename'>;
export type PluValue = {
  type: PluType;
  value: string;
};
export type Plus = Partial<Record<PluVendor, PluValue>>;

/** Regular plus are just strings or numbers */
const parsePlu = (plu: string | number): string => plu.toString();

/** Parse array based plus like multiContant & qualityBased plus  */
const parseArrayBasedPlu = (plu: MultiConstantPlus[] | QuantityBasedPlu[]): string =>
  plu && plu.length > 0 && plu[0]?.plu ? plu[0].plu : '';

/** sizeBased plus parser */
const parseSizeBasedPlu = (plu: SizeBasedPlu): string =>
  plu.comboPlu && plu.comboSize ? [plu.comboPlu, plu.comboSize].join('-') : '';

/** parentChild plus parser */
const parseParentChildPlu = (plu: ParentChildPlu): string =>
  plu.plu && plu.childPlu ? [plu.plu, plu.childPlu].join('-') : '';

/** parser map */
export const pluTypeParser = {
  constantPlu: parsePlu,
  multiConstantPlus: parseArrayBasedPlu,
  parentChildPlu: parseParentChildPlu,
  parentSanityId: parsePlu,
  pluType: parsePlu,
  pullUpLevels: parsePlu,
  quantityBasedPlu: parseArrayBasedPlu,
  sizeBasedPlu: parseSizeBasedPlu,
};

/**
 * Extracts the plu value and type of a product VendorConfigs where plu key will be the
 * pos vendor ex: ncr, rpos, etc, the type will be the pluType and the value plu value
 */
export const getPlus = (vendorConfigs?: VendorConfigs | null): Plus | null => {
  if (!vendorConfigs) {
    return null;
  }

  return Object.entries(vendorConfigs).reduce<Plus>((hash, [vendor, config]) => {
    // return early if config can't be parsed
    if (!config || typeof config === 'string' || !config.pluType) {
      return hash;
    }

    const type = config.pluType as PluType;
    const currentPlu: any = config[type];

    if (!currentPlu) {
      return hash;
    }

    const value = pluTypeParser?.[type]?.(currentPlu) ?? '';

    return {
      ...hash,
      [vendor]: {
        type,
        value,
      },
    };
  }, {});
};
