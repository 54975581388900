import styled from 'styled-components';

export const PopperContainer = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  width: 100%;
  cursor: pointer;
`;

export const PopoverReference = styled.div`
  width: 100%;
`;
