import {
  BodyType,
  HeadingOneType,
  HeadingThreeType,
  HeadingTwoType,
  LabelType,
  LinkType,
} from './Typography.styled';

/**
 * Variant type definition
 */
type Variant = 'body' | 'label' | 'link' | 'headingOne' | 'headingTwo' | 'headingThree';

/**
 * Typography Component Props
 */
interface TypographyProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  /**
   * The component variant we want to render
   *
   * <Typography variant="headingOne">Title</Typography>
   */
  variant: Variant;

  /**
   * Bold version of the component variant
   * Works with body variant is ignored by the others
   *
   * <Typography variant="body" bold>Hello World!</Typography>
   */
  bold?: boolean;

  /**
   * Large version of the component variant
   * Works with body and label variants is ignored by others
   *
   * <Typography variant="label" large>Label</Typography>
   */
  large?: boolean;

  /**
   * Forwards as prop to rendered component
   * Renders the same component style with a different html tag
   * Expects html tag string: Eg: span, p, h4, strong...
   *
   * <Typography variant="headingOne" as="h4">I'm an h4!</Typography>
   */
  as?: string;
}

/**
 * Typography Component interface
 *
 * extends the base React.FC with the different styled typography components
 */
interface Typepography<P> extends React.FC<P> {
  Body: typeof BodyType;
  Label: typeof LabelType;
  Link: typeof LinkType;
  HeadingOne: typeof HeadingOneType;
  HeadingTwo: typeof HeadingTwoType;
  HeadingThree: typeof HeadingThreeType;
}

/**
 * Resolves the component mapped to a variant
 *
 * @param variant {Variant}
 * @returns StyledComponent
 */
const getComponent = (variant: Variant): React.ComponentType<any> => {
  const components = {
    body: BodyType,
    label: LabelType,
    link: LinkType,
    headingOne: HeadingOneType,
    headingTwo: HeadingTwoType,
    headingThree: HeadingThreeType,
  };

  return components?.[variant] ?? BodyType;
};

/**
 * Typography component
 * exposes the different typography styles as single component.
 * There're 3 to use cases consume this compoment
 *
 * Case 1: <Typography variant="body" lg>Hello World!</Typography>
 * Case 2: <Typography.Body lg>Hello World!</Typography
 * Case 3: const HelloWorld = styled(Typography.Body)`...`
 *
 * @param props {TypographyProps}
 * @returns StyledComponent
 */
export const Typography: Typepography<TypographyProps> = ({
  children,
  variant = 'body',
  as,
  ...rest
}) => {
  const Component = getComponent(variant);

  return (
    <Component {...rest} as={as}>
      {children}
    </Component>
  );
};

Typography.Body = BodyType;
Typography.Label = LabelType;
Typography.Link = LinkType;
Typography.HeadingOne = HeadingOneType;
Typography.HeadingTwo = HeadingTwoType;
Typography.HeadingThree = HeadingThreeType;
