import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Driver: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 24 18" {...props}>
    <path
      d="M21.4033 0.244141H1.94575C0.885318 0.283056 0.0389151 1.12946 0 2.1899V15.8102C0.0389151 16.8706 0.885318 17.717 1.94575 17.7559H21.4033C22.4637 17.717 23.3101 16.8706 23.3491 15.8102V2.1899C23.3101 1.12946 22.4637 0.283056 21.4033 0.244141ZM21.4033 15.8102H1.94575V2.1899H21.4033V15.8102ZM13.6203 13.8644V12.6483C13.6203 11.0333 10.3709 10.2161 8.7559 10.2161C7.14092 10.2161 3.89151 11.0333 3.89151 12.6483V13.8644H13.6203ZM8.7559 4.13565C8.11084 4.13565 7.4922 4.3919 7.03608 4.84802C6.57995 5.30415 6.3237 5.92279 6.3237 6.56784C6.3237 6.88724 6.38661 7.20352 6.50884 7.4986C6.63107 7.79369 6.81023 8.06181 7.03608 8.28766C7.4922 8.74379 8.11084 9.00004 8.7559 9.00004C9.0753 9.00004 9.39157 8.93713 9.68666 8.8149C9.98174 8.69267 10.2499 8.51351 10.4757 8.28766C10.7016 8.06181 10.8807 7.79369 11.0029 7.4986C11.1252 7.20352 11.1881 6.88724 11.1881 6.56784C11.1881 6.24844 11.1252 5.93217 11.0029 5.63708C10.8807 5.342 10.7016 5.07387 10.4757 4.84802C10.2499 4.62217 9.98174 4.44302 9.68666 4.32079C9.39157 4.19856 9.0753 4.13565 8.7559 4.13565ZM13.6203 4.13565V5.10853H19.4575V4.13565H13.6203ZM13.6203 6.0814V7.05428H19.4575V6.0814H13.6203ZM13.6203 8.02716V9.00004H17.5118V8.02716H13.6203Z"
      fill="#507083"
    />
  </SvgIcon>
);
