import { ButtonBase, ButtonIcon } from './Button.styled';
import { ButtonProps } from './types';

/**
 * Renders the Button component. The content will be anything wrapped within the Button tag, but a
 * text is expected. The component can also include an icon.
 *
 * @param {ButtonProps & ComponentPropsWithoutRef<typeof ButtonBase>} props
 *
 * @returns {React.FC<ButtonProps & ComponentPropsWithoutRef<typeof ButtonBase>>}
 */
export const Button: React.FC<ButtonProps & React.ComponentPropsWithoutRef<typeof ButtonBase>> = ({
  icon: Icon,
  secondary = false,
  large = false,
  width,
  children,
  justifyContent = 'center',
  ...rest
}) => {
  return (
    <ButtonBase
      {...rest}
      $iconOnly={!!Icon && !children}
      $secondary={secondary}
      $large={large}
      $justifyContent={justifyContent}
      $width={width}
    >
      {!!Icon && <ButtonIcon>{Icon}</ButtonIcon>}
      {children}
    </ButtonBase>
  );
};
