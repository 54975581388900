import { createModel } from '@rematch/core';
import { format, subDays } from 'date-fns';

import {
  OrderHistoryFormatted,
  OrdersHistoryFilter,
} from 'src/pages/delivery/history/useOrdersHistory';
import { getPaymentTranslationKey } from 'src/utils/orders';

import type { RootModel } from '.';

type DateObject = {
  value: string;
  displayValue: string;
  error: boolean;
  message: string;
};

interface OrdersHistoryProps {
  ordersHistoryData: OrderHistoryFormatted[];
  dateFrom: DateObject;
  dateTo: DateObject;
  searchTerm: string;
  payment?: OrdersHistoryFilter[] | null;
  channel?: OrdersHistoryFilter[] | null;
  status?: OrdersHistoryFilter[] | null;
}

const maxDateDisplay = format(new Date(), "yyyy-MM-dd'T'HH:mm");
const maxDate = new Date().toISOString();
const yesterdayDisplay = format(subDays(new Date(), 1), "yyyy-MM-dd'T'HH:mm");
const yesterday = subDays(new Date(), 1).toISOString();

const EMPTY_DATE_FROM_OBJECT = {
  displayValue: yesterdayDisplay,
  value: yesterday,
  message: '',
  error: false,
};

const EMPTY_DATE_TO_OBJECT = {
  displayValue: maxDateDisplay,
  value: maxDate,
  message: '',
  error: false,
};

const INITIAL_STATE = {
  ordersHistoryData: [],
  searchTerm: '',
  dateFrom: EMPTY_DATE_FROM_OBJECT,
  dateTo: EMPTY_DATE_TO_OBJECT,
  payment: [],
  status: [],
  channel: [],
} as OrdersHistoryProps;

export const ordersHistory = createModel<RootModel>()({
  state: INITIAL_STATE,
  reducers: {
    setOrdersHistory(state, ordersHistoryData: OrderHistoryFormatted[]) {
      if (!ordersHistoryData) {
        return state;
      }
      return {
        ...state,
        ordersHistoryData,
      };
    },
    setSearchTerm(state, newSearchTerm: string) {
      return {
        ...state,
        searchTerm: newSearchTerm,
      };
    },
    setDateFrom(state, dateFrom: DateObject) {
      return {
        ...state,
        dateFrom: dateFrom === state.dateFrom ? EMPTY_DATE_FROM_OBJECT : dateFrom,
      };
    },
    setDateTo(state, dateTo: DateObject) {
      return {
        ...state,
        dateTo: dateTo === state.dateTo ? EMPTY_DATE_TO_OBJECT : dateTo,
      };
    },
    setStatus(state, status: OrdersHistoryFilter[]) {
      return {
        ...state,
        status: status === state.status ? [] : status,
      };
    },
    setChannel(state, channel: OrdersHistoryFilter[]) {
      return {
        ...state,
        channel: channel === state.channel ? [] : channel,
      };
    },
    setPayment(state, payment: OrdersHistoryFilter[]) {
      return {
        ...state,
        payment: payment === state.payment ? [] : payment,
      };
    },
  },
  selectors: (slice, createSelector) => ({
    getOrdersHistory() {
      return slice(state => state.ordersHistoryData);
    },
    getState() {
      return slice;
    },
    getSearchTerm() {
      return slice(state => state.searchTerm);
    },
    getSelectedDateFrom() {
      return slice(state => state.dateFrom);
    },
    getSelectedDateTo() {
      return slice(state => state.dateTo);
    },
    getSelectedStatus() {
      return slice(state => state.status);
    },
    getSelectedChannel() {
      return slice(state => state.channel);
    },
    getSelectedPayment() {
      return slice(state => state.payment);
    },
    filterOrdersHistory() {
      return createSelector(
        this.getSearchTerm,
        this.getSelectedStatus,
        this.getSelectedChannel,
        this.getSelectedPayment,
        this.getOrdersHistory,
        (st, ss, sc, sp, aoh) => {
          const searchTerm = st as unknown as string;
          const selectedStatus = ss as unknown as OrdersHistoryFilter[];
          const selectedChannel = sc as unknown as OrdersHistoryFilter[];
          const selectedPayment = sp as unknown as OrdersHistoryFilter[];
          const allOrdersHistory = aoh as unknown as OrderHistoryFormatted[];

          const filterBySearchTerm = (orderHistory: OrderHistoryFormatted) => {
            const paymentMethod =
              orderHistory.paymentMethod && getPaymentTranslationKey(orderHistory.paymentMethod)
                ? getPaymentTranslationKey(orderHistory.paymentMethod)
                : '';

            return [
              orderHistory.displayOrderId?.slice(-7).toLowerCase(),
              orderHistory.channel?.toLowerCase(),
              orderHistory.paymentTotal?.toFixed(2),
              orderHistory.status.toLowerCase(),
              paymentMethod.toLocaleLowerCase(),
              orderHistory.restaurantId.toLowerCase(),
              orderHistory.restaurantName?.toLocaleLowerCase(),
              orderHistory.customerEmail?.toLowerCase(),
            ].some(term => term?.includes(searchTerm.toLowerCase()));
          };

          if (!allOrdersHistory.length) {
            return [];
          }

          if (
            !searchTerm &&
            !selectedStatus.length &&
            !selectedChannel.length &&
            !selectedPayment.length
          ) {
            return allOrdersHistory;
          }

          let results = allOrdersHistory;

          results = results.filter(order => {
            let statusMatch =
              !selectedStatus.length || selectedStatus.some(item => item.name === order.status);
            let channelMatch =
              !selectedChannel.length || selectedChannel.some(item => item.name === order.channel);
            let paymentMatch =
              !selectedPayment.length ||
              selectedPayment.some(item => item.name === order.paymentMethod);

            return statusMatch && channelMatch && paymentMatch;
          });

          if (searchTerm !== '') {
            results = results.filter(filterBySearchTerm);
          }

          return results;
        }
      );
    },
  }),
});
