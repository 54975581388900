import { useEffect, useRef } from 'react';

import { IconClose } from 'src/components/V2/Icons';
import { ModalPortal } from 'src/components/V2/Portal';
import { useEscapeKey } from 'src/hooks/useEscapeKey';
import { useOutsideClick } from 'src/hooks/useOutsideClick';
import { useLocale } from 'src/locale';
import { noop } from 'src/utils';

import {
  ModalBackground,
  ModalBody,
  ModalClose,
  ModalContainer,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from './Modal.styled';

/**
 * Modal component props interface
 */
export interface ModalProps {
  /**
   * Content that will be rendered inside the modal body
   */
  children?: React.ReactNode;

  /**
   * Boolean flag to control the visibility of the modal
   */
  open?: boolean;

  /**
   * Title of the modal
   */
  title?: string;

  /**
   * Boolean flag to control modal max width
   */
  large?: boolean;

  /**
   * Boolean flag to control the display of the dark background max width
   */
  showBackground?: boolean;

  /**
   * Boolean flag to control onOutsideClick
   */
  autoclose?: boolean;

  /**
   * React component for buttons that will render inside the modal footer
   */
  actions?: React.ReactNode;

  /**
   * Callback that will be executed when the modal closes
   */
  onDissmisModal?: () => void;
}

/**
 * Modal component to render content inside the modal portal
 */
export const Modal: React.FC<ModalProps> = ({
  title,
  actions,
  children,
  open = true,
  showBackground = true,
  large = false,
  autoclose = true,
  onDissmisModal = noop,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useLocale();

  useEffect(() => {
    if (open) {
      dialogRef.current?.focus();
      document.body.classList.add('modal-open');
    }
    return () => document.body.classList.remove('modal-open');
  }, [open]);

  useOutsideClick({ ref: dialogRef, callback: () => open && onDissmisModal(), autoclose });
  useEscapeKey({ ref: containerRef, callback: () => open && onDissmisModal() });

  return (
    <ModalPortal>
      <ModalBackground showBackground={showBackground && open} />
      <ModalContainer open={open} ref={containerRef}>
        <ModalDialog ref={dialogRef} open={open} large={large} tabIndex={0}>
          <ModalHeader>
            {title && <ModalTitle>{title}</ModalTitle>}
            <ModalClose
              aria-label={formatMessage({ id: 'modal.closeModal' })}
              onClick={onDissmisModal}
            >
              <IconClose />
            </ModalClose>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
          {actions && <ModalFooter>{actions}</ModalFooter>}
        </ModalDialog>
      </ModalContainer>
    </ModalPortal>
  );
};
