import { Brand } from 'src/brand';
import BKMarker from 'src/components/V2/Icons/map-icon-bk.svg';
import PLKMarker from 'src/components/V2/Icons/map-icon-plk.svg';
import { GeoJson, Maybe } from 'src/graphql';

export const FULL_CIRCLE = 360.0;
export const GLOBE_WIDTH = 256;
export const DEFAULT_ZOOM = 10;

export interface MapCoordinates {
  lat: number;
  lng: number;
}

export interface CartesianCoordinates {
  x: number;
  y: number;
  z: number;
}

export const getIconMarker = (brand: Brand) => {
  return {
    bk: BKMarker,
    th: BKMarker,
    plk: PLKMarker,
  }[brand];
};

export const geoJsonToLatLng = (coordinate: GeoJson['coordinates']) => {
  return coordinate.flat().map(point => ({ lat: point[1], lng: point[0] }));
};

export const closePolyline = (coordinates: MapCoordinates[]) => {
  if (!coordinates.length) {
    return [];
  }

  const start = coordinates[0];
  const end = coordinates[coordinates.length - 1];

  if (start.lat.toString() !== end.lat.toString() || start.lng.toString() !== end.lng.toString()) {
    coordinates.push(start);
  }

  return coordinates;
};

export const latLngToGeoJson = (coordinates: MapCoordinates[]) => {
  const closedCoordinates = closePolyline(coordinates);
  const geojson = closedCoordinates.map(coord => [coord.lng, coord.lat]);

  return [geojson];
};

export const convertDegreeToRadian = (angle: number): number => (angle * Math.PI) / 180;
export const convertRadianToDegree = (angle: number): number => (angle * 180) / Math.PI;

export const geolocationToCartesian = ({ lat, lng }: MapCoordinates): CartesianCoordinates => {
  const latInRadians = convertDegreeToRadian(lat);
  const lngInRadians = convertDegreeToRadian(lng);

  return {
    x: Math.cos(latInRadians) * Math.cos(lngInRadians),
    y: Math.cos(latInRadians) * Math.sin(lngInRadians),
    z: Math.sin(latInRadians),
  };
};

export const cartesianToGeolocation = ({ x, y, z }: CartesianCoordinates): MapCoordinates => {
  const longitude = Math.atan2(y, x);
  const squareRoot = Math.sqrt(x * x + y * y);
  const latitude = Math.atan2(z, squareRoot);

  return {
    lat: convertRadianToDegree(latitude),
    lng: convertRadianToDegree(longitude),
  };
};

export const calculateCenter = (locations: MapCoordinates[]): MapCoordinates => {
  if (!locations.length) {
    return {
      lat: 0,
      lng: 0,
    };
  }

  const x = [];
  const y = [];
  const z = [];

  const locationsInCartesian = locations.map(location => geolocationToCartesian(location));

  for (const coord of locationsInCartesian) {
    x.push(coord.x);
    y.push(coord.y);
    z.push(coord.z);
  }

  const center: CartesianCoordinates = {
    x: (Math.max(...x) + Math.min(...x)) / 2,
    y: (Math.max(...y) + Math.min(...y)) / 2,
    z: (Math.max(...z) + Math.min(...z)) / 2,
  };

  return cartesianToGeolocation(center);
};

export const calculateZoom = (locations: MapCoordinates[], mapWidth: number): number => {
  if (!locations.length) {
    return DEFAULT_ZOOM;
  }

  const lats = [];
  const lngs = [];

  for (const coord of locations) {
    lats.push(coord.lat);
    lngs.push(coord.lng);
  }
  const northBound = Math.max(...lngs);
  const southBound = Math.min(...lngs);
  const eastBound = Math.max(...lats);
  const westBound = Math.min(...lats);

  const verticalAngle = northBound - southBound;
  const horizontalAngle = eastBound - westBound;

  const angle = Math.max(horizontalAngle, verticalAngle);
  return Math.round(Math.log((mapWidth * FULL_CIRCLE) / angle / GLOBE_WIDTH));
};

export const calculateDistance = (
  coord1: { latitude: number; longitude: number },
  coord2: { latitude: Maybe<number> | undefined; longitude: Maybe<number> | undefined }
): number => {
  if (!coord2.latitude || !coord2.longitude) {
    return 0;
  }

  const multiplier = 2;
  const divider = 2;

  // Earth's radius in meters
  const earthRadius = 6371000;

  // Difference in latitudes and longitudes
  const dLat = convertDegreeToRadian(coord2.latitude - coord1.latitude);
  const dLon = convertDegreeToRadian(coord2.longitude - coord1.longitude);

  // Calculate the distance using the Haversine formula
  const a =
    Math.sin(dLat / divider) * Math.sin(dLat / divider) +
    Math.cos(convertDegreeToRadian(coord1.latitude)) *
      Math.cos(convertDegreeToRadian(coord2.latitude)) *
      Math.sin(dLon / divider) *
      Math.sin(dLon / divider);
  const c = multiplier * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c; // Distance in meters
  return distance;
};
