import styled from 'styled-components';

export const Header = styled.header`
  text-align: center;
  margin: ${({ theme }) => theme.spacing.large} 0;
`;

export const HeaderTitle = styled.h1`
  font-size: 1.75rem;
  line-height: 1.2;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.palette.base.darker};

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

export const ContentRow = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.small};
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ErrorIcon = styled.img``;

export const ErrorText = styled.p`
  text-align: center;
  margin: 0 auto;
  max-width: 45rem;
`;
