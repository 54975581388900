import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const NavToggle: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon viewBox="0 0 100 80" fill="#fff" {...props}>
    <rect y="0" width="100" height="10" />
    <rect y="35" width="70" height="10" />
    <rect y="70" width="40" height="10" />
  </SvgIcon>
);
