import { FC, createContext, useContext, useMemo, useState } from 'react';

import { ModalProps } from 'src/components/V2/Modal';

interface DeliveryModal {
  /** Open delivery modal with given props */
  openModal: (modal: ModalProps) => void;

  /** Close modal */
  closeModal: () => void;

  /** Modal props */
  modal: ModalProps;
}

const modalInitialState = {
  open: false,
  children: null,
};

const DeliveryModalContext = createContext<DeliveryModal>({
  openModal: () => {},
  closeModal: () => {},
  modal: modalInitialState,
});

export const DeliveryModalProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [modal, setModal] = useState<ModalProps>(modalInitialState);

  const api = useMemo(() => {
    const openModal = (modal: ModalProps) => {
      setModal(modal);
    };

    const closeModal = () => setModal(modalInitialState);

    return { openModal, closeModal, modal };
  }, [modal]);

  return <DeliveryModalContext.Provider value={api}>{children}</DeliveryModalContext.Provider>;
};

export const useDeliveryModalContext = () => useContext(DeliveryModalContext);
