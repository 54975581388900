import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Guides: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon viewBox="0 0 32 32" with="32" height="32" fill="#DAE7EE" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2C3.34315 2 2 3.34314 2 5V23C2 24.6569 3.34315 26 5 26H11V27C11 28.6569 12.3431 30 14 30H27C28.6569 30 30 28.6569 30 27V9C30 7.34315 28.6569 6 27 6H21V5C21 3.34315 19.6569 2 18 2H5ZM19 6V5C19 4.44771 18.5523 4 18 4H5C4.44771 4 4 4.44772 4 5V23C4 23.5523 4.44772 24 5 24H11V9C11 7.34314 12.3431 6 14 6H19ZM13 9C13 8.44771 13.4477 8 14 8H27C27.5523 8 28 8.44772 28 9V27C28 27.5523 27.5523 28 27 28H14C13.4477 28 13 27.5523 13 27V9ZM15 12C15 11.4477 15.4477 11 16 11H25C25.5523 11 26 11.4477 26 12C26 12.5523 25.5523 13 25 13H16C15.4477 13 15 12.5523 15 12ZM16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17H25C25.5523 17 26 16.5523 26 16C26 15.4477 25.5523 15 25 15H16ZM15 20C15 19.4477 15.4477 19 16 19H25C25.5523 19 26 19.4477 26 20C26 20.5523 25.5523 21 25 21H16C15.4477 21 15 20.5523 15 20ZM16 23C15.4477 23 15 23.4477 15 24C15 24.5523 15.4477 25 16 25H20.5C21.0523 25 21.5 24.5523 21.5 24C21.5 23.4477 21.0523 23 20.5 23H16Z"
    />
  </SvgIcon>
);
