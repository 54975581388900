import { Helmet } from 'react-helmet-async';
import { ThemeProvider as BaseThemeProvider, createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.min.css';

export const theme = {
  palette: {
    common: {
      black: '#000000',
      white: '#ffffff',
      opacity: {
        blackOpacity30: '#0000004d',
      },
    },
    gray: {
      lighter: '#f4f7f9',
      light: '#ECECEC',
      medium: '#C2C2C2',
      dark: '#878787',
      darker: '#5A5A5A',
    },
    blue: {
      lighter: '#F0F4F6',
    },
    base: {
      lighter: '#DAE7EE',
      light: '#97B0BD',
      medium: '#507083',
      dark: '#3B5A6B',
      darker: '#263C47',
    },
    accent: {
      error: '#DD3712',
      errorBg: '#DD37121a',
      caution: '#F3A919',
      warning: '#FFC2241A',
      warningStrong: '#FFC224',
      success: '#30B402',
      successBg: '#30b4021a',
    },
    brand: {
      bk: '#D62300',
      plk: '#F25600',
      th: '#C8102E',
      rbi: '#CC0000',
    },
    polygon: {
      approved: '#50F915',
      notApproved: '#D62300',
    },
    orderDeliveryStatus: {
      delivered: '#79BF1F',
    },
  },
  spacing: {
    xxxsmall: '4px',
    xxsmall: '8px',
    xsmall: '12px',
    small: '16px',
    normal: '24px',
    large: '32px',
    xlarge: '48px',
    xxlarge: '96px',
  },
  borderRadius: {
    default: '8px',
    small: '4px',
    large: '12px',
    circle: '50%',
    pill: '9999em',
  },
  layout: {
    maxWidth: '1440px',
  },
  navbar: {
    breakpoint: '1100px',
    mobile: {
      height: '54px',
    },
    desktop: {
      height: '72px',
    },
  },
  actionBar: {
    desktop: {
      height: '80px',
    },
  },
  deliveryNavbar: {
    desktop: {
      height: '60px',
    },
  },
};

export type Theme = typeof theme;

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
  }

  body.nav-open,
  body.modal-open {
    overflow: hidden !important;
  }

  p,ul,h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
  #popper_root {
    position: absolute;
    z-index: 1100;
  }

  .embedded-container {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
`;

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <BaseThemeProvider theme={theme}>
      {children}
      <GlobalStyle />
    </BaseThemeProvider>
  </>
);
