import { Override } from '@rbilabs/intl-menu';

import { ServiceMode } from 'src/graphql';
import { Channel } from 'src/graphql/__generated__/main';

export const serviceModes = [
  {
    id: ServiceMode.EAT_IN,
    tr: 'editor.serviceModeEatIn',
  },
  {
    id: ServiceMode.DELIVERY,
    tr: 'editor.serviceModeDelivery',
  },
] as const;

export const lateNight = [
  {
    id: Override.lateNight,
    tr: 'editor.overrides.lateNight',
  },
] as const;

export const defaultEditorChannels = [
  {
    id: Channel.WHITELABEL,
    tr: 'editor.channelApp',
  },
  {
    id: Channel.IN_STORE,
    tr: 'editor.channelInStore',
  },
] as const;

export const defaultChannels = [Channel.WHITELABEL];

export const quickFillProductId = 'quickFill';
export const quickFillServiceMode = 'QUICK_FILL';

export const confirmCopyArticle =
  'https://rbictg.atlassian.net/wiki/spaces/COG/pages/4409360612/Ability+to+Migrate+Copy+Pricing+Availability+Between+Restaurants';
