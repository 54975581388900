import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ToastContainer } from 'react-toastify';
import { useTheme } from 'styled-components';

import { useAuthContext } from 'src/Auth';
import { Brand, useBrand } from 'src/brand';
import { BKLogo, IconChevronDown, IconNavToggle, PLKLogo } from 'src/components/V2/Icons';
import { LanguageSelector } from 'src/components/V2/LanguageSelector';
import { RegionSwitcher } from 'src/components/V2/RegionSwitcher';
import { useOutsideClick } from 'src/hooks/useOutsideClick';
import { useLocale } from 'src/locale';
import { useRematch } from 'src/rematch';
import { select } from 'src/rematch/store';

import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuLink,
  NavBar,
  NavBarBrand,
  NavBarDropdown,
  NavBarDropdownMenu,
  NavBarLogo,
  NavBarNav,
  NavBarTitle,
  NavBarToggle,
  NavFlag,
  NavGroup,
  NavItem,
  NavLink,
  NavLinkLabel,
  PageWrapper,
  ToggleButton,
} from './BaseLayout.styled';
import { navItems } from './constants';
import { Notification } from './notification/Notification';

const getLogo = (brand: Brand) => {
  return {
    bk: BKLogo,
    th: BKLogo,
    plk: PLKLogo,
  }[brand];
};

export const BaseLayout: React.FC<React.ComponentProps<typeof PageWrapper>> = ({
  children,
  ...props
}) => {
  const isLoggedIn = useRematch(select.userPermissions.isLoggedIn);
  const allowAccessDelivery = useRematch(select.userPermissions.allowAccessDelivery);
  const enableAnalyticsPage = useRematch(select.launchDarkly.enableAnalyticsPage);
  const enableErrorsPage = useRematch(select.launchDarkly.enableErrorsPage);
  const enableDeliveryPage = useRematch(select.launchDarkly.enableDeliveryPage);
  const enableGuidesPage = useRematch(select.launchDarkly.enableGuidesPage);
  const enableUserManagementPage = useRematch(select.launchDarkly.enableUserManagementPage);
  const enableHistoryPage = useRematch(select.launchDarkly.enableHistoryPage);
  const enableMultiMarket = useRematch(select.launchDarkly.enableMultiMarket);
  const user = useRematch(select.user.getState);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const [navOpen, setNavOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const { authClient } = useAuthContext();
  const { formatMessage } = useLocale();
  const theme = useTheme();
  const brand = useBrand() || 'bk';
  const Logo = getLogo(brand);

  const allowDeliveryFeature = enableDeliveryPage && allowAccessDelivery;

  const filteredNavItems = useMemo(
    () =>
      navItems.filter(nav => {
        switch (nav.id) {
          case 'analytics':
            return enableAnalyticsPage;
          case 'errors':
            return enableErrorsPage;
          case 'guides':
            return enableGuidesPage;
          case 'delivery':
            return allowDeliveryFeature;
          case 'users':
            return enableUserManagementPage;
          case 'history':
            return enableHistoryPage;
          default:
            return true;
        }
      }),
    [
      enableAnalyticsPage,
      enableErrorsPage,
      enableGuidesPage,
      enableUserManagementPage,
      enableHistoryPage,
      allowDeliveryFeature,
    ]
  );

  useOutsideClick({ ref: dropdownRef, callback: () => dropdownOpen && setDropdownOpen(false) });
  useOutsideClick({ ref: navRef, callback: () => navOpen && setNavOpen(false) });

  useEffect(() => {
    const isMobileNav = window.matchMedia?.('(max-width: 1009px)').matches || true;
    if (isMobileNav && navOpen) {
      document.body.classList.add('nav-open');
    } else {
      document.body.classList.remove('nav-open');
    }
  }, [navOpen]);

  const logout = useCallback(() => {
    authClient.signOut({ revokeAccessToken: false });
  }, [authClient]);

  return (
    <PageWrapper {...props}>
      <NavBar>
        <NavBarBrand>
          <NavBarLogo to="/" brand={brand}>
            <Logo />
          </NavBarLogo>
          <NavBarTitle>{formatMessage({ id: 'shell.navHeading' })}</NavBarTitle>
          <NavBarToggle>
            <ToggleButton onClick={() => setNavOpen(prev => !prev)} type="button">
              <IconNavToggle />
            </ToggleButton>
          </NavBarToggle>
        </NavBarBrand>
        <NavBarNav open={navOpen} ref={navRef}>
          <NavGroup>
            {isLoggedIn &&
              filteredNavItems.map(({ id, localeString, path, Icon }) => (
                <NavItem key={id} id={id}>
                  <NavLink to={path} onClick={() => setNavOpen(false)}>
                    <Icon />
                    <NavLinkLabel as="span">{formatMessage({ id: localeString })}</NavLinkLabel>
                  </NavLink>
                </NavItem>
              ))}
            <NavFlag>{enableMultiMarket && <RegionSwitcher />}</NavFlag>
            <NavItem>
              <NavBarDropdown
                open={dropdownOpen}
                onClick={() => setDropdownOpen(prevOpen => !prevOpen)}
              >
                <NavLink as="div" $dropdown>
                  <NavLinkLabel as="span">{user?.name ?? 'Unknown User'}</NavLinkLabel>
                  <IconChevronDown color={theme.palette.base.lighter} />
                </NavLink>
                <NavBarDropdownMenu ref={dropdownRef}>
                  <DropdownMenu>
                    <DropdownMenuItem>
                      <DropdownMenuLink
                        as="button"
                        data-testid="change-language"
                        onClick={() => setLanguageSelectorOpen(prev => !prev)}
                      >
                        {formatMessage({ id: 'shell.changeLanguage' })}
                      </DropdownMenuLink>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <DropdownMenuLink as="button" onClick={logout}>
                        {formatMessage({ id: 'shell.logOut' })}
                      </DropdownMenuLink>
                    </DropdownMenuItem>
                  </DropdownMenu>
                </NavBarDropdownMenu>
              </NavBarDropdown>
              {allowDeliveryFeature && <Notification />}
            </NavItem>
          </NavGroup>
        </NavBarNav>
      </NavBar>
      {children}
      <ToastContainer />
      <LanguageSelector
        isModalOpen={languageSelectorOpen}
        closeModal={() => setLanguageSelectorOpen(prev => !prev)}
      />
    </PageWrapper>
  );
};
