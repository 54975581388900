import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const EyeVisibleOff: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="22" height="20" viewBox="0 0 22 20" {...props}>
    <path
      d="M2.70703 0.292969L1.29297 1.70703L4.06836 4.48242C3.52925 4.92191 3.04856 5.39001 2.61914 5.85938C0.789141 7.89937 0 10 0 10C0 10 3 18 11 18C12.2 18 13.2895 17.8198 14.2695 17.5098C14.9886 17.29 15.6486 17.0016 16.248 16.6621L19.293 19.707L20.707 18.293L2.70703 0.292969ZM11 2C9.8 2 8.71047 2.18023 7.73047 2.49023L9.39062 4.15039C9.89062 4.05039 10.43 4 11 4C16.28 4 18.9405 8.27 19.8105 10C19.4805 10.66 18.8709 11.7007 17.9609 12.7207L19.3809 14.1406C21.2109 12.1006 22 10 22 10C22 10 19 2 11 2ZM5.48828 5.90234L7.55859 7.97266C7.19945 8.57213 7 9.26093 7 10C7 10.08 6.99977 10.17 7.00977 10.25C7.12977 12.26 8.74 13.8702 10.75 13.9902C10.83 14.0002 10.92 14 11 14C11.7391 14 12.4279 13.8006 13.0273 13.4414L14.7578 15.1719C14.1084 15.4814 13.3885 15.7198 12.6094 15.8496C12.0994 15.9496 11.57 16 11 16C5.72 16 3.05945 11.73 2.18945 10C2.51945 9.34 3.11906 8.2993 4.03906 7.2793C4.44851 6.80992 4.93918 6.34184 5.48828 5.90234ZM11.25 6.00977L14.9902 9.75C14.8702 7.74 13.26 6.12977 11.25 6.00977Z"
      fill="#507083"
    />
  </SvgIcon>
);
