import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Alert: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7 4C7 3.44772 7.44772 3 8 3C8.55229 3 9 3.44772 9 4V8C9 8.55228 8.55229 9 8 9C7.44772 9 7 8.55228 7 8V4ZM8 13C8.69036 13 9.25 12.4404 9.25 11.75C9.25 11.0596 8.69036 10.5 8 10.5C7.30964 10.5 6.75 11.0596 6.75 11.75C6.75 12.4404 7.30964 13 8 13Z"
    />
  </SvgIcon>
);
