import { PosVendor } from '@rbilabs/intl-common';
import { createModel } from '@rematch/core';

import { GetRestaurantsWithMenuItemsQuery, ServiceMode } from 'src/graphql';
import type { RootState } from 'src/rematch/store';
import { EditMode, RestaurantWithMenu } from 'src/rematch/types';

import type { RootModel } from '.';

const getEditableRestaurantsIds = ({
  selectedRestaurants,
  restaurants,
  editMode,
  editableVendors,
}: Pick<UpdatesState, 'selectedRestaurants' | 'restaurants' | 'editMode'> & {
  editableVendors: Array<PosVendor>;
}) =>
  selectedRestaurants.reduce<string[]>((result, id) => {
    const vendor = restaurants[id].pos?.vendor as PosVendor;
    const availability = restaurants[id].menuPrices?.[0]?.availability;
    // A retaurant is editable when:
    // - has pos vendor and
    // - the pos vendor allowed for edits and either
    // - the edit mode is availability or
    // - the edit mode is price and the product is already available
    const isEditable =
      vendor &&
      editableVendors.includes(vendor) &&
      (editMode === EditMode.Availability || (editMode === EditMode.Price && availability));
    if (isEditable) {
      return [...result, id];
    }
    return result;
  }, []);

type UpdatesState = {
  editMode: EditMode | null;
  price: number | null;
  availability: boolean;
  serviceMode: ServiceMode;
  restaurants: { [key: string]: RestaurantWithMenu };
  selectedRestaurants: string[];
  filters: {
    searchTerm: string;
  };
};

export const updates = createModel<RootModel>()({
  state: {
    editMode: null,
    price: null,
    availability: true,
    serviceMode: ServiceMode.EAT_IN,
    restaurants: {},
    selectedRestaurants: [],
    filters: {
      searchTerm: '',
    },
  } as UpdatesState,
  effects: dispatch => ({
    setSelectedRestaurantsAll: (payload: string[], rootState) => {
      const restaurants = rootState.updates.restaurants;
      const editMode = rootState.updates.editMode;
      const editableVendors = rootState.editableVendors;
      const selectedRestaurants = getEditableRestaurantsIds({
        restaurants,
        editMode,
        editableVendors,
        selectedRestaurants: payload,
      });
      dispatch.updates.toggleSelectedRestaurantsAll(selectedRestaurants);
    },
  }),
  reducers: {
    /**
     * Creates an dictionary of restaurants { [restaurant.id]: restaurant }
     */
    setRestaurants: (state, payload: GetRestaurantsWithMenuItemsQuery | undefined) => {
      const allRestaurants = payload?.userRestaurants?.allRestaurants;

      if (allRestaurants) {
        return {
          ...state,
          restaurants: allRestaurants.reduce(
            (hash, restaurant) => ({
              ...hash,
              [restaurant.id]: restaurant,
            }),
            {}
          ),
        };
      }

      return state;
    },

    /**
     * Changes the current edit mode
     */
    changeEditMode: (state, payload: EditMode | null) => {
      return { ...state, editMode: payload };
    },

    /**
     * Changes the new price value
     */
    changePrice: (state, payload: null | number) => {
      return { ...state, price: payload };
    },

    /**
     * Changes the new availability value
     */
    changeAvailability: (state, payload: boolean) => {
      return { ...state, availability: payload };
    },

    /**
     * Changes the selected Service Mode
     */
    changeServiceMode: (state, payload: ServiceMode) => {
      return { ...state, serviceMode: payload };
    },

    /**
     * Toggles selected restaurants by adding or removing them from the selectedRestaurants array
     */
    setSelectedRestaurant: (state, payload: string) => {
      if (state.selectedRestaurants.find(id => id === payload)) {
        return {
          ...state,
          selectedRestaurants: state.selectedRestaurants.filter(id => id !== payload),
        };
      }
      return { ...state, selectedRestaurants: [...state.selectedRestaurants, payload] };
    },

    /**
     * Toggles selected restaurants by adding or removing all of them from the selectedRestaurants array
     */
    toggleSelectedRestaurantsAll: (state, payload: string[]) => {
      if (payload.every(id => state.selectedRestaurants.includes(id))) {
        return { ...state, selectedRestaurants: [] };
      }
      return { ...state, selectedRestaurants: [...payload] };
    },

    /**
     * Reset to initial state
     */
    clearState: state => {
      return {
        ...state,
        editMode: null,
        price: null,
        availability: true,
        serviceMode: ServiceMode.EAT_IN,
        selectedRestaurants: [],
      };
    },

    /**
     * Updates the value of the search filter
     */
    setSearchTerm: (state, payload: string) => {
      return { ...state, filters: { ...state.filters, searchTerm: payload } };
    },
  },
  selectors: (slice, createSelector) => ({
    restaurants() {
      return slice(state => state.restaurants);
    },
    editMode() {
      return slice(state => state.editMode);
    },
    newPrice() {
      return slice(state => state.price);
    },
    newAvailability() {
      return slice(state => state.availability);
    },
    serviceMode() {
      return slice(state => state.serviceMode);
    },
    selectedRestaurants() {
      return slice(state => state.selectedRestaurants);
    },
    searchTerm() {
      return slice(state => state.filters.searchTerm);
    },
    filteredRestaurants() {
      return createSelector([this.restaurants, this.searchTerm], (rs, st) => {
        const restaurants = Object.values(rs) as unknown as RestaurantWithMenu[];
        const searchTerm = st as unknown as UpdatesState['filters']['searchTerm'];

        if (!restaurants) {
          return [];
        }

        if (!searchTerm) {
          return restaurants;
        }

        return restaurants.filter(rest =>
          [rest.id, rest.name].some(data => data.includes(searchTerm))
        );
      });
    },
    areAllRestaurantsSelected() {
      return createSelector(
        [
          this.selectedRestaurants,
          this.filteredRestaurants,
          this.restaurants,
          this.editMode,
          (state: RootState) => state.editableVendors,
        ],
        (sr, fr, ar, em, ev) => {
          const allRestaurants = ar as unknown as UpdatesState['restaurants'];
          const selectedRestaurants = sr as unknown as UpdatesState['selectedRestaurants'];
          const editMode = em as unknown as UpdatesState['editMode'];
          const filteredRestaurants = fr as unknown as Array<RestaurantWithMenu>;
          const editableVendors = ev;

          const currentEditableRestaurantIds = getEditableRestaurantsIds({
            selectedRestaurants: filteredRestaurants.map(r => r.id),
            restaurants: allRestaurants,
            editMode,
            editableVendors,
          });

          return (
            selectedRestaurants.length > 0 &&
            currentEditableRestaurantIds.every(id => selectedRestaurants.includes(id))
          );
        }
      );
    },
  }),
});
