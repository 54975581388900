import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Premium: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.45332 8.79333L7.99999 7.62L9.53999 8.79333L8.95332 6.89333L10.5 5.66667H8.60666L7.99999 3.79333L7.39332 5.66667H5.49999L7.03999 6.89333L6.45332 8.79333ZM13.3333 6.33333C13.3333 3.38667 10.9467 1 7.99999 1C5.05332 1 2.66666 3.38667 2.66666 6.33333C2.66666 7.68667 3.17332 8.91333 3.99999 9.85333V15L7.99999 13.6667L12 15V9.85333C12.8267 8.91333 13.3333 7.68667 13.3333 6.33333ZM7.99999 2.33333C10.2067 2.33333 12 4.12667 12 6.33333C12 8.54 10.2067 10.3333 7.99999 10.3333C5.79332 10.3333 3.99999 8.54 3.99999 6.33333C3.99999 4.12667 5.79332 2.33333 7.99999 2.33333Z"
    />
  </SvgIcon>
);
