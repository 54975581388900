import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const QuestionFlag: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="21" height="14" viewBox="0 0 600 400" {...props}>
    <rect fill="#969594" width="600" height="400" />
    <rect fill="#c2c1c1" width="400" height="400" />
    <rect fill="#72706f" width="200" height="400" />
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth="0.1524"
      d="M348.367 270.386h-93.7054v-9.3454c0-15.9122 1.768-28.8778 5.3882-38.8124 3.6202-10.0188 9.0086-19.0274 16.1648-27.2782 7.1564-8.2508 23.237-22.7318 48.242-43.443 13.3024-10.8608 19.9534-20.7954 19.9534-29.8038 0-9.0928-2.694-16.0808-7.9982-21.1322-5.3882-4.9674-13.4706-7.4932-24.3314-7.4932-11.7026 0-21.3006 3.873-28.962 11.6186-7.6614 7.6614-12.5446 21.2164-14.6494 40.412l-95.7262-11.871c3.2836-35.1922 16.0808-63.4806 38.3074-84.9496 22.3108-21.469 56.4928-32.1612 102.546-32.1612 35.8658 0 64.7436 7.493 86.8018 22.395 29.8882 20.2902 44.8744 47.2316 44.8744 80.9084 0 13.9758-3.8728 27.5306-11.6186 40.4962-7.6614 12.9656-23.4894 28.8778-47.3158 47.5684-16.5858 13.2182-27.1098 23.7422-31.4878 31.7404-4.2938 7.914-6.4828 18.3538-6.4828 31.151zm-96.989 24.9208h100.441v88.5698H251.378v-88.5698z"
    />
  </SvgIcon>
);
