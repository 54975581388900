import styled from 'styled-components';

export const ErrorHeadingText = styled.h2`
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

export const ErrorSubHeading = styled.p`
  font-size: 0.875rem;
  margin: 0;
`;

export const ErrorButton = styled.button`
  border: 0;
  background: transparent;
  font-size: 1.3125rem;
  line-height: 1;
  margin-top: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.palette.accent.error};
  cursor: pointer;
`;

export const ErrorContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.large};
`;

export const ErrorIcon = styled.img``;
