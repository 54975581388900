import { NavLink as RouterLink } from 'react-router-dom';
import Slider from 'react-slick';
import styled from 'styled-components';

import { Typography } from 'src/components/V2/Typography';

export const Navbar = styled.nav`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.base.lighter};
  align-items: center;
  padding-left: 70px;
  padding-right: 35px;

  @media (max-width: ${({ theme }) => theme.navbar.breakpoint}) {
    padding-left: 35px;
  }
`;

export const CarouselNavbar = styled(Slider)`
  .slick-track {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .slick-disabled {
    display: none !important;
  }
`;

export const NavGroup = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  display: block;
`;

export const NavItem = styled.li`
  display: flex;
`;

export const NavLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-bottom: 20px;
  border-bottom-width: ${({ theme }) => theme.spacing.xxxsmall};
  border-bottom-color: transparent;
  border-bottom-style: solid;
  justify-content: center;
  &.active {
    cursor: default;
    border-bottom-color: ${({ theme }) => theme.palette.base.medium};
  }
`;

export const NavLinkLabel = styled(Typography.Label)`
  color: ${({ theme }) => theme.palette.base.medium};
  pointer-events: none;
`;

export const Scrollbar = styled.ul`
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.gray.light};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.gray.medium};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette.gray.dark};
  }
`;
