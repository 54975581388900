import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Email: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      d="M16.6667 3.33337H3.33341C2.41675 3.33337 1.67508 4.08337 1.67508 5.00004L1.66675 15C1.66675 15.9167 2.41675 16.6667 3.33341 16.6667H16.6667C17.5834 16.6667 18.3334 15.9167 18.3334 15V5.00004C18.3334 4.08337 17.5834 3.33337 16.6667 3.33337ZM16.6667 6.66671L10.0001 10.8334L3.33341 6.66671V5.00004L10.0001 9.16671L16.6667 5.00004V6.66671Z"
      fill="#97B0BD"
    />
  </SvgIcon>
);
