import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const IncrementButton: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.832 18.9908V10.9908C20.832 10.4385 20.3843 9.99078 19.832 9.99078C19.2797 9.99078 18.832 10.4385 18.832 10.9908V18.9908H10.832C10.2797 18.9908 9.83203 19.4385 9.83203 19.9908C9.83203 20.5431 10.2797 20.9908 10.832 20.9908H18.832V28.9908C18.832 29.5431 19.2797 29.9908 19.832 29.9908C20.3843 29.9908 20.832 29.5431 20.832 28.9908V20.9908H28.832C29.3843 20.9908 29.832 20.5431 29.832 19.9908C29.832 19.4385 29.3843 18.9908 28.832 18.9908H20.832Z"
      fill="white"
    />
  </SvgIcon>
);
