import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Errors: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon viewBox="0 0 32 32" with="32" height="32" fill="#DAE7EE" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28ZM16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM16 19C15.4477 19 15 18.5523 15 18L15 9C15 8.44771 15.4477 8 16 8C16.5523 8 17 8.44772 17 9L17 18C17 18.5523 16.5523 19 16 19ZM16 23.5C16.6904 23.5 17.25 22.9404 17.25 22.25C17.25 21.5596 16.6904 21 16 21C15.3096 21 14.75 21.5596 14.75 22.25C14.75 22.9404 15.3096 23.5 16 23.5Z"
    />
  </SvgIcon>
);
