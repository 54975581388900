import { PosVendor } from '@rbilabs/intl-common';
import { createModel } from '@rematch/core';

import { GetRestaurantGroupsQuery } from 'src/graphql';

import type { RootModel } from '.';

type Data = Required<GetRestaurantGroupsQuery['allRestaurantGroups']['groups']>;

export interface RestaurantGroupsState {
  data: Data;
  selected: Array<Data[0]['_id']>;
  filters: {
    searchTerm: string;
  };
}

/**
 * Restaurant groups redux store
 */
export const restaurantGroups = createModel<RootModel>()({
  state: {
    data: [],
    selected: [],
    filters: {
      searchTerm: '',
    },
  } as RestaurantGroupsState,

  reducers: {
    /**
     * Stores the result of the GetRestaurantGroups query
     */
    setRestaurantGroups: (state, payload: GetRestaurantGroupsQuery) => {
      if (payload?.allRestaurantGroups?.groups) {
        return { ...state, data: payload.allRestaurantGroups.groups };
      }
      return state;
    },

    /**
     * Toggles selected group
     */
    setSelectedGroup: (state, payload: string) => {
      if (state.selected.includes(payload)) {
        return { ...state, selected: state.selected.filter(id => id !== payload) };
      }
      return { ...state, selected: [...state.selected, payload] };
    },

    /**
     * Clears selected groups
     */
    clearSelectedGroups: state => {
      return { ...state, selected: [] };
    },

    /**
     * Updates the group selection after a restaurant selection
     */
    updateSelectedGroups: (
      state,
      payload: { selected: string[]; editableVendors: PosVendor[] }
    ) => {
      const { selected, editableVendors } = payload;
      const updatedSelection = state.data.reduce<string[]>((result, group) => {
        if (group.restaurants && group.restaurants.length > 0) {
          const editableRestaurants = group.restaurants.filter(
            r => r.pos?.vendor && editableVendors.includes(r.pos.vendor as PosVendor)
          );
          if (editableRestaurants.every(r => selected.includes(r.id))) {
            result = [...result, group._id];
          }
        }
        return result;
      }, []);
      return { ...state, selected: updatedSelection };
    },

    /**
     * Updates the value of the search filter
     */
    setSearchTerm: (state, payload: string) => {
      return { ...state, filters: { ...state.filters, searchTerm: payload } };
    },
  },
  effects: dispatch => ({
    selectAllGroups(payload: string[], rootState) {
      const selectedGroups = rootState.restaurantGroups.selected;
      // only groups with restaurants
      const allGroups = rootState.restaurantGroups.data.filter(
        ({ restaurants, _id }) => restaurants && restaurants.length > 0 && payload.includes(_id)
      );
      const allGroupsIds = allGroups.map(g => g._id);

      if (allGroupsIds.every(id => selectedGroups.includes(id))) {
        // remove all groups
        allGroupsIds.forEach(groupId => this.selectGroup(groupId));
      } else {
        // add all groups
        // delete current selection to ensure all groups are added
        rootState.restaurantGroups.selected = []; //.splice(0);
        allGroupsIds.forEach(groupId => this.selectGroup(groupId));
      }
    },
    selectGroup(payload: RestaurantGroupsState['data'][0]['_id'], rootState) {
      const editableVendors = rootState.editableVendors;
      const selectedGroups = rootState.restaurantGroups.selected;
      const selectedRestaurants = rootState.restaurants.selected;

      const group = rootState.restaurantGroups.data.find(g => g._id === payload);

      if (!group) {
        return;
      }

      // Filter non-editable POS vendors ex: SICOM
      const editableRestaurants =
        group.restaurants?.filter(r => editableVendors.includes(r.pos?.vendor as PosVendor)) ?? [];

      // Prepare to update
      let updatedSelection: string[];

      // remove previously seleted restaurant ids
      if (selectedGroups.includes(payload)) {
        // Ensure the restaurants are not included in
        // other selected group before removing them
        const otherRestaurantIds = selectedGroups
          .filter(id => id !== payload)
          .flatMap(groupId =>
            rootState.restaurantGroups.data
              .find(g => g._id === groupId)
              ?.restaurants?.map(r => r.id)
          );

        // calc the ids that need to be removed
        const restaurantIds = editableRestaurants
          .map(r => r.id)
          .filter(id => !otherRestaurantIds.includes(id));

        // update the selected restaurants
        updatedSelection = selectedRestaurants.filter(id => !restaurantIds.includes(id));
      } else {
        // Update the selected restaurants to add the ones in the group
        updatedSelection = Array.from(
          new Set([...selectedRestaurants, ...editableRestaurants.map(r => r.id)])
        );
      }

      // Update selected restaurants
      rootState.restaurants = { ...rootState.restaurants, selected: updatedSelection };
      dispatch.restaurantGroups.setSelectedGroup(group._id);

      const editorPayload = updatedSelection.map(id => rootState.restaurants.data[id]);
      dispatch.editor.setRestaurants(editorPayload);
    },
  }),
  selectors: (slice, createSelector) => ({
    /**
     * Memoized list of all the groups
     */
    groups() {
      return slice(state => state.data);
    },

    /**
     * Memoized list the selected groups
     */
    selectedGroups() {
      return slice(state => state.selected);
    },

    /**
     * Memoized value of the restaurant groups search filter
     */
    searchTerm() {
      return slice(state => state.filters.searchTerm);
    },

    /**
     *
     */
    filteredGroups() {
      return createSelector([this.groups, this.searchTerm], (ag, st) => {
        const allGroups = ag as unknown as RestaurantGroupsState['data'];
        const searchTerm = st as unknown as RestaurantGroupsState['filters']['searchTerm'];

        if (!allGroups) {
          return [];
        }

        if (!searchTerm) {
          return allGroups;
        }

        return allGroups.filter(({ name }) => {
          return name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
        });
      });
    },

    /**
     * Memoized list of groups with restaurants
     */
    editableGroups() {
      return createSelector([this.filteredGroups], fg => {
        const filteredGroups = fg as unknown as RestaurantGroupsState['data'];
        return filteredGroups.reduce<string[]>((result, { restaurants, _id }) => {
          if (restaurants && restaurants.length > 0) {
            result = [...result, _id];
          }
          return result;
        }, []);
      });
    },
  }),
});
