import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const CashPayment: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 19 16" {...props}>
    <path
      d="M3.96983 0.5C3.23556 0.5 2.63891 1.13284 2.63814 1.90951V6.62467C2.46466 6.74667 2.30236 6.88492 2.15607 7.04297L1.05938 8H0.288086V14.6667H1.05938C1.05938 14.6667 1.53231 14.9834 1.76139 15.1484C1.97735 15.3043 2.3317 15.4854 2.61554 15.4854H6.53982L6.66636 15.4121C11.8055 12.4629 12.0181 12.118 12.1091 11.9714L12.4556 10.4609H16.6963C17.4313 10.4609 18.028 9.82893 18.028 9.05143V1.90951C18.028 1.13284 17.4313 0.5 16.6963 0.5H3.96983ZM5.82727 2.1276H14.8358C14.7699 2.31673 14.7474 2.52032 14.7703 2.7209C14.7932 2.92148 14.8608 3.11305 14.9673 3.27919C15.0739 3.44532 15.2161 3.58106 15.3818 3.67474C15.5476 3.76842 15.7319 3.81726 15.9189 3.81706C16.1318 3.81631 16.3403 3.7521 16.5215 3.63151V7.32943C16.3401 7.2094 16.1316 7.14576 15.9189 7.14551C15.7322 7.14567 15.5483 7.1947 15.3829 7.2884C15.2175 7.38211 15.0756 7.5177 14.9693 7.68358C14.863 7.84947 14.7955 8.0407 14.7725 8.24093C14.7495 8.44116 14.7717 8.64442 14.8373 8.83333H6.77332L7.92575 7.95443C8.58907 7.47609 8.82015 6.57694 8.48464 5.80111L8.44547 5.71647C8.07833 4.97397 7.284 4.62839 6.51271 4.90755L4.14458 5.91504V3.63314C4.32596 3.75316 4.53446 3.8168 4.74716 3.81706C4.93395 3.81673 5.1179 3.76751 5.28325 3.67361C5.4486 3.5797 5.59042 3.44391 5.69659 3.27785C5.80275 3.11179 5.8701 2.92041 5.89286 2.72009C5.91562 2.51977 5.89312 2.31648 5.82727 2.1276V2.1276ZM10.3376 3.00651C9.72052 3.00651 9.16323 3.27078 8.74827 3.69661C9.18019 3.99745 9.54895 4.41117 9.80579 4.93034L9.86906 5.06706C10.1676 5.75539 10.232 6.48734 10.1101 7.17318H12.0504C12.4198 6.72984 12.6515 6.14984 12.6515 5.50651C12.6515 4.12568 11.6156 3.00651 10.3376 3.00651Z"
      fill="#97B0BD"
    />
  </SvgIcon>
);
