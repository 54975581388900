import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const UberEats: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      d="M3.93942 0H16.0606C18.2366 0 20 1.76339 20 3.93942V16.0606C20 18.2366 18.2366 20 16.0606 20H3.93942C1.76339 20 1.01421e-06 18.2366 1.01421e-06 16.0606V3.93942C-0.00154446 1.76339 1.76339 0 3.93942 0Z"
      fill="#142328"
    />
    <path
      d="M9.58397 13.4024C9.59015 12.829 9.13114 12.3576 8.55777 12.3515C7.9844 12.3453 7.51303 12.8043 7.50685 13.3776C7.50067 13.951 7.95967 14.4224 8.53304 14.4286C8.53768 14.4286 8.54077 14.4286 8.54541 14.4286C9.11105 14.4363 9.57624 13.9835 9.58397 13.4178C9.58397 13.4117 9.58397 13.407 9.58397 13.4024ZM10.4324 11.724V15.0916H9.56851V14.7887C9.26096 15.0375 8.87614 15.1719 8.47895 15.1673C7.50376 15.1936 6.69084 14.4239 6.66456 13.4472C6.63829 12.472 7.40794 11.6591 8.38468 11.6328C8.41559 11.6313 8.44804 11.6313 8.47895 11.6328C8.87768 11.6313 9.26405 11.7688 9.57315 12.0223V11.7224H10.4324V11.724ZM13.3008 14.3235H12.6517C12.4539 14.3235 12.3256 14.2369 12.3256 14.0592V12.4828H13.3008V11.724H12.3287V10.7643H11.4555V11.724H10.8003V12.4859H11.4571V14.2756C11.4571 14.7269 11.7832 15.0854 12.3689 15.0854H13.3039L13.3008 14.3235ZM15.2698 15.1719C16.2681 15.1719 16.8307 14.7052 16.8307 14.0592C16.8307 13.6002 16.4984 13.2571 15.7999 13.1103L15.0627 12.9619C14.6346 12.8846 14.5001 12.8058 14.5001 12.6513C14.5001 12.4473 14.7057 12.3236 15.0859 12.3236C15.497 12.3236 15.7999 12.4334 15.8864 12.8058H16.7503C16.7024 12.1057 16.1862 11.639 15.14 11.639C14.2374 11.639 13.6022 12.0053 13.6022 12.7131C13.6022 13.203 13.9515 13.5229 14.7041 13.679L15.5279 13.866C15.8524 13.9278 15.939 14.0128 15.939 14.1458C15.939 14.3559 15.6901 14.4888 15.296 14.4888C14.7969 14.4888 14.5109 14.3807 14.4012 14.0067H13.528C13.6578 14.7052 14.191 15.1719 15.2698 15.1719ZM3.16406 10.5309H6.42192V11.3083H4.04344V12.4225H6.35856V13.1706H4.04344V14.2926H6.42192V15.0715H3.16406V10.5309Z"
      fill="#06C167"
    />
    <path
      d="M16.7565 6.58681V5.97634H16.5247C16.1955 5.96243 15.8849 6.12934 15.7133 6.41217V6.00262H15.0503V9.31766H15.7195V7.43218C15.7195 6.91908 16.0379 6.58681 16.4752 6.58681H16.7565ZM11.947 7.35954C12.0598 6.79081 12.6116 6.41989 13.1803 6.53271C13.5976 6.61462 13.9237 6.94226 14.0071 7.35954H11.947ZM12.9948 5.93771C12.0444 5.92998 11.267 6.69344 11.2593 7.64391C11.2593 7.65009 11.2593 7.65473 11.2593 7.66091C11.2593 8.65002 12.0413 9.3903 13.0551 9.3903C13.6269 9.40112 14.1678 9.13839 14.5125 8.68247L14.0288 8.3301C13.8047 8.64383 13.4415 8.8262 13.0567 8.81847C12.5003 8.8262 12.0227 8.42438 11.9346 7.87573H14.6748V7.65937C14.6748 6.67181 13.9608 5.93771 12.9856 5.93771M9.17287 8.81847C8.53768 8.81384 8.02458 8.2961 8.02922 7.65937C8.03386 7.02263 8.55159 6.51108 9.18833 6.51571C9.82043 6.52035 10.332 7.03345 10.332 7.66709C10.3304 8.30383 9.8127 8.82002 9.17596 8.81847C9.17442 8.81847 9.17287 8.81847 9.17287 8.81847ZM7.35694 9.31766H8.01995V8.90038C8.33986 9.2203 8.77414 9.39957 9.22542 9.39957C10.1821 9.42584 10.9795 8.67011 11.0043 7.71346C11.0305 6.75681 10.2748 5.95934 9.31815 5.93461C9.28724 5.93307 9.25633 5.93307 9.22542 5.93461C8.77568 5.93461 8.34295 6.11389 8.02613 6.4338V4.76624H7.35694V9.31766ZM4.99854 8.78447C5.64146 8.78447 6.13756 8.2961 6.13756 7.57591V4.76624H6.83302V9.31766H6.14374V8.8942C5.82073 9.2203 5.37873 9.39957 4.91972 9.39339C3.92135 9.39339 3.16406 8.68402 3.16406 7.61146V4.76624H3.86571V7.57591C3.86571 8.31001 4.35562 8.78447 5.00627 8.78447"
      fill="#F6F0EA"
    />
  </SvgIcon>
);
