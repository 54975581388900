import { createModel } from '@rematch/core';

import { RestaurantDeliveryFragmentFragment } from 'src/graphql';

import type { RootModel } from '.';

interface DeliveryRestaurantManagementProps {
  data: ReadonlyArray<RestaurantDeliveryFragmentFragment>;
  searchTerm: string;
  city: string;
  franchisee: string;
  status: string;
  currentRestaurant: null | RestaurantDeliveryFragmentFragment;
}

const INITIAL_STATE = {
  data: [],
  searchTerm: '',
  city: '',
  franchisee: '',
  status: '',
  currentRestaurant: null,
} as DeliveryRestaurantManagementProps;

export const deliveryRestaurant = createModel<RootModel>()({
  state: INITIAL_STATE,
  reducers: {
    setRestaurants(state, data?: ReadonlyArray<RestaurantDeliveryFragmentFragment>) {
      if (!data) {
        return state;
      }
      return {
        ...INITIAL_STATE,
        data,
      };
    },
    setSearchTerm(state, newSearchTerm: string) {
      return {
        ...state,
        searchTerm: newSearchTerm,
      };
    },
    setCity(state, city: string) {
      return {
        ...state,
        city: city === state.city ? '' : city,
      };
    },
    setFranchisee(state, franchisee: string) {
      return {
        ...state,
        franchisee: franchisee === state.franchisee ? '' : franchisee,
      };
    },
    setStatus(state, status: string) {
      return {
        ...state,
        status: status === state.status ? '' : status,
      };
    },
    setCurrentRestaurant(state, restaurant?: RestaurantDeliveryFragmentFragment) {
      return {
        ...state,
        currentRestaurant: restaurant || null,
      };
    },
  },
  selectors: (slice, createSelector) => ({
    getAllRestaurants() {
      return slice(state => state.data);
    },
    getState() {
      return slice;
    },
    getSearchTerm() {
      return slice(state => state.searchTerm);
    },
    getSelectedCity() {
      return slice(state => state.city);
    },
    getSelectedFranchisee() {
      return slice(state => state.franchisee);
    },
    getSelectedStatus() {
      return slice(state => state.status);
    },
    getCurrentRestaurant() {
      return slice(state => state.currentRestaurant);
    },
    getAvailableCities() {
      return slice(state => {
        const cities: string[] = [];
        state.data.forEach(restaurant => {
          const city = restaurant.physicalAddress?.city;
          if (city) {
            cities.push(city);
          }
        });
        return [...new Set(cities)];
      });
    },
    getAvailableFranchisee() {
      return slice(state => {
        const franchisees: string[] = [];
        state.data.forEach(restaurant => {
          const franchisee = restaurant.franchiseGroupName;
          if (franchisee) {
            franchisees.push(franchisee);
          }
        });
        return [...new Set(franchisees)];
      });
    },
    getAvailableStatus() {
      return slice(state => {
        const statuses: string[] = [];
        state.data.forEach(restaurant => {
          const status = restaurant.availability;
          if (status) {
            statuses.push(status);
          }
        });
        return [...new Set(statuses)];
      });
    },
    filterRestaurants() {
      return createSelector(
        this.getSearchTerm,
        this.getSelectedCity,
        this.getSelectedFranchisee,
        this.getSelectedStatus,
        this.getAllRestaurants,
        (st, sc, sf, sst, ar) => {
          const searchTerm = st as unknown as string;
          const selectedCity = sc as unknown as string;
          const selectedFranchisee = sf as unknown as string;
          const selectedStatus = sst as unknown as string;
          const allRestaurants = ar as unknown as RestaurantDeliveryFragmentFragment[];

          const matchSelectedStatus = new RegExp(`^${selectedStatus}$`, 'i');

          if (!allRestaurants.length) {
            return [];
          }
          if (!searchTerm && !selectedCity && !selectedFranchisee && !selectedStatus) {
            return allRestaurants;
          }

          let results = allRestaurants;

          if (selectedStatus) {
            results = results.filter(restaurant =>
              restaurant.availability?.match(matchSelectedStatus)
            );
          }

          if (selectedCity) {
            results = results.filter(restaurant =>
              restaurant.physicalAddress?.city?.includes(selectedCity)
            );
          }

          if (selectedFranchisee) {
            results = results.filter(restaurant =>
              restaurant.franchiseGroupName?.includes(selectedFranchisee)
            );
          }

          if (searchTerm) {
            results = results.filter(
              restaurant =>
                restaurant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                restaurant.internalName?.toLowerCase().includes(searchTerm.toLowerCase())
            );
          }

          return results;
        }
      );
    },
  }),
});
