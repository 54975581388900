import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Clock: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      d="M9.84505 17.6666C8.06589 17.6666 6.3596 16.9599 5.10154 15.7018C3.84349 14.4438 3.13672 12.7375 3.13672 10.9583C3.13672 9.17914 3.84349 7.47285 5.10154 6.2148C6.3596 4.95674 8.06589 4.24997 9.84505 4.24997C11.6242 4.24997 13.3305 4.95674 14.5886 6.2148C15.8466 7.47285 16.5534 9.17914 16.5534 10.9583C16.5534 12.7375 15.8466 14.4438 14.5886 15.7018C13.3305 16.9599 11.6242 17.6666 9.84505 17.6666ZM9.84505 2.3333C7.55756 2.3333 5.36376 3.24201 3.74626 4.85951C2.12875 6.47701 1.22005 8.67081 1.22005 10.9583C1.22005 13.2458 2.12875 15.4396 3.74626 17.0571C5.36376 18.6746 7.55756 19.5833 9.84505 19.5833C12.1325 19.5833 14.3263 18.6746 15.9438 17.0571C17.5613 15.4396 18.4701 13.2458 18.4701 10.9583C18.4701 8.67081 17.5613 6.47701 15.9438 4.85951C14.3263 3.24201 12.1325 2.3333 9.84505 2.3333M10.3242 6.16664H8.88672V11.9166L13.4388 14.6479L14.1576 13.4691L10.3242 11.1979V6.16664ZM5.89672 1.74872L4.67005 0.282471L0.261719 3.97205L1.49797 5.4383L5.89672 1.74872ZM19.4284 3.98164L15.0201 0.282471L13.7838 1.74872L18.1921 5.44789L19.4284 3.98164Z"
      fill="#507083"
    />
  </SvgIcon>
);
