import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Phone: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      d="M3.51667 6.99167C4.71667 9.35 6.65 11.275 9.00833 12.4833L10.8417 10.65C11.0667 10.425 11.4 10.35 11.6917 10.45C12.625 10.7583 13.6333 10.925 14.6667 10.925C15.125 10.925 15.5 11.3 15.5 11.7583V14.6667C15.5 15.125 15.125 15.5 14.6667 15.5C6.84167 15.5 0.5 9.15833 0.5 1.33333C0.5 0.875 0.875 0.5 1.33333 0.5H4.25C4.70833 0.5 5.08333 0.875 5.08333 1.33333C5.08333 2.375 5.25 3.375 5.55833 4.30833C5.65 4.6 5.58333 4.925 5.35 5.15833L3.51667 6.99167Z"
      fill="#97B0BD"
    />
  </SvgIcon>
);
