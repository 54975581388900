import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Delivery: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon viewBox="0 0 32 32" with="32" height="32" fill="#DAE7EE" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 0V1.2381V6.19048H0V7.42857V14.8571H5.31738C5.17162 15.0405 5.02232 15.223 4.89746 15.4085C4.16579 16.4955 3.75 17.5397 3.75 18.5714C3.75006 18.8998 3.88179 19.2146 4.11621 19.4468L4.31396 19.6427C3.98465 20.2511 3.7793 20.9333 3.7793 21.6667C3.7793 24.0423 5.74347 26 8.13965 26C10.3222 26 12.1286 24.3705 12.4365 22.2857H15H17.5H21.3428C21.6507 24.3705 23.4571 26 25.6396 26C28.0358 26 30 24.0423 30 21.6667C30 19.8493 28.847 18.2841 27.2339 17.6453L27.1143 17.3333H30V14.8571H26.1768L23.833 8.66667H26.25V4.95238H23.125C22.09 4.95238 21.25 5.78438 21.25 6.80952C21.25 7.02758 21.2949 7.23414 21.3647 7.42857H17.5V9.90476H21.6333L21.9604 10.768L21.8677 10.7173L16.7651 19.8095H15C14.9999 19.7283 14.9917 19.6473 14.9756 19.5677L14.0234 14.8571H15V12.381H8.75V8.66667H10V0H1.25ZM3.75 2.47619H7.5V6.19048H3.75V2.47619ZM2.5 8.66667H6.25V12.381H2.5V8.66667ZM23.064 13.6843L24.5093 17.5026C23.2793 17.8363 22.2662 18.6868 21.7212 19.8095H19.624L23.064 13.6843ZM9.08691 14.8571H11.4746L12.4756 19.8095H12.0581C11.3522 18.3555 9.86412 17.3333 8.13965 17.3333C7.5774 17.3333 7.04274 17.4496 6.54785 17.6453C6.63165 17.3954 6.75496 17.1127 6.97754 16.782C7.45621 16.0708 8.17307 15.363 9.08691 14.8571ZM8.13965 19.8095C9.17847 19.8095 10 20.6227 10 21.6667C10 22.7107 9.17847 23.5238 8.13965 23.5238C7.10082 23.5238 6.2793 22.7107 6.2793 21.6667C6.2793 20.6227 7.10082 19.8095 8.13965 19.8095ZM25.6396 19.8095C26.6785 19.8095 27.5 20.6227 27.5 21.6667C27.5 22.7107 26.6785 23.5238 25.6396 23.5238C24.6008 23.5238 23.7793 22.7107 23.7793 21.6667C23.7793 20.6227 24.6008 19.8095 25.6396 19.8095Z"
    />
  </SvgIcon>
);
