// Causes a delay to execute a function

export const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor = 100) => {
  let timeout: NodeJS.Timeout;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};
