import { AwsRegions, IsoCountryCode2Char, getAwsRegionByCountryCode } from '@rbilabs/intl-common';

export const getServerUrl = (region: IsoCountryCode2Char) => {
  let userAwsRegion = getAwsRegionByCountryCode(region);

  // Temporary overwrite for DOP scalability testing
  if (region === IsoCountryCode2Char.AQ) {
    userAwsRegion = AwsRegions.EUW3;
  }

  const isLocalDev =
    process.env.NODE_ENV === 'development' &&
    (process.env.REACT_APP_GRAPHQL_ENDPOINT?.includes('localhost') ||
      process.env.REACT_APP_API_ENDPOINT?.includes('localhost'));

  if (isLocalDev) {
    return 'http://localhost:3100';
  }

  return `https://${userAwsRegion}-${process.env.REACT_APP_STAGE}-${process.env.REACT_APP_BRAND}-fzportal.rbictg.com`;
};
