import { useCallback, useState } from 'react';

import { IsoCountryCode2Char } from '@rbilabs/intl-common';

import { Flag } from 'src/components/V2/Flag';
import { MARKETS } from 'src/generated/markets';
import { useLocale } from 'src/locale';
import { useRematch } from 'src/rematch';
import { dispatch, select } from 'src/rematch/store';

import { RegionModal } from './RegionModal';
import { Action } from './RegionSwitcher.styled';

export const RegionSwitcher: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const countries = [...MARKETS].sort((a, b) => a.regionName.localeCompare(b.regionName));
  const { region } = useLocale();
  const { userId } = useRematch(select.user.getState);

  const onConfirm = useCallback(
    (regionSelected: IsoCountryCode2Char) => {
      if (regionSelected !== region) {
        dispatch.userPermissions.changeRegion({ country: regionSelected, oktaId: userId! });
      }
      setOpenModal(false);
    },
    [region, userId]
  );

  return (
    <>
      <Action
        type="button"
        data-testid={'region-switcher'}
        title={region}
        onClick={() => setOpenModal(true)}
      >
        <Flag country={region} />
      </Action>
      <RegionModal
        isOpen={openModal}
        selected={region}
        items={countries}
        onDissmisModal={() => setOpenModal(false)}
        onConfirm={onConfirm}
      />
    </>
  );
};
