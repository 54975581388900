import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Analytics: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon viewBox="0 0 32 32" with="32" height="32" fill="#DAE7EE" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00003 2C3.55231 2 4.00003 2.44772 4.00003 3V20.5388L11.147 13.1954C11.3188 13.0189 11.5496 12.912 11.7953 12.8952L20.3208 12.3112L28.3391 5.24954C28.7536 4.88453 29.3855 4.92461 29.7505 5.33908C30.1155 5.75355 30.0754 6.38544 29.6609 6.75046L21.3882 14.0362C21.2232 14.1815 21.015 14.2684 20.7956 14.2834L12.3135 14.8644L4.00003 23.4063V24.5956L28.6729 16.055C29.1948 15.8744 29.7644 16.151 29.945 16.6729C30.1257 17.1948 29.849 17.7643 29.3271 17.945L4.00003 26.7121V28H29C29.5523 28 30 28.4477 30 29C30 29.5523 29.5523 30 29 30H3.00003C2.44774 30 2.00003 29.5523 2.00003 29V26.0232C1.99966 26.0077 1.99967 25.9922 2.00003 25.9767V23.001V22.9988V3C2.00003 2.44772 2.44774 2 3.00003 2Z"
    />
  </SvgIcon>
);
