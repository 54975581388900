import { PosVendor } from '@rbilabs/intl-common';

import { ServiceMode } from 'src/graphql';
import { PluVendor } from 'src/utils/plus';

/**
 * Parses restaurant pos vendor to convert it to product vendor configs vendor
 * ex: (CARROLS, EAT_IN) => carrols
 * ex: (SIMPLY_DELIVERY, DELIVERY) => simplyDeliveryDelivery
 */
export const vendorToProductPlu = (vendor: PosVendor, serviceMode: ServiceMode): PluVendor => {
  let pluVendor = vendor
    .toLowerCase()
    .split('_')
    .reduce((result, part) => result + part.charAt(0).toUpperCase() + part.slice(1));

  if (serviceMode === ServiceMode.DELIVERY) {
    pluVendor += 'Delivery';
  }

  return pluVendor as PluVendor;
};

/**
 * Get Restaurant Environment Criteria Based on Stage Environment.
 *
 * @param env - The stage environment as a string.
 * @returns An array containing the criteria for the production environment ['prod'] if the stage environment is 'qa' or 'prod', or null if the stage environment is not provided or any other value.
 */
export const getRestaurantEnvCriteriaByStageEnv = (
  env: string | undefined
): Array<string> | null => {
  if (env === 'qa' || env === 'prod') {
    return ['prod'];
  }

  return null;
};
