import { SvgIcon } from './SvgIcon';

export const Calendar: React.FC<React.ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="16" height="19" viewBox="0 0 16 19" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33328 8.66677H3.5555V10.4445H5.33328V8.66677ZM8.8889 8.66677H7.11112V10.4445H8.8889V8.66677ZM12.4444 8.66677H10.6666V10.4445H12.4444V8.66677ZM14.2222 2.44453H13.3333V0.666748H11.5556V2.44453H4.44444V0.666748H2.66667V2.44453H1.77778C0.791111 2.44453 0.00888889 3.24453 0.00888889 4.2223L0 16.6667C0 17.6445 0.791111 18.4445 1.77778 18.4445H14.2222C15.2 18.4445 16 17.6445 16 16.6667V4.2223C16 3.24453 15.2 2.44453 14.2222 2.44453ZM14.2223 16.6667H1.77781V6.88896H14.2223V16.6667Z"
    />
  </SvgIcon>
);
