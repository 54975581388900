import styled from 'styled-components';

import { Popover } from 'src/components/V2/Popover/Popover';
import { Typography } from 'src/components/V2/Typography';

export const Container = styled.div`
  display: contents;
  position: relative;

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    height: 100%;
    top: 50%;
    right: ${({ theme }) => theme.spacing.large};
    transform: translateY(-50%);
    z-index: 1000;
    align-items: center;
    justify-content: center;
  }
`;

export const PopoverItemContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100vh;
  border: 1px solid ${({ theme }) => theme.palette.gray.medium};
  background: ${({ theme }) => theme.palette.common.white};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: ${({ theme }) => theme.spacing.large} ${({ theme }) => theme.spacing.normal};
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray.medium};
`;

export const Title = styled(Typography.Label)`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.palette.base.medium};
  font-weight: 600;
`;

export const CloseButton = styled.button`
  color: ${({ theme }) => theme.palette.base.medium};
  border: 0;
  background: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xsmall} 0;
`;

export const StyledPopover = styled(Popover).attrs(() => ({
  options: {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 26],
        },
      },
    ],
  },
}))`
  display: flex;
  padding: 0 ${({ theme }) => theme.spacing.large};
  height: 100%;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({ theme }) => theme.palette.base.dark};
  }
`;
