import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Kabob: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.5C12 3.88071 10.8807 5 9.5 5C8.11929 5 7 3.88071 7 2.5C7 1.11929 8.11929 0 9.5 0C10.8807 0 12 1.11929 12 2.5ZM12 10C12 11.3807 10.8807 12.5 9.5 12.5C8.11929 12.5 7 11.3807 7 10C7 8.61929 8.11929 7.5 9.5 7.5C10.8807 7.5 12 8.61929 12 10ZM9.5 20C10.8807 20 12 18.8807 12 17.5C12 16.1193 10.8807 15 9.5 15C8.11929 15 7 16.1193 7 17.5C7 18.8807 8.11929 20 9.5 20Z"
    />
  </SvgIcon>
);
