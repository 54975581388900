import { SVGAttributes } from 'react';

import { IsoCountryCode2Char } from '@rbilabs/intl-common';
import { hasFlag } from 'country-flag-icons';
import * as flags from 'country-flag-icons/react/3x2';

import { IconQuestionFlag } from '../Icons';

export interface FlagProps extends SVGAttributes<SVGElement> {
  /** Country fo flag */
  country: IsoCountryCode2Char | undefined;
}

const getFlagIcon = (country: IsoCountryCode2Char | undefined) => {
  return hasFlag(country ?? '') ? flags[country as keyof typeof flags] : IconQuestionFlag;
};

export const Flag: React.FC<FlagProps> = props => {
  const { width = '32px', height = '32px' } = props;

  const Icon = getFlagIcon(props.country);

  return <Icon width={width} height={height} {...props} />;
};
