import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Typography } from 'src/components/V2/Typography';

export const Page = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.large};

  @media (min-width: ${({ theme }) => theme.navbar.breakpoint}) {
    min-height: calc(100vh - ${({ theme }) => theme.navbar.desktop.height});
  }
`;

export const PageContent = styled.main<{ hasActionBar?: boolean }>`
  max-width: ${({ theme }) => theme.layout.maxWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${({ theme }) => theme.spacing.small};
  padding-right: ${({ theme }) => theme.spacing.small};
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-bottom: ${({ theme, hasActionBar }) => hasActionBar && theme.actionBar.desktop.height};
`;

export const PageHeaderWrapper = styled.header`
  padding: 0;
  background: linear-gradient(180deg, rgba(243, 243, 243, 0) 0%, rgba(59, 90, 107, 0.1) 100%);
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray.medium};
`;

export const PageHeaderContent = styled(PageContent)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing.large};
  padding-bottom: ${({ theme }) => theme.spacing.large};

  @media (max-width: 767px) {
    flex-direction: column;

    & div {
      flex-direction: column;
      margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
    }
  }
`;

export const PageTitle = styled(Typography.HeadingTwo)<{ margin?: 'normal' | 'small' }>`
  font-size: 2rem;
  font-weight: 500;
  margin: ${({ theme, margin }) =>
    margin === 'small' ? `${theme.spacing.xsmall} 0` : `${theme.spacing.normal} 0`};
`;

export const PageHeaderBackLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.large};
  color: ${({ theme }) => theme.palette.base.dark};
  text-decoration: underlined;
`;

export const PageHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.normal};
`;

export const EditorPageTitle = styled(Typography.HeadingTwo)<{ margin?: 'normal' | 'small' }>`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
  margin: ${({ theme, margin }) =>
    margin === 'small' ? `${theme.spacing.xsmall} 0` : `${theme.spacing.normal} 0`};
`;

export const EditorPageContent = styled.main<{ hasActionBar?: boolean }>`
  display: flex;
  flex-direction: column;
  height: calc(
    100vh -
      ${
        ({ theme }) =>
          `${theme.navbar.desktop.height} - ${theme.actionBar.desktop.height} - 80px` /* 80px is an editor header */
      }
  );
  min-height: 400px;
  max-width: ${({ theme }) => theme.layout.maxWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${({ theme }) => theme.spacing.small};
  padding-right: ${({ theme }) => theme.spacing.small};
  padding-top: ${({ theme }) => theme.spacing.normal};
  padding-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

export const EditorPageSelectionsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
