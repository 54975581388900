import { createModel } from '@rematch/core';

import { GetRestaurantSettingsQuery, LastMileProviders } from 'src/graphql';
import { MAX_ORDER_LIMIT } from 'src/pages/delivery/settings/useRestaurantSettings';
import { omitDeepTypename } from 'src/utils';

import type { RootModel } from '.';

export type RestaurantSettings = GetRestaurantSettingsQuery['restaurant'];
export type EtaValuesProps = GetRestaurantSettingsQuery['etaValues']['etaValues'];

export type DmpSettings = {
  allowPauseService: boolean;
  allowReducedArea: boolean;
  allowAutoPrepare: boolean;
};
export type NotificationSettings = {
  deliveryArea: boolean;
  eta: boolean;
  pauseService: boolean;
  posOffline: boolean;
};

export type ComplaintsCompensationProperties = {
  allowRefund: boolean;
  allowVoucher: boolean;
};

export type LastMileProviderConfiguration = {
  provider: LastMileProviders;
  disabledPaymentMethods: string[];
};

export type LastMileSettings = {
  providers: LastMileProviders[];
  catcherLocationId?: string;
  settings?: LastMileProviderConfiguration[];
};

export const deliveryRestaurantSettings = createModel<RootModel>()({
  state: {
    restaurant: {
      name: '',
      id: '',
      settings: {
        allowAutoFire: false,
        allowAutoPrepare: false,
        allowPauseService: false,
        allowReducedArea: false,
        autoFireRadius: 1500,
        driverOrderLimit: MAX_ORDER_LIMIT,
        deliverySettings: {
          deliveryCost: '0',
          eta: 30,
        },
        notifications: {
          deliveryArea: true,
          eta: true,
          pauseService: true,
          posOffline: true,
        },
        allowRefund: false,
        allowVoucher: false,
        lastMile: {
          providers: new Array<LastMileProviders>(),
          catcherLocationId: '' as string | undefined,
          settings: [] as
            | Array<{ provider: LastMileProviders; disabledPaymentMethods: Array<string> }>
            | undefined,
        },
      },
    },
    etaValues: ['15'],
    lastMileProviders: [LastMileProviders.UBERDIRECT],
  },
  reducers: {
    setRestaurant(state, payload: GetRestaurantSettingsQuery) {
      const { restaurant, etaValues } = payload;
      return {
        ...state,
        etaValues: [...etaValues.etaValues],
        restaurant: omitDeepTypename(restaurant),
      };
    },
    setDmpSettings(state, payload: DmpSettings) {
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          settings: {
            ...state.restaurant.settings,
            ...payload,
          },
        },
      };
    },
    setOrderLimit(state, orderLimit: number) {
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          settings: {
            ...state.restaurant.settings,
            driverOrderLimit: orderLimit,
          },
        },
      };
    },
    setAutoFireRadius(state, autoFireRadius: number) {
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          settings: {
            ...state.restaurant.settings,
            autoFireRadius,
          },
        },
      };
    },
    setDeliveryEta(state, payload: number) {
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          settings: {
            ...state.restaurant.settings,
            deliverySettings: {
              ...state.restaurant.settings.deliverySettings,
              eta: payload,
            },
          },
        },
      };
    },
    setNotificationSettings(state, payload: NotificationSettings) {
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          settings: {
            ...state.restaurant.settings,
            notifications: {
              ...state.restaurant.settings.notifications,
              ...payload,
            },
          },
        },
      };
    },
    setComplaintsCompensation(state, payload: ComplaintsCompensationProperties) {
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          settings: {
            ...state.restaurant.settings,
            ...payload,
          },
        },
      };
    },
    setLastMileSettings(state, payload: LastMileSettings) {
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          settings: {
            ...state.restaurant.settings,
            lastMile: {
              ...state.restaurant.settings.lastMile,
              ...payload,
            },
          },
        },
      };
    },
  },
  selectors: slice => ({
    getRestaurantData() {
      return slice(state => state.restaurant);
    },
    getSettings() {
      return slice(({ restaurant }) => restaurant.settings);
    },
    getEtaValues() {
      return slice(state => state.etaValues);
    },
    getLastMileRegionOptions() {
      return slice(state => state.lastMileProviders);
    },
  }),
});
