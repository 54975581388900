import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Lock: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="18" height="21" viewBox="0 0 16 21" {...props}>
    <path
      d="M14.6665 7.5H13.6665V5.5C13.6665 2.74 11.4265 0.5 8.6665 0.5C5.9065 0.5 3.6665 2.74 3.6665 5.5V7.5H2.6665C1.5665 7.5 0.666504 8.4 0.666504 9.5V19.5C0.666504 20.6 1.5665 21.5 2.6665 21.5H14.6665C15.7665 21.5 16.6665 20.6 16.6665 19.5V9.5C16.6665 8.4 15.7665 7.5 14.6665 7.5ZM8.6665 16.5C7.5665 16.5 6.6665 15.6 6.6665 14.5C6.6665 13.4 7.5665 12.5 8.6665 12.5C9.7665 12.5 10.6665 13.4 10.6665 14.5C10.6665 15.6 9.7665 16.5 8.6665 16.5ZM11.7665 7.5H5.5665V5.5C5.5665 3.79 6.9565 2.4 8.6665 2.4C10.3765 2.4 11.7665 3.79 11.7665 5.5V7.5Z"
      fill="#97B0BD"
    />
  </SvgIcon>
);
