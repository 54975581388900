import { ApolloError } from '@apollo/client';

import { Button } from 'src/components/V2/Button';
import { PageLoadError } from 'src/components/V2/Page';
import { GetNotificationsQuery } from 'src/graphql';
import { useLocale } from 'src/locale';

import {
  CardBody,
  CardContainer,
  CardFooterContainer,
  CardHeaderContainer,
  Container,
  EmptyContainer,
  NotificationMark,
  NotificationText,
  NotificationTitle,
  NotificationTitleContainer,
} from './NotificationCard.styled';

enum RestaurantServiceStatusReasons {
  NUMBER_OF_ORDERS = 'NUMBER_OF_ORDERS',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
  RESTAURANT_SCHEDULE = 'RESTAURANT_SCHEDULE',
  WEATHER = 'WEATHER',
  STREET_CLOSED = 'STREET_CLOSED',
  NO_DRIVERS_AVAILABLE = 'NO_DRIVERS_AVAILABLE',
}

type GetNotificationsType = GetNotificationsQuery['notifications'][0];

export type NotificationType = GetNotificationsType & {
  isDismissed: boolean;
};

type NotificationProps = {
  notifications?: NotificationType[];
  hasError?: ApolloError;
  onRetry: () => void;
  onDismissed: (id: string) => void;
};

export const NotificationContainer = ({
  notifications,
  hasError,
  onRetry,
  onDismissed,
}: NotificationProps) => {
  const { formatDate, formatMessage } = useLocale();

  const pauseServiceReasonMessage = (reason: RestaurantServiceStatusReasons) =>
    formatMessage({ id: `shell.notification.PAUSE_SERVICE.reason.${reason}` });

  const mapperMessages = (notification: NotificationType) => {
    // The parameter type will change when integrate with the real data
    let title: string, message: string;
    switch (notification.eventType) {
      case 'CAPACITY_UPDATE':
        if (notification.eventValue.capacitySettings.capacity === 'REDUCED') {
          title = formatMessage({ id: 'shell.notification.REDUCED_AREA_CHANGED.title' });
          message = formatMessage(
            { id: 'shell.notification.REDUCED_AREA_CHANGED.activate' },
            { restaurant: notification.restaurantName }
          );
          break;
        }
        title = formatMessage({ id: 'shell.notification.REDUCED_AREA_CHANGED.title' });
        message = formatMessage(
          { id: 'shell.notification.REDUCED_AREA_CHANGED.deactivate' },
          { restaurant: notification.restaurantName }
        );

        break;

      case 'POS_UPDATE':
        if (notification.eventValue.posStatus.available) {
          title = formatMessage({ id: 'shell.notification.POS_ONLINE.title' });
          message = formatMessage(
            { id: 'shell.notification.POS_ONLINE.message' },
            { restaurant: notification.restaurantName }
          );
          break;
        }
        title = formatMessage({ id: 'shell.notification.POS_OFFLINE.title' });
        message = formatMessage(
          { id: 'shell.notification.POS_OFFLINE.message' },
          { restaurant: notification.restaurantName }
        );
        break;

      case 'ETA_UPDATE':
        title = formatMessage({ id: 'shell.notification.ETA_UPDATE.title' });
        message = formatMessage(
          { id: 'shell.notification.ETA_UPDATE.message' },
          {
            restaurant: notification.restaurantName,
            minutes: notification.eventValue.deliverySettings.eta,
          }
        );
        break;

      case 'SERVICE_PAUSE_UPDATE':
        if (notification.eventValue.serviceStatus.status === 'RESTAURANT_AVAILABLE') {
          title = formatMessage({ id: 'shell.notification.RESUME_SERVICE.title' });
          message = formatMessage(
            { id: 'shell.notification.RESUME_SERVICE.message' },
            { restaurant: notification.restaurantName }
          );
          break;
        }
        title = formatMessage({ id: 'shell.notification.PAUSE_SERVICE.title' });
        message = `${formatMessage(
          { id: 'shell.notification.PAUSE_SERVICE.message' },
          { restaurant: notification.restaurantName }
        )}${pauseServiceReasonMessage(
          notification.eventValue.serviceStatus.reason as RestaurantServiceStatusReasons
        )}`;
        break;

      default:
        title = formatMessage({ id: 'shell.notification.UNKNOWN.title' });
        message = formatMessage(
          { id: 'shell.notification.UNKNOWN.message' },
          { restaurant: notification.restaurantName }
        );
        break;
    }

    return { title, message };
  };

  const renderNotifications = () => {
    const dismiss = formatMessage({ id: 'shell.notification.dismiss' });
    return notifications?.map(notification => {
      const { isDismissed } = notification;
      const { message, title } = mapperMessages(notification);

      return (
        <CardContainer
          data-testid="notification_card_container"
          key={notification.id}
          isDismissed={isDismissed}
        >
          <CardHeaderContainer>
            <NotificationTitleContainer>
              <NotificationTitle>{title}</NotificationTitle>
              {!isDismissed && <NotificationMark />}
            </NotificationTitleContainer>
            {!isDismissed && (
              <Button secondary onClick={() => onDismissed(notification.id)}>
                {dismiss}
              </Button>
            )}
          </CardHeaderContainer>
          <CardBody>
            <NotificationText>{message}</NotificationText>
          </CardBody>
          <CardFooterContainer>
            <NotificationText>
              {formatDate(notification.createdAt, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </NotificationText>
          </CardFooterContainer>
        </CardContainer>
      );
    });
  };

  if (hasError) {
    return <PageLoadError error={hasError} onRefreshClick={onRetry} />;
  }

  if (!notifications?.length) {
    return (
      <EmptyContainer>
        <NotificationTitle>
          {formatMessage({ id: 'shell.notification.empty.title' })}
        </NotificationTitle>
        <CardBody>
          <NotificationText>
            {formatMessage({ id: 'shell.notification.empty.message' })}
          </NotificationText>
        </CardBody>
      </EmptyContainer>
    );
  }

  return <Container>{renderNotifications()}</Container>;
};
