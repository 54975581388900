import { EditorState, Update } from '../../types';

export const updateOnlyQuickFill = (state: EditorState, payload: Update) => {
  const quickFillUpdatesMap = new Map(state.quickFill.updates.map(u => [`${u.id}`, u]));

  const quickFillPrev = quickFillUpdatesMap.get(payload.id);

  if (!quickFillPrev) {
    quickFillUpdatesMap.set(payload.id, payload);
  } else {
    quickFillUpdatesMap.set(payload.id, { ...payload });
  }

  const serviceQuickFillId = `${payload.id}::${payload.serviceMode}`;
  const serviceQuickFillPrev = quickFillUpdatesMap.get(serviceQuickFillId);

  if (!serviceQuickFillPrev) {
    quickFillUpdatesMap.set(serviceQuickFillId, {
      ...payload,
      id: serviceQuickFillId,
      product: payload.id,
    });
  } else {
    quickFillUpdatesMap.set(serviceQuickFillId, {
      ...serviceQuickFillPrev,
      ...{ ...payload, id: serviceQuickFillId, product: payload.id },
    });
  }

  const quickFillUpdates = Array.from(quickFillUpdatesMap.values());

  return {
    ...state,
    quickFill: { ...state.quickFill, updates: quickFillUpdates },
  };
};
