import { Maybe } from 'src/graphql';

/**
 * Receives T | null and returns T | undefined. Used to parse Maybe<T> values.
 *
 * @param {T | null} value to be parsed
 * @returns {T | undefined} parsed type
 */
export const parseNullableToUndefined = <T>(value: T | null): T | undefined => value ?? undefined;

/**
 * Receives Array<Maybe<T>> and parses all values to Array<T>.
 *
 * @param {T | null} value to be parsed
 * @returns {T | undefined} parsed type
 */
export const parseNullablesArr = <T>(items: Array<Maybe<T>> | Array<T | null>): Array<T> => {
  return items.filter(x => x !== null) as T[];
};
