import styled from 'styled-components';

export const ButtonIcon = styled.span`
  width: ${({ theme }) => theme.spacing.small};
  height: ${({ theme }) => theme.spacing.small};
`;

export const ButtonBase = styled.button<{
  $iconOnly: boolean;
  $large: boolean;
  $secondary: boolean;
  $justifyContent?: string;
  $width?: string;
}>`
  /** css variables */
  --button-height: ${({ $large }) => ($large ? '48px' : '36px')};
  --button-padding: ${({ $iconOnly, theme }) =>
    $iconOnly ? '10px' : `${theme.spacing.xxsmall} ${theme.spacing.normal}`};
  --button-text-color: ${({ theme, $secondary }) =>
    $secondary ? theme.palette.base.dark : theme.palette.common.white};
  --button-background-color: ${({ theme, $secondary }) =>
    $secondary
      ? theme.palette.common.white
      : `linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 51.04%), ${theme.palette.base.medium}`};
  --button-border: ${({ theme, $secondary }) =>
    $secondary ? `1px solid ${theme.palette.base.light}` : 0};
  --button-shadow: ${({ $secondary }) =>
    $secondary
      ? '0px 2px 6px rgba(0, 0, 0, 0.2)'
      : '0px 2px 6px rgba(0, 0, 0, 0.2), inset 0px -2px 0px rgba(0, 0, 0, 0.25)'};
  --button-icon-margin: ${({ theme }) => theme.spacing.xxsmall};

  display: inline-flex;
  align-items: center;
  justify-content: ${({ $justifyContent, theme }) => $justifyContent ?? 'center'};
  font-family: inherit;
  font-weight: 600;
  font-size: inherit;
  line-height: 1.25;
  text-decoration: none;
  letter-spacing: -0.01em;
  white-space: nowrap;
  height: var(--button-height);
  width: ${({ $width }) => $width};
  padding: var(--button-padding);
  gap: var(--button-icon-margin);
  color: var(--button-text-color);
  background: var(--button-background-color);
  box-shadow: var(--button-shadow);
  border: var(--button-border);
  border-color: ${({ theme }) => theme.palette.base.light};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  cursor: pointer;

  &:hover {
    --button-background-color: ${({ theme, $secondary }) =>
      $secondary ? theme.palette.base.lighter : theme.palette.base.dark};

    border-color: ${({ theme }) => theme.palette.base.dark};
  }

  &:active {
    --button-background-color: ${({ theme, $secondary }) =>
      $secondary ? '#BBCED9' : theme.palette.base.darker};
    --button-text-color: ${({ theme, $secondary }) =>
      $secondary ? theme.palette.base.darker : theme.palette.base.lighter};

    box-shadow: none;
  }

  &:disabled {
    --button-background-color: ${({ theme, $secondary }) =>
      $secondary ? theme.palette.common.white : theme.palette.gray.medium} !important;
    --button-text-color: ${({ theme, $secondary }) =>
      $secondary ? theme.palette.gray.medium : theme.palette.common.white} !important;

    box-shadow: none !important;
    cursor: not-allowed !important;
    pointer-events: all !important;
    border-color: ${({ theme }) => theme.palette.gray.medium} !important;
  }

  & svg {
    width: ${({ theme }) => theme.spacing.small};
    height: ${({ theme }) => theme.spacing.small};
    fill: var(--button-text-color);
  }
`;
