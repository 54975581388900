export const omitDeepTypename = (obj: any): any => {
  const omit = '__typename';
  if (!obj || (!(obj instanceof Array) && !(obj instanceof Object))) {
    return obj;
  }
  if (obj instanceof Array) {
    return obj.map(item => omitDeepTypename(item));
  }

  const { [omit]: _, ...rest } = obj;

  return Object.entries(rest).reduce((newObj, [key, value]) => {
    newObj[key] = omitDeepTypename(value);
    return newObj;
  }, {} as typeof rest);
};
