import { Models } from '@rematch/core';

import { complaints } from './complaints';
import { deliveryRestaurant } from './deliveryRestaurant';
import { deliveryRestaurantSettings } from './deliveryRestaurantSettings';
import { drivers } from './drivers';
import { editableVendors } from './editableVendors';
import { editor } from './editor';
import { launchDarkly } from './launchDarkly';
import { menu } from './menu';
import { ordersHistory } from './ordersHistory';
import { polygons } from './polygons';
import { regionLastMileProviders } from './regionLastMileProviders';
import { restaurantGroups } from './restaurantGroups';
import { restaurants } from './restaurants';
import { updates } from './updates';
import { user } from './user';
import { userPermissions } from './userPermissions';

export interface RootModel extends Models<RootModel> {
  user: typeof user;
  launchDarkly: typeof launchDarkly;
  menu: typeof menu;
  updates: typeof updates;
  deliveryRestaurant: typeof deliveryRestaurant;
  polygons: typeof polygons;
  drivers: typeof drivers;
  editor: typeof editor;
  restaurants: typeof restaurants;
  restaurantGroups: typeof restaurantGroups;
  editableVendors: typeof editableVendors;
  userPermissions: typeof userPermissions;
  deliveryRestaurantSettings: typeof deliveryRestaurantSettings;
  complaints: typeof complaints;
  regionLastMileProviders: typeof regionLastMileProviders;
  ordersHistory: typeof ordersHistory;
}

export const models: RootModel = {
  user,
  launchDarkly,
  menu,
  updates,
  deliveryRestaurant,
  ordersHistory,
  drivers,
  editor,
  polygons,
  restaurants,
  restaurantGroups,
  editableVendors,
  userPermissions,
  deliveryRestaurantSettings,
  complaints,
  regionLastMileProviders,
};
