import { PosVendor } from '@rbilabs/intl-common';
import { createModel } from '@rematch/core';

import type { RootModel } from '.';

export const DEFAULT_EDITABLE_VENDORS = [PosVendor.SIMPLY_DELIVERY, PosVendor.TABLET];

export type EditableVendorsState = Array<PosVendor>;

export const editableVendors = createModel<RootModel>()({
  state: [...DEFAULT_EDITABLE_VENDORS],
  reducers: {
    add: (state, payload: PosVendor) => {
      return Array.from(new Set([...state, payload]));
    },
  },
  selectors: slice => ({
    list() {
      return slice(state => state);
    },
  }),
});
