import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const CheckCircle: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM12.7071 6.20711C13.0976 5.81658 13.0976 5.18342 12.7071 4.79289C12.3166 4.40237 11.6834 4.40237 11.2929 4.79289L6.51805 9.56774L4.69997 7.78583C4.30554 7.39924 3.67241 7.4056 3.28583 7.80003C2.89924 8.19446 2.90561 8.82759 3.30003 9.21417L5.82515 11.6891C6.21678 12.0729 6.84447 12.0697 7.23222 11.682L12.7071 6.20711Z"
    />
  </SvgIcon>
);
