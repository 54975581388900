import styled from 'styled-components';

export const Action = styled.button`
  display: block;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

export const ScrollContainer = styled.div`
  height: 36vh;
  overflow: scroll;
  width: calc(100% + 22px); /* twice the value of the parent's padding*/
  padding-right: 14px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${({ theme }) => theme.palette.common.opacity.blackOpacity30};
  }
`;

export const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small};
  align-self: stretch;
  padding: ${({ theme }) => theme.spacing.small};
  border-bottom: 1px solid ${({ theme }) => theme.palette.base.light};

  &:hover {
    background-color: ${({ theme }) => theme.palette.blue.lighter};
    cursor: pointer;
  }

  &:last-child {
    border-bottom: 0;
  }
`;

export const Icon = styled.span`
  margin-left: auto;

  & svg {
    width: 20px;
    height: 20px;
  }
`;
