import { ComponentProps, FC } from 'react';

import { SvgIcon } from './SvgIcon';

export const Glovo: FC<ComponentProps<typeof SvgIcon>> = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <rect width="20" height="20" rx="4" fill="#FFC333" />
    <path
      d="M9.99082 2.88135C7.56418 2.88135 5.59375 4.86314 5.59375 7.30377C5.59375 8.23121 5.87524 9.12936 6.41881 9.88108L6.53529 10.047L8.74381 13.1907C8.79861 13.2687 8.84958 13.3498 8.9136 13.4204C9.03928 13.5591 9.30266 13.7763 9.71904 13.7763H10.2723C10.7059 13.7763 10.9783 13.5331 11.0967 13.3971C11.146 13.3404 11.187 13.2769 11.2302 13.2154L13.4561 10.047L13.5725 9.88108C14.1161 9.1196 14.4073 8.23121 14.4073 7.30377C14.3879 4.85338 12.4175 2.88135 9.99082 2.88135ZM11.7962 8.61195L11.6798 8.77791V8.77791C10.8621 9.94726 9.12583 9.94282 8.30188 8.77791V8.77791L8.1854 8.60219C7.91362 8.21168 7.75831 7.76261 7.75831 7.29401C7.75831 6.05744 8.74317 5.06365 9.97136 5.04873C9.97141 5.04872 9.99077 5.04863 9.99082 5.04863V5.04863C11.2203 5.04863 12.2084 6.03915 12.2232 7.27443C12.2233 7.28094 12.2233 7.2875 12.2233 7.29401V7.29401C12.2136 7.76261 12.068 8.23121 11.7962 8.61195ZM8.75809 15.7581C8.75809 15.0943 9.28225 14.5378 9.98112 14.5378C10.6897 14.5378 11.2041 15.0747 11.2041 15.7386V15.7484C11.2041 16.4024 10.68 16.9491 9.97141 16.9491C9.26284 16.9491 8.75809 16.4122 8.75809 15.7581Z"
      fill="#00A182"
    />
  </SvgIcon>
);
