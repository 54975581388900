import { StrictMode } from 'react';

import ReactDOM from 'react-dom';

import { init as initDataDogLogs } from 'src/utils/datadog';
import { init as initLogRocket } from 'src/utils/logrocket';

import App from './App';
import AppProviders from './AppProviders';

initDataDogLogs();
initLogRocket();

ReactDOM.render(
  <StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>,
  document.getElementById('root')
);
